/**
 * Standard configuration object for Fetch API requests
 */
const timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
const targetTimeZone = timezoneString;

console.log(targetTimeZone, "targetTimeZone");

const fetchReqConfig = {
    method: `POST`,
    redirect: `follow`,
    credentials: `same-origin`,
    headers: {
        'Content-Type': 'application/json',
        'm-device-type':'web',
        'device-timezone':targetTimeZone,
        
        /**
         * @definition A request is accepted as a Fetch API request only if it has this flag
         */
        'fetch-req': true
    }
};

export default () => {
    let token = null;

    if (typeof window !== `undefined`) {

        const tempToken = localStorage.getItem(`temtoken`);
        if(tempToken){
            token= tempToken
        } else{
            token = localStorage.getItem(`token`);
        }
       
    }

   


    

    if (token) fetchReqConfig.headers.Authorization = `Bearer ${token}`;

    return fetchReqConfig;
};
