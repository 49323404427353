import React, { useState, useEffect } from 'react';

import getFetchConfig from '../../../utils/getFetchConfig';
import handleFetchErrors from '../../../utils/handleFetchErrors';
import standardFetchHandlers from '../../../utils/standardFetchHandlers';

import CommonModalWrapper from '../../common-modal-Wrapper/CommonModalWrapper';
import CloseIcon from '../../Icons/CloseIcon';

import RoomRents from '../rentals/Rentals';
import Jobs from '../jobs/Jobs';
import LocalBizServices from '../localbiz-services/LocalBizAndServices';
import BabySitterAndNanny from '../babysitter-and-nanny/BabySitterAndNanny';
import Events from '../events/Events';
import BuyAndSell from '../buy-and-sell/BuyAndSell';
import PostAdsHeading from '../post-ads-heading/PostAdsHeading';
import FormButton from '../../buttons/form-button/FormButton';
import appURLs from '../../../appURL';
import Inputs from '../../Inputs/Inputs';

import style from "./adsCategpries.module.css";
import { toast } from 'react-toastify';

import LoaderScreen from '../loader-screen/LoaderScreen';
import { Modal } from 'react-bootstrap';



export default function AdsCategories({ toggleModalForPostAdscategories = () => { }, checkJobEdit , checkBabyEdit ,checkEventEdit, adsIds, checkRentalEdit, setadsIds,checkLocalBizEdit, checkBuySellEdit} ) {
    console.log(adsIds,"9876567")


    const fetchConfig = getFetchConfig(),
    appURL = appURLs();
    
    
    const contactModeRadioBtn = [
        {
            label: "Offering - I have a job to offer",
            value: "1"
        },
        {
            label: "Wanted - I am looking for a job",
            value: "2"
        },
        
    ];

    const [selectedJobType, updateSelectedJobType] = useState(``);
    
    const [replaceJobs, setReplaceJobs] = useState(false);
    const [Replacerental, setReplacerental,] = useState(false);
    const [replaceBaby, setReplaceBaby] = useState(false)
    const [replaceEvents, setReplaceEvents] = useState(false);
    const [replaceLocalBiz, setReplaceLocalBiz] = useState(false);
    const [replaceBuySell, setReplaceBuySell] = useState(false);


  const [isModalOpenForRoomRents, updateVisibilityForRoomRents] = useState(false),
        [isModalOpenForJobs, updateVisibilityForJobs] = useState(false),
        [isModalOpenForBuyAndSell, updateVisibilityForBuyAndSell] = useState(false),
        [isModalOpenForEvents, updateVisibilityForEvents] = useState(false),
        [isModalOpenForLocalBizAndService, updateVisibilityForLocalBizAndService] = useState(false),
        [isModalOpenForBabySitterAndNanny, updateVisibilityForBabySitterAndNanny] = useState(false);

  const [adsCategories, updateAdsCategories] = useState([]),
        [selectedAdsCategories, updatedSelectedAdsCategories] = useState({
            adsCategoriesId: "",
            optionText: ""
        });

        console.log(adsCategories, "adsCategories");
    // fetching ads categories
    // const fetchingPostACategories = () => {

    //     fetch(`${appURL}/posts/types`, {
    //         ...fetchConfig,
    //         method: "GET"
    //     })
    //         .then(handleFetchErrors)
    //         .then((res) => {
    //             console.log(res)
    //             const adsTypesArr = res?.postType;
    //             debugger
    //             if (adsTypesArr?.length) updateAdsCategories(adsTypesArr); 
    //         })
    //         .catch((err) => standardFetchHandlers.error(err));
    // }

    const fetchingPostACategories = () => {
        fetch(`${appURL}/posts/types`, {
            ...fetchConfig,
            method: "GET"
        })
        .then(handleFetchErrors)
        .then((res) => {
            const adsTypesArr = res?.postType;
            if (adsTypesArr?.length) {
                const transformedCategories = adsTypesArr.map(category => {
                    if (category.name === "Rentals") {
                        return {
                            _id: category._id,
                            name: `${category.name} - የኪራይ ማስታወቂያዎች`
                        };
                    } 

                    if (category.name === "Jobs") {
                        return {
                            _id: category._id,
                            name: `${category.name} - የስራ ማስታወቂያዎች`
                        };
                    }

                    if (category.name === "Babysitters & Nannies") {
                        return {
                            _id: category._id,
                            name: `${category.name} - የሞግዚት ማስታወቂያዎች`
                        };
                    } 
                    if (category.name === "Events") {
                        return {
                            _id: category._id,
                            name: `${category.name} - የተለያዩ ዝግጅቶች`
                        };
                    } 
                    if (category.name === "Buy & Sell") {
                        return {
                            _id: category._id,
                            name: `${category.name} - ግዢና ሽያጮች`
                        };
                    } 
                    if (category.name === "Local Biz & Services") {
                        return {
                            _id: category._id,
                            name: `${category.name} - ንግድና አገልግሎቶች`
                        };
                    } 
                     
                    else {
                        return category;
                    }
                });
                updateAdsCategories(transformedCategories);
            }
        })
        .catch((err) => standardFetchHandlers.error(err));
    };

    const toggleModalVisibilty = (source, value) => {

    

        // if(value) document.body.classList.add("body-fixed");
        // else  document.body.classList.remove("body-fixed");
        // alert(`Yes${source}:${value}`);
        
        if(adsIds){
        toggleModalForPostAdscategories(false);
        }

        console.log("running");

        switch (source) {
            case "roomRents":
                updateVisibilityForRoomRents(value)
                setReplacerental(value);
                break;
            case "jobs":
                updateVisibilityForJobs(value)
                setReplaceJobs(value)
                break;
            case "events":
                updateVisibilityForEvents(value)
                setReplaceEvents(value);
                break;

            case "babySitterAndNanny":
                updateVisibilityForBabySitterAndNanny(value)
                setReplaceBaby(value)
                break;

            case "buyAndSell":
                updateVisibilityForBuyAndSell(value)
                setReplaceBuySell(value)
                break;

            case "localBizServices":
                updateVisibilityForLocalBizAndService(value)
                setReplaceLocalBiz(value)
                break;

            default:
                break;
        }

    }


    useEffect(() => {
        if (checkJobEdit) {
            setReplaceJobs(true);
            console.log("bunnung");
        }

          if (checkRentalEdit) {
            // debugger
            setReplacerental(true);
            // console.log("bunnung");
        }
        if(checkBabyEdit){
            setReplaceBaby(true);
        }

        if(checkEventEdit){
            setReplaceEvents(true);
        }

        if(checkLocalBizEdit){
            setReplaceLocalBiz(true)
        }

        if(checkBuySellEdit){
            setReplaceBuySell(true)
        }


      }, [checkJobEdit,checkRentalEdit, checkBabyEdit,checkEventEdit,checkLocalBizEdit,checkBuySellEdit]);
    

    //   const categoryTranslations = {
    //     "Babysitters & Nannies": "Babysitters & Nannies - ህጻን እና ናኒስ",
    //     "Buy & Sell": "Buy & Sell - አስተዳደር እና ሽመኝ",
    //     "Local Biz & Services": "Local Biz & Services - አካባቢ እና አገልግሎት እና አገልግሎቶች",
    //     "Events": "Events - ዕድሜዎች",
    //     "Jobs": "Jobs - ስራዎች",
    //     "Rentals": "Rentals - የኪራይ ማስታወቂያዎች"
    // };


    const onChangeHandler = (e) => {
        const adsId = e?.target?.value,
            optionText = e.target.selectedOptions[0].text;

          

        updatedSelectedAdsCategories({
            adsCategoriesId: adsId,
            optionText: optionText,
        })   
    }

    // const onChangeHandler = (e) => {
    //     const adsId = e?.target?.value,
    //         optionText = e.target.selectedOptions[0].text;
    
    //     const translatedOptionText = categoryTranslations[optionText] || optionText;
    
    //     updatedSelectedAdsCategories({
    //         adsCategoriesId: adsId,
    //         optionText: translatedOptionText,
    //     });
    // }


    const handleSubmitCategories = (e) => {
        e.preventDefault();
        toast.dismiss();

        const selectedAdsCategoriesOtionId = selectedAdsCategories?.adsCategoriesId,
            selectedAdsCategoriesOtionText = selectedAdsCategories?.optionText;

            console.log(selectedAdsCategoriesOtionText, "selectedAdsCategoriesOtionIdselectedAdsCategoriesOtionIdselectedAdsCategoriesOtionId");

        if (!(selectedAdsCategoriesOtionId || selectedAdsCategoriesOtionText)) {
            return toast.error("Please select category")
        }

        localStorage.setItem('adsCategories', JSON.stringify(selectedAdsCategoriesOtionText));

        if( selectedAdsCategoriesOtionText === "Jobs" && !selectedJobType)  return toast.error("Please select job type")

        // switch (selectedAdsCategoriesOtionText) {
            switch (selectedAdsCategoriesOtionText.split(" - ")[0]) {
            case "Rentals":
                toggleModalVisibilty("roomRents", true);
                break;

            case "Jobs":
                toggleModalVisibilty("jobs", true);
                break;

            case "Babysitters & Nannies":
                toggleModalVisibilty("babySitterAndNanny", true);
                break;

            case "Buy & Sell":
                toggleModalVisibilty("buyAndSell", true);
                break;

            case "Local Biz & Services":
                toggleModalVisibilty("localBizServices", true);
                break;
            case "Events":
                toggleModalVisibilty("events", true);
                break;
            case "addJobsVisibilityModal":
                toggleModalForPostAdscategories(false);
                break;
            default:
                break;
        }

    }


    


    const handleCancelBtnClick = () => {
        toggleModalForPostAdscategories(false)
    }



    const onChangeHandlerFor = (e) => {
        updateSelectedJobType(e.target.value)
    
        console.log(selectedJobType)
    }

    useEffect(() => {
        fetchingPostACategories();
    }, [])

    // alert(`Called ${isModalOpenForEvents}:${replaceEvents}:${adsIds}`);

  

    return (

        <>
    
            <CommonModalWrapper isOpen={isModalOpenForRoomRents || Replacerental  && adsIds} className={style.room_rents_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalVisibilty("roomRents", false)} />
                <RoomRents toggleModalVisibilty={toggleModalVisibilty} adsCategoriesId={selectedAdsCategories?.adsCategoriesId} adsIds={adsIds}  setadsIds={setadsIds} setReplacerental={setReplacerental} toggleModalForPostAdscategories={toggleModalForPostAdscategories} post_type={adsCategories._id}/>
            </CommonModalWrapper>   

            <CommonModalWrapper isOpen={isModalOpenForJobs || replaceJobs  && adsIds}  className={style.room_rents_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalVisibilty("jobs", false)}/>
                <Jobs toggleModalVisibilty={toggleModalVisibilty} adsCategoriesId={selectedAdsCategories?.adsCategoriesId} selectedJobType={selectedJobType} adsIds={adsIds} setadsIds={setadsIds} />
            </CommonModalWrapper>

            <CommonModalWrapper isOpen={isModalOpenForEvents || replaceEvents && adsIds} className={style.room_rents_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalVisibilty("events", false)} />
                <Events toggleModalVisibilty={toggleModalVisibilty} adsCategoriesId={selectedAdsCategories?.adsCategoriesId}   adsIds={adsIds}  setadsIds={setadsIds} />
            </CommonModalWrapper>


            <CommonModalWrapper isOpen={isModalOpenForBabySitterAndNanny || replaceBaby  && adsIds} className={style.room_rents_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalVisibilty("babySitterAndNanny", false)} />
                <BabySitterAndNanny toggleModalVisibilty={toggleModalVisibilty} adsCategoriesId={selectedAdsCategories?.adsCategoriesId} adsIds={adsIds} setadsIds={setadsIds}/>
            </CommonModalWrapper>

            <CommonModalWrapper isOpen={isModalOpenForBuyAndSell || replaceBuySell  && adsIds} className={style.room_rents_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalVisibilty("buyAndSell", false)} />
                <BuyAndSell toggleModalVisibilty={toggleModalVisibilty} adsCategoriesId={selectedAdsCategories?.adsCategoriesId} adsIds={adsIds} setadsIds={setadsIds} />
            </CommonModalWrapper>

            <CommonModalWrapper isOpen={isModalOpenForLocalBizAndService || replaceLocalBiz  && adsIds} className={style.room_rents_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalVisibilty("localBizServices", false)} />
                {/* <Modal show={isModalOpenForLocalBizAndService} onHide={()=>updateVisibilityForLocalBizAndService(false)}>
                    <Modal.Header closeButton >Hello New Modal</Modal.Header>
                </Modal> */}
                <LocalBizServices toggleModalVisibilty={toggleModalVisibilty} adsCategoriesId={selectedAdsCategories?.adsCategoriesId}  adsIds={adsIds} />
            </CommonModalWrapper>


            {<main className={style.main_wrapper}>
       
                <PostAdsHeading headingText='Post your Ad' subHeadingText='' />

                <form id={style.categories_form} onSubmit={handleSubmitCategories}>
                    <Inputs
                        required={true}
                        label='Categories'
                        type='select'
                        placeholder="Select ad category"
                        className={style.select_input}
                        name='ads-category'
                        selectInputOptions={adsCategories}
                        onChangeHandler={onChangeHandler}
                    />

                    {
                        selectedAdsCategories && selectedAdsCategories.optionText.split(" - ")[0] === "Jobs" ?

                            <Inputs
                                type="radio"
                                required={true}
                                onChangeHandler={onChangeHandlerFor}
                                name='jobType'
                                value={selectedJobType}
                                label='Select Type of Jobs Ad'
                                className={style.job_type_input}
                                radioBtnOtions={contactModeRadioBtn}
                            />
                            :
                            ``
    
                    }

                    <FormButton backBtnClickHandler={handleCancelBtnClick} cancelButtonText='Cancel' submitBtnText='Next' />
                </form>

            </main>}

        </>
    )
}


