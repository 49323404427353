import React from 'react'
import { Link } from 'react-router-dom';
import style from "./emailChangeCard.module.css";

export default function EmailChangeCard({messgae}) {
    return (
        <section className={style.email_card_wrapper}>
             <svg width="150" height="150" viewBox="0 0 232 232" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="116" cy="116" r="115.753" fill="#FE4A49" fillOpacity="0.06" />
              <circle cx="116" cy="116" r="89" fill="#FE4A49" fillOpacity="0.12" />
              <path d="M178 118C178 134.178 171.573 149.694 160.134 161.134C148.694 172.573 133.178 179 117 179C100.822 179 85.3062 172.573 73.8665 161.134C62.4268 149.694 56 134.178 56 118C56 101.822 62.4268 86.3062 73.8665 74.8665C85.3062 63.4268 100.822 57 117 57C133.178 57 148.694 63.4268 160.134 74.8665C171.573 86.3062 178 101.822 178 118ZM147.729 94.8962C147.184 94.3535 146.536 93.9261 145.822 93.6398C145.108 93.3534 144.344 93.2139 143.576 93.2295C142.807 93.2452 142.049 93.4157 141.348 93.7309C140.646 94.0461 140.016 94.4995 139.494 95.064L113.012 128.805L97.053 112.838C95.9689 111.828 94.5351 111.278 93.0535 111.304C91.572 111.33 90.1584 111.93 89.1106 112.978C88.0629 114.026 87.4627 115.439 87.4365 116.921C87.4104 118.402 87.9603 119.836 88.9705 120.92L109.146 141.104C109.69 141.646 110.337 142.074 111.049 142.361C111.762 142.648 112.524 142.788 113.292 142.774C114.06 142.76 114.817 142.591 115.518 142.278C116.22 141.965 116.851 141.514 117.374 140.951L147.813 102.903C148.85 101.824 149.424 100.381 149.409 98.8838C149.395 97.3869 148.794 95.9552 147.736 94.8962H147.729Z" fill="#FD7E14" />
            </svg>

            <h1 className={style.heading}>{messgae}</h1>
                {/* <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the</p> */}


                <Link to="/">
                <button className='btn1'>Back To Home</button>
                </Link>

        </section>
    )
}
