import React from 'react'
import { Bars } from 'react-loader-spinner'
import style from './loaderScreen.module.css'

export default function LoaderScreen({mainWrapperId = ``}) {

    return (
        <main id='span-2' className={style.main_wrapper}>
            <Bars
                height="50"
                width="50"
                color="#FD7E14"
                ariaLabel="bars-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
            />
        </main>
    )
}
