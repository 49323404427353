import React, { useEffect, useState } from 'react'
import Card from '../../component/card';
import Jobcard from '../../component/jobcard';
import Babysittercard from '../../component/babysitter'
import Button from '../../component/button';
import Footer_menu from '../../component/footer_menu';
import Header from '../../component/header/Header';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import CommonModalWrapper from '../common-modal-Wrapper/CommonModalWrapper';
import CloseIcon from '../Icons/CloseIcon';

import BabySitterAndNanny from '../postAds/babysitter-and-nanny/BabySitterAndNanny';
import LocalBizAndServices from '../postAds/localbiz-services/LocalBizAndServices';
import Jobs from '../postAds/jobs/Jobs';
import Rentals from '../postAds/rentals/Rentals';
import BuyAndSell from '../postAds/buy-and-sell/BuyAndSell';
import Events from '../postAds/events/Events';

import style from "./paymentFailure.module.css"

export default function PaymentSuccess() {


  const [adsInfo, updateadsInfo] = useState(JSON.parse(localStorage.getItem('adsInfo')))

  useEffect(() => {
    document.body.classList.add("body-fixed");
  }, [])
  const navigate = useNavigate();

  const handleToHomePage = () => {
    localStorage.removeItem("adsInfo");
    navigate("/");
  }
  
  useEffect(() => {

    const adsDetail = JSON.parse(localStorage.getItem('adsInfo'));
    console.log(`adsDetail`, adsDetail)
    if(!adsDetail) navigate("/");
    // console.log(adsDetail, adsDetail)

  }, [])


  return (
    <>

      <CommonModalWrapper isOpen={true} className={style.failure_payment_modal} >
        <CloseIcon onClick={handleToHomePage} />

        {
          (()=>{
            if(adsInfo.adsType === "jobs"){
              return   <Jobs adsId={adsInfo.id} ad_steps={4} adsCategoriesId={adsInfo.adsCategories} />
            } else if(adsInfo.adsType === "events"){
              return   <Events adsId={adsInfo.id} ad_steps={4} adsCategoriesId={adsInfo.adsCategories} />
            } else if(adsInfo.adsType === "BuyAndSell"){
              return   <BuyAndSell adsId={adsInfo.id} ad_steps={4} adsCategoriesId={adsInfo.adsCategories} />
            }else if(adsInfo.adsType === "localbizANDservices"){
              return   <LocalBizAndServices adsId={adsInfo.id} ad_steps={4} adsCategoriesId={adsInfo.adsCategories} />
            }else if(adsInfo.adsType === "BabbysitterAndNanny"){
              return   <BabySitterAndNanny adsId={adsInfo.id} ad_steps={4} adsCategoriesId={adsInfo.adsCategories} />
            }else if(adsInfo.adsType === "rentals"){
              return   <Rentals adsId={adsInfo.id} ad_steps={4} adsCategoriesId={adsInfo.adsCategories} />
            }
          })()
    
        }

      </CommonModalWrapper>

    

      <div className="Main-wrapper-section">
        <div className="view_project">
          <div className='numberlists'>
            <div className='counting-number-lists'>
              <h3>15+M</h3>
              <p>Happy User</p>
            </div>
            <div className='counting-number-lists'>
              <h3>64+K</h3>
              <p>Verified Experts</p>
            </div>
            <div className='counting-number-lists'>
              <h3>98+</h3>
              <p>Categories</p>
            </div>
          </div>
        </div>
        <div className="section">

        </div>
      </div>

    </>

  )

}
