// import { Modal } from "antd";
import React from "react";
import { Modal } from "react-bootstrap";
import { CircularProgress } from "@mui/material";
import binImage from '../../../assest/images/Bin.png';
import CancelIcon from '@mui/icons-material/Cancel';



const ChatItem = (props) => {
  const isCurrentUser = props.user === "me";
  const containsUrl = (message) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return urlRegex.test(message);
  };
  const itemClass = isCurrentUser? "chat__item me" :"chat__item other";

  const handleDelete = () => {
    // Show confirmation dialog
    const shouldDelete = window.confirm("Are you sure you want to delete this message?");
    
    // If user confirms deletion, trigger onDelete function
    if (shouldDelete && props.onDelete) {
      props.onDelete();
      console.log("Message deleted successfully");
    } else {
      console.log("Deletion cancelled");
    }
  };
  
  // Function to check if a message contains a URL

  // Function to check if a message contains a PDF file
  const containsPdf = (message) => {
    return message.toLowerCase().endsWith(".pdf");
  };

  // Function to render either text, an image, or a PDF link based on the message content
  const renderContent = () => {
    const message = props.msg;
    // If the message contains a URL, render an image or PDF link
    if (containsUrl(message)) {
      if (containsPdf(message)) {
        return (
          <a href={message} target="_blank" rel="noopener noreferrer">
            {/* {message} */}
            PDF  
          </a>
        );
      } else {
        return  <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',gap:'10px'}}>
    <img src={message} alt="Image" height='150px' onClick={()=>{props.setimageUrl(message);props.setShowFullImage(true)}} />{isCurrentUser && ( // Render delete button for current user's messages only
    <img onClick={handleDelete} src={binImage} height='20px' width='20px' className="delete_img"/>
    // <i  className="fa fa-trash deletebtn"></i>
  )}
    </div>;
      }
    }
    return <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',gap:'10px'}}>
    <p style={{margin:'0px'}}>{message}</p>{isCurrentUser && ( // Render delete button for current user's messages only
    <img onClick={handleDelete} src={binImage} height='20px' width='20px' className="delete_img"/>
    // <i  className="fa fa-trash deletebtn"></i>
  )}
    </div>;
  };

  return (
    <div style={{ animationDelay: `0.8s` }} className={itemClass}>
      <div className="chat__item__content">
        <div className={isCurrentUser ? "chat__msg me" : "chat__msg other"}>
          {renderContent()}
        </div>
        <div className="chat__meta">
          <span>{props.timeStamp}</span>
           
        </div>
      </div>
    </div>
  );
};

export default ChatItem;
