import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import disabledKeys from '../../utils/disabledKeys';

import LoaderButton from '../buttons/loader-button/LoaderButton';

import style from '../verify-otp-card/verifyOTPCard.module.css';
import appURLs from '../../appURL';

export default function VerifyOTPCardForForgetPassword({updateModalVisibilityForVerifyOTP, updateModalVisibilityForUpdatePassword=null, email_address=null, reGenerateOTPForEmail = ""}) {


    const appURL = appURLs();
 
    const navigate = useNavigate();
    const fetchConfig = getFetchConfig();

    const [emailOTP, setEmailOTP] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" });
    const [showLoder, toggleLoader] = useState(false);


    const [isValidEmailOTP, updateIsValidEmailOTP] = useState({
        value: false,
        message: `Please enter valid OTP`
    })

 
    const handleChange = (otpType, otpValue, event) => {

        if(disabledKeys.includes(event.target.value)) return

        if (otpType === 'email') {
            setEmailOTP({ ...emailOTP, [otpValue]: event.target.value });
        } 
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        updateIsValidEmailOTP({ value: false })

        let emailOTPStr = ``;
       

            if (!(emailOTP.otp1 && emailOTP.otp2 && emailOTP.otp3 && emailOTP.otp4)) {
                updateIsValidEmailOTP({
                    value: true,
                    message: `Please enter all otp`
                })
            }
            console.log(emailOTP.otp1)
            emailOTPStr = `${emailOTP.otp1}${emailOTP.otp2}${emailOTP.otp3}${emailOTP.otp4}`

        if (emailOTPStr.length < 4) return
        console.log(`emailOTPStr`,emailOTPStr)

        const dataSend ={
            otp: emailOTPStr,
            email_address: email_address
        }


            toggleLoader(true)
            fetch(`${appURL}/verify-forget-password-otp`, {
                ...fetchConfig, 
                body: JSON.stringify(dataSend)
            })
                .then(handleFetchErrors)
                .then((res) => {
                    toggleLoader(false)
                    if (Number(res.status) === Number(200)) {
                        
                        updateModalVisibilityForVerifyOTP(false)
                        updateModalVisibilityForUpdatePassword(true)

                    } else if (Number(res.status) === Number(400) || Number(res.status) === Number(403)) {
                        toast.error(res?.message);
                    }

                })
                .catch(standardFetchHandlers.error)
                .finally(() => {
                    setTimeout(() => {
                        toggleLoader(false);
                    }, 1000);
                });
        



    };

    const inputfocus = (otpType, elmnt) => {

        if(disabledKeys.includes(elmnt.key)) return;


        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus()
            }
        } else {
            const next = elmnt.target.tabIndex;
            if (next < 4) {
                elmnt.target.form.elements[next].focus()
            }
        }
    };

    


    return (
        

        <form id={style.otpForm} onSubmit={handleSubmit}>

            {
      
                    <section className={style.emailOTPInputSection}>
                        <h4> Enter email OTP</h4>
                        <div className={style.otpContainer}>
                            <input
                                name="email-otp-1"
                                type="text"
                                autoComplete="off"
                                className={style.otpInput}
                                value={emailOTP.otp1}
                                onChange={(e) => handleChange('email', 'otp1', e)}
                                tabIndex="1"
                                maxLength="1"
                                onKeyUp={(e) => inputfocus('email', e)}
                            />
                            <input
                                name="email-otp-2"
                                type="text"
                                autoComplete="off"
                                className={style.otpInput}
                                value={emailOTP.otp2}
                                onChange={(e) => handleChange('email', 'otp2', e)}
                                tabIndex="2"
                                maxLength="1"
                                onKeyUp={(e) => inputfocus('email', e)}
                            />
                            <input
                                name="email-otp-3"
                                type="text"
                                autoComplete="off"
                                className={style.otpInput}
                                value={emailOTP.otp3}
                                onChange={(e) => handleChange('email', 'otp3', e)}
                                tabIndex="3"
                                maxLength="1"
                                onKeyUp={(e) => inputfocus('email', e)}
                            />
                            <input
                                name="email-otp-4"
                                type="text"
                                autoComplete="off"
                                className={style.otpInput}
                                value={emailOTP.otp4}
                                onChange={(e) => handleChange('email', 'otp4', e)}
                                tabIndex="4"
                                maxLength="1"
                                onKeyUp={(e) => inputfocus('email', e)}
                            />
                        </div>

                        <div className={style.erroResendButtonSection}>
                            <span className={style.errorText}> {isValidEmailOTP.value ? isValidEmailOTP.message : ``} </span>
                            <span onClick={(e) => { reGenerateOTPForEmail(e, 2) }} className={style.resendText}>Resend OTP</span>
                        </div>
                    </section>
            
            }


            {
                showLoder ? <LoaderButton /> :   <button className={`${style.submitBtn} btn1`} type='submit'>submit</button>


            }

          

        </form>
    )

}