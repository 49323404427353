import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import {} from '../../../public/firebase-messagin-sw'
const firebaseConfig = {
        apiKey: "AIzaSyAeAktYaifa6OcKkh2AgYAUBzv9CsBltS8",
        authDomain: "http://menehariya-d1e7f.firebaseapp.com/",
        projectId: "menehariya-d1e7f",
        storageBucket: "http://menehariya-d1e7f.appspot.com/",
        messagingSenderId: "445054121959",
        appId: "1:445054121959:web:7cf2ed301bb2b5f5472f42"
};
// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
// Cache to store notification IDs that have been shown
const shownNotifications = new Set();

export const requestForToken = () => {
    return getToken(messaging, {
        vapidKey: "BG7vWYbJ6dnZLV177iloKLrUl3BmeGjN_6Vz46gcoZw8rgPSDr4esp0k0RXjLMo7e8fJJMT93UWumAmhZkEMifk"
    })
        .then((currentToken) => {
            if (currentToken) {
                console.log('Current token for client:', currentToken);
                return currentToken;
            } else {
                console.log('No registration token available. Request permission to generate one.');
                return null;
            }
        })
        .catch((err) => {
            console.log('An error occurred while retrieving token:', err);
            return null;
        });
};  

onMessage(messaging, (payload) => {
  console.log('Message received (background):', payload);
  // Check if the notification is related to your userId
  if (payload.data) {
      // Check if the notification ID has been shown already
      if (!shownNotifications.has(payload.data.notificationId)) {
          // Add the notification ID to the cache
          shownNotifications.add(payload.data.notificationId);
          // Process the notification or show it to the user
          const notification = new Notification(payload.notification.title, {
              body: payload.notification.body,
              icon: payload.notification.icon || '/images/default-icon.png',
              // other options
          });
          // Handle notification click
          notification.onclick = (event) => {
              event.preventDefault();
              // Handle the click action here
              console.log('Notification clicked:', payload);
          };
      }
  }
});

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
      navigator.serviceWorker.register('/firebase-messaging-sw')
          .then(registration => {
              console.log('Service Worker registered with scope:', registration.scope);
          })
          .catch(error => {
              console.error('Service Worker registration failed:', error);
          });
  });
} 




