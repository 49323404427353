import React from 'react';
import './Tooltip.css';

const Tooltip = ({ text, children, id= "" }) => {
  return (
    <div className="tooltip-container">
      <div className="tooltip-content">{children}</div>
      <div className="tooltip-text" id={id}><p className='tooltips_text'>{text}</p></div>
    </div>
  );
};

export default Tooltip;
