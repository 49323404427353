import 'react-widgets/styles.css';
import styles from './formElems.module.css';
import MultiselectExternal from 'react-widgets/Multiselect';

const NoItemsMessage = () => (
    <div className="rw-list-empty">
      No Item in lists
    </div>
  );



const LimitReachedMessage = () => (
  <div className="rw-list-empty">
    Max limit reached
  </div>
);
  
const MultiSelect = ({
    className='',
    label='',
    id='',
    name='',
    data=[],
    dataKey='',
    textField='',
    placeholder='',
    defaultValue=[],
    value=undefined,
    readOnly=false,
    allowCreate=false,
    required= false,
    onChange=()=>{},
    handleTagRemove,
    onCreate=()=>{},
    onRemove=()=>{} }) => { // Add onRemove prop


    const dataArrToUse = (data && Array.isArray(data) && data.length) ? data : [];

    const optionalProps = {};

    if(typeof value !== undefined){
        optionalProps.value = value;
    }

    const remove = (tag) => {
     
        onRemove(tag); // Call the onRemove prop with the tag parameter
    };
  
      // const messages = {
      //   emptyList: value.length < 2 ? NoItemsMessage : LimitReachedMessage
      // };


    return (
        <section className={`multiSelectWrapper ${ className }`}>
            <label id='label' className={ styles.formElementLabel } htmlFor={ id }>{ label }{required ? <span className="required_field"> *</span> : <span className='optional_text'>(Optional)</span>}</label>

            <MultiselectExternal
                // allowCreate={true}
                { ...optionalProps }
                id={ id }
                allowCreate={ allowCreate }
                readOnly={ readOnly }
                placeholder={ placeholder }
                data={ dataArrToUse.slice(0,5) } 
                dataKey={ dataKey }
                textField={ textField }
                defaultValue={ defaultValue }
                name={ name }
                // messages={messages}
                filter={ 'contains' }
                containerClassName={ styles.multiSelectContainer + ` multiSelectContainer` }
                listProps={{ className:styles.multiSelectListItem }}
                onChange={ onChange }
                onCreate={ onCreate }
                onRemove={ remove } // Pass the remove method as the onRemove prop
            />
        </section>
    );
};


export default MultiSelect;
