import React from 'react';
import style from "./postAddHeading.module.css"

export default function PostAdsHeading({headingText =  ``, subHeadingText = ``}) {
    return (
        <div className={style.heading_section}>
            <h3>{headingText}</h3>
            <p>{subHeadingText}</p>
        </div>
    )
}
