import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import disabledKeys from '../../utils/disabledKeys';

import appURLs from '../../appURL';
import LoaderButton from '../buttons/loader-button/LoaderButton';
import CommonModalWrapper from '../common-modal-Wrapper/CommonModalWrapper';

import style from './verifyOTPForEmail.module.css';

export default function VerifyOTPForEmail({ reGenerateOTPForOldEmail = null, fetchingSellerAccountDetail = () => { }, reGenerateOTPForNewEmail = null, updateModalIsOpenForVerifyEmail = () => { }, emailAddress, handleModalForEmailChangeInfo=()=>{} }) {

    const fetchConfig = getFetchConfig(),
        appURL = appURLs();


    const [showLoader, toggleLoader] = useState(false);
    const [emailOTP, updateEmailOTP] = useState(
        {
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: ""
        }
    );


    const [isValidOTP, updateIsValidOTP] = useState({
        value: false,
        message: `Please enter valid OTP`
    })

    const handleChange = (otpValue, event) => {

        if (disabledKeys.includes(event.target.value)) return
        updateEmailOTP({ ...emailOTP, [otpValue]: event.target.value });

    };

    const handleSubmit = (event) => {
        event.preventDefault();

        console.log(`emailAddress`, emailAddress)

        updateIsValidOTP({ value: false })

        let emailOTPStr = ``;

        let dataPerfect = true;


        if (!(emailOTP.otp1 || emailOTP.otp2 || emailOTP.otp3 || emailOTP.otp4)) {
            dataPerfect = false
            updateIsValidOTP({
                value: true,
                message: `Please enter all otp`
            })
        }

        emailOTPStr = `${emailOTP.otp1}${emailOTP.otp2}${emailOTP.otp3}${emailOTP.otp4}`

        if (!dataPerfect) return

        toggleLoader(true)
        fetch(`${appURL}/verify-otp-for-email-change`, {
            ...fetchConfig,
            body: JSON.stringify({
                old_email_otp: emailOTPStr,
                new_email_address: emailAddress
            })
        })
            .then(handleFetchErrors)
            .then((res) => {

                toggleLoader(false)
                if (Number(res.status) === Number(200)) {
                    updateModalIsOpenForVerifyEmail(false);
                    handleModalForEmailChangeInfo(true);
                    fetchingSellerAccountDetail();
                } else {
                    return updateIsValidOTP({
                        value: true,
                        message: "invalid OTP"
                    })
                }

            })
            // .catch(standardFetchHandlers.error)
            .catch((error) => {
                toast.error(`OTP is not matching`);
              })
            .finally(() => {
                setTimeout(() => {
                    toggleLoader(false);
                }, 1000);
            });

    };


    const resendOTPHandler = (e) => {


        fetch(`${appURL}/generate-otp-for-email-change`, {
            ...fetchConfig,
            body: JSON.stringify({
                new_email_address: emailAddress,
            })
        })
            .then(handleFetchErrors)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    return toast.success(`OTP resend successfully on your email ${emailAddress}`)
                } else {
                    return toast.success(res.message)
                }
            })
            .catch(standardFetchHandlers.error)
            .finally(() => {
                setTimeout(() => {
                    toggleLoader(false);
                }, 1000);
            });

    }

    const inputfocus = (elmnt) => {


        if (disabledKeys.includes(elmnt.key)) return;

        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt?.target?.tabIndex - 2;
            if (next > -1) {
                elmnt?.target?.form?.elements[next].focus()
            }
        } else {
            const next = elmnt?.target?.tabIndex;
            if (next < 4) {
                console.log(elmnt?.target?.form.elements[next])
                elmnt?.target?.form?.elements[next].focus()
            }
        }
    };




    return (
        <>

            

            <form id={style.otpForm} onSubmit={handleSubmit}>


                <section className={style.emailOTPInputSection}>
                    <h4>Enter OTP</h4>
                    <div className={style.otpContainer}>
                        <input
                            name="email-otp-1"
                            type="text"
                            autoComplete="off"
                            className={style.otpInput}
                            value={emailOTP.otp1}
                            onChange={(e) => handleChange('otp1', e)}
                            tabIndex="1"
                            maxLength="1"
                            onKeyUp={(e) => inputfocus(e)}
                        />
                        <input
                            name="email-otp-2"
                            type="text"
                            autoComplete="off"
                            className={style.otpInput}
                            value={emailOTP.otp2}
                            onChange={(e) => handleChange('otp2', e)}
                            tabIndex="2"
                            maxLength="1"
                            onKeyUp={(e) => inputfocus(e)}
                        />
                        <input
                            name="email-otp-3"
                            type="text"
                            autoComplete="off"
                            className={style.otpInput}
                            value={emailOTP.otp3}
                            onChange={(e) => handleChange('otp3', e)}
                            tabIndex="3"
                            maxLength="1"
                            onKeyUp={(e) => inputfocus(e)}
                        />
                        <input
                            name="email-otp-4"
                            type="text"
                            autoComplete="off"
                            className={style.otpInput}
                            value={emailOTP.otp4}
                            onChange={(e) => handleChange('otp4', e)}
                            tabIndex="4"
                            maxLength="1"
                            onKeyUp={(e) => inputfocus(e)}
                        />
                    </div>

                    <div className={style.erroResendButtonSection}>
                        <span className={style.errorText}> {isValidOTP.value ? isValidOTP.message : ``} </span>
                        <span onClick={(e) => { resendOTPHandler(e, 2) }} className={style.resendText}>Resend OTP</span>
                    </div>


                </section>

                {/* <section className={style.emailOTPInputSection}>
            <h4>New email otp goes to <br /> <span>{emailAddress}</span></h4>
                <div className={style.otpContainer}>
                    <input
                        name="email-otp-1"
                        type="text"
                        autoComplete="off"
                        className={style.otpInput}
                        value={emailOTPForNew.otp1}
                        onChange={(e) => handleChange('new', 'otp1', e)}
                        tabIndex="5"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus('new', e)}
                    />
                    <input
                        name="email-otp-2"
                        type="text"
                        autoComplete="off"
                        className={style.otpInput}
                        value={emailOTPForNew.otp2}
                        onChange={(e) => handleChange('new', 'otp2', e)}
                        tabIndex="6"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus('new', e)}
                    />
                    <input
                        name="email-otp-3"
                        type="text"
                        autoComplete="off"
                        className={style.otpInput}
                        value={emailOTPForNew.otp3}
                        onChange={(e) => handleChange('new', 'otp3', e)}
                        tabIndex="7"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus('new', e)}
                    />
                    <input
                        name="email-otp-4"
                        type="text"
                        autoComplete="off"
                        className={style.otpInput}
                        value={emailOTPForNew.otp4}
                        onChange={(e) => handleChange('new', 'otp4', e)}
                        tabIndex="8"
                        maxLength="1"
                        onKeyUp={(e) => inputfocus('new', e)}
                    />
                </div>



                <div className={style.erroResendButtonSection}>

                <span className={style.errorText}> {isValidEmailOTPForNew.value ? isValidEmailOTPForNew.message : ``} </span>
                    <span onClick={(e) => { resendOTPHandler(e, 1) }} className={style.resendText}>Resend OTP</span>
                </div>
            </section> */}

                {
                    showLoader ?
                        <LoaderButton />
                        :
                        <button className={`${style.submitBtn} btn1`} type='submit'>Submit</button>
                }




            </form>
        </>
    )

}