import Modal from 'react-modal';
import classes from './commonModalWrapper.module.css';

const customStyles = {
    content: {
        position: `absolute`,
        overflow: `scroll`,
        padding: `20px`,
        width: `100%`,
        marginLeft: `auto`,
        maxWidth: `850px`,
        maxHeight: `600px`,
        paddingLeft: `40px`,
        paddingRight: `40px`,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        background: `white`,
        zIndex: 10000000
    },
    overlay: {
        background: `rgba(0, 0, 0, 0.4)`,
        zIndex: 1
    }
};


function CommonModalWrapper({ isOpen = false, closeModal = () => {}, className = ``, children }) {
    
    return (
        <Modal
            style={customStyles}
            ariaHideApp={false}
            className={className + ` ` + classes.detailsModal}
            isOpen={isOpen}
            // onRequestClose={}
            >
                

            {children}
        </Modal>
    )
}


export default CommonModalWrapper;