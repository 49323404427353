import React, { useState } from "react";
import style from "./changePasswordCard.module.css"
import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import LoaderButton from "../buttons/loader-button/LoaderButton";
import { useNavigate } from 'react-router-dom';
import PasswordChecklistComponent from "../password-checklist/PasswordChecklist";
import { toast } from 'react-toastify';
import appURLS from "../../appURL";

import { isTruthyString, isValidEmailAddress, isValidPassword,isValidIndianMobileNumber } from '../../utils/verification';

export default function ChangePasswordCard({updateIsOpenForLoginChangePassword = false}) {

    const appURL = appURLS();


    const navigate = useNavigate();
    const fetchConfig = getFetchConfig();


    const [passwordInputTypeForNewPassword, updatePasswordInputTypeForNewPassword] = useState(`password`),
        [passwordInputTypeForConfirmPassword, updatePasswordInputTypeForConfirmPassword] = useState(`password`);


        const [confirmPasswordFocused, setConfirmPasswordFocused] = useState(false);



    const [showLoader, toggleLoader] = useState(false),
        [formData, updateFormData] = useState({

            old_password: '',
            new_password: '',
            confirm_password: '',
        });

    const { old_password, new_password, confirm_password } = formData;

    // const onChangeHandlerForFormData = (e) => {
    //     const inputName = e?.target?.name || e?.name,
    //         inputValue = e?.target?.value || e?.value;

    //     updateFormData((prev) => ({
    //         ...prev,
    //         [inputName]: inputValue
    //     }));
    // };


  const onChangeHandlerForFormData = (e) => {
  const inputName = e?.target?.name || e?.name,
    inputValue = e?.target?.value || e?.value;

  updateFormData((prev) => ({
    ...prev,
    [inputName]: inputValue
  }));

  if (inputName === "confirm_password") {
    setConfirmPasswordFocused(e.type === "focus");
  }
};

      


    const passwordInputChangeHandler = (inputTpe, source) => {

        if (source === 1) updatePasswordInputTypeForNewPassword(inputTpe);
        else if (source === 2) updatePasswordInputTypeForConfirmPassword(inputTpe);

    }


    const onSubmitHandler = async (e) => {
        e.preventDefault();

        const missingData = [],
            invalidData = [];

        if (!old_password) return toast.error(`old password`);
        else if (old_password && !isValidPassword(old_password)) return toast.error(`old password should include at least one upper case, one lower case,one digit & special character`);

        if (!new_password) missingData.push(`new password`);
        else if (new_password && !isValidPassword(new_password)) return toast.error(`new password should include at least one upper case, one lower case,one digit & special character`);

        if (!confirm_password) return toast.error(`confirm password`);
        else if (confirm_password && !isValidPassword(confirm_password)) return toast.error(`confirm password should include at least one upper case, one lower case,one digit & special character`);


        // Show errors if needed
        if (missingData.length || invalidData.length) {
            if (missingData.length) toast.error(`Missing: ${missingData.join(`, `)}`);
            if (invalidData.length) toast.error(`Invalid: ${invalidData.join(`, `)}`);
            return;
        }

        if (new_password !== confirm_password) return toast.error('Password don`t match');
        if (old_password === new_password) return toast.error('New password and old password can`t be same');

        const dataObj = {
            password: old_password,
            newPassword: new_password,
        };

        toggleLoader(true)
        fetch(`${appURL}/change-password`, {
            ...fetchConfig,
            method: "PATCH",
            body: JSON.stringify(dataObj)
        })
            .then(handleFetchErrors)
            .then((res) => {
                toggleLoader(false)
                if (res?.status === 200) {
   
                    updateIsOpenForLoginChangePassword(false)
                    // localStorage.removeItem(`token`);
                    // localStorage.removeItem(`temtoken`);
                    // localStorage.removeItem(`user`);
                    // localStorage.removeItem(`source`);
                    // localStorage.removeItem(`profilePicture`);
                    toast.success(res?.message);
                   return window.location.reload(true)
                } else if (res?.status === 400) {
                    return toast.error(res?.message)
                } else {
                    return toast.error(`Something isn't working right`);
                }

            }).catch(standardFetchHandlers.error)
            .finally(() => {
                setTimeout(() => {
                    toggleLoader(false);
                }, 1000);
            });
    };


    return (

        <main>
            <h1 className={style.heading}>Change Password</h1>
            <form onSubmit={onSubmitHandler} >

                <div className="form-group">
                    <label for="exampleInputName1">Old Password* </label>
                    <input type="text" onChange={onChangeHandlerForFormData} name='old_password' className="form-control" placeholder="Enter your old password" autoComplete="off" required/>
                </div>


                <div className="form-group">
                    <label for="exampleInputEmail1">New Password* </label>
                    <div className={style.password_input_wrapper}>
                        <input type={passwordInputTypeForNewPassword} onChange={onChangeHandlerForFormData} className="form-control" name='new_password' placeholder="Enter your new password" autoComplete="off" required />
                        {
                            passwordInputTypeForNewPassword === "text" ?
                                <svg onClick={() => { passwordInputChangeHandler(`password`, 1) }} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                :  <svg onClick={() => { passwordInputChangeHandler(`text`, 1) }} fill="#000000" width="20" height="20" viewBox="0 -16 544 544" xmlns="http://www.w3.org/2000/svg" ><title>hide</title><path d="M108 60L468 420 436 452 362 378Q321 400 272 400 208 400 154 361 99 322 64 256 79 229 102 202 124 174 144 160L76 92 108 60ZM368 256Q368 216 340 188 312 160 272 160L229 117Q254 112 272 112 337 112 392 152 446 192 480 256 474 269 461 288 448 307 434 322L368 256ZM272 352Q299 352 322 338L293 309Q283 312 272 312 249 312 233 296 216 279 216 256 216 247 220 236L190 206Q176 229 176 256 176 296 204 324 232 352 272 352Z" /></svg>
                        }

                    </div>


                            {
                                new_password && new_password.length > 0 && !confirmPasswordFocused ? (
                                    <PasswordChecklistComponent
                                    password={new_password || ""}
                                    rules={[
                                        "minLength",
                                        "specialChar",
                                        "number",
                                        "capital",
                                        "lowercase"
                                    ]}
                                    confirm_password={confirm_password || ""}
                                    />
                                ) : null
                            }

                </div>

                <div className="form-group">
                    <label for="exampleInputEmail1">Confirm Password*</label>
                    <div className={style.password_input_wrapper}>
                        <input type={passwordInputTypeForConfirmPassword} onChange={onChangeHandlerForFormData} className="form-control" name='confirm_password' placeholder="Re-enter your password" autoComplete="off" required/>


                        {
                            passwordInputTypeForConfirmPassword === "text" ?
                                <svg onClick={() => { passwordInputChangeHandler(`password`, 2) }} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                : 
                                <svg onClick={() => { passwordInputChangeHandler(`text`, 2) }} fill="#000000" width="20" height="20" viewBox="0 -16 544 544" xmlns="http://www.w3.org/2000/svg" ><title>hide</title><path d="M108 60L468 420 436 452 362 378Q321 400 272 400 208 400 154 361 99 322 64 256 79 229 102 202 124 174 144 160L76 92 108 60ZM368 256Q368 216 340 188 312 160 272 160L229 117Q254 112 272 112 337 112 392 152 446 192 480 256 474 269 461 288 448 307 434 322L368 256ZM272 352Q299 352 322 338L293 309Q283 312 272 312 249 312 233 296 216 279 216 256 216 247 220 236L190 206Q176 229 176 256 176 296 204 324 232 352 272 352Z" /></svg>
                        }

                    </div>

                    {
                            confirm_password && confirm_password.length > 0 ?
                   
                               <PasswordChecklistComponent
                                    password={new_password || ""}
                                    rules={[ "match"]}
                                    confirm_password={confirm_password || ""}
                                /> 
                         
                       :``
                        }

                </div>



                {
                    showLoader ?

                        <section className={`${style.button_wrapper} button_wrapper`}>

                            <div id="back_button" className="form-group">
                                <p onClick={() => { updateIsOpenForLoginChangePassword(false) }} id={style.back_button} className=" btn-confirm">
                                    Back
                                </p>

                            </div>

                            <LoaderButton />


                        </section>
                        :
                        <section className={`${style.button_wrapper} button_wrapper`}>

                            <div id="back_button" className="form-group">
                                <p onClick={() => { updateIsOpenForLoginChangePassword(false) }} id={style.back_button} className=" btn-confirm">
                                    Back
                                </p>

                            </div>

                            <div id={style.submit_btn} className="form-group">
                                <button type="submit" className="btn btn-confirm">
                                    Change Passsword
                                </button>

                            </div>


                        </section>

                }


              
               

            </form>
        </main>
    )
}