import React, { useState, useRef, useEffect } from "react";
import "./ChatContent.css";
import appURLs from "../../../appURL";
import getFetchConfig from "../../../utils/getFetchConfig";
import ChatItem from "./ChatItem";
import { useNavigate } from "react-router-dom";
// import { verify } from "jsonwebtoken";
import { CircularProgress } from "@mui/material";
import userVectorImage from '../../../assest/images/userVector.jpeg'

const ChatContent = ({ chatDetailsData, setInput,userData, sendMessage,input,loader,  deleteMessage, selectedChat, checkOnlinestatus,ads_type, adsId,setSelectedFiles,selectedFiles ,myMessages,setPreviewUrls,previewUrls,listData}) => {
  const navigate = useNavigate();

  const appURL = appURLs();
  const fetchConfig = getFetchConfig();
  const messagesEndRef = useRef(null);
  const [chat, setChat] = useState();
  const [msg, setMsg] = useState("");
  const [image, setImage] = useState(null);
  // const [selectedFiles, setSelectedFiles] = useState([]);
  // const [previewUrls, setPreviewUrls] = useState([]);
  const [showFullImage, setShowFullImage] = useState(null);
  const [imageUrl,setimageUrl]=useState('');
  const [mychatDetails,setmychatDetails]=useState();
  // const [selectedFiles, setSelectedFiles] = useState(null);

  
  console.log(chat, "chat2343434345");
  
  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    const newPreviewUrls = files.map(file => URL.createObjectURL(file));
  
    setSelectedFiles(prevFiles => [...prevFiles, ...files]);
    setPreviewUrls(prevUrls => [...prevUrls, ...newPreviewUrls]);
  
    // Clear the input value to allow selecting the same file again
    e.target.value = '';
  };
  
  console.log(handleFileSelect, "selectedFiles"); 

  useEffect(()=>{
    console.log("myMessage Contennt",myMessages);
  },[myMessages])

  useEffect(()=>{
    console.log(`chatDetailsData`,chatDetailsData);
    setmychatDetails(chatDetailsData);
  },[chatDetailsData])

  const chatScrollRef=useRef();
  const prevMessageCount = useRef(myMessages.length);


  const colorList = {
    A: "#00FFFF",
    B: "#0000FF",
    C: "#FF8C00",
    D: "#FFD700",
    E: "#008000",
    F: "#FF00FF",
    G: "#DDA0DD",
    H: "#FF69B4",
    I: "#4B0082",
    J: "#00A86B",
    K: "#F0E68C",
    L: "#E6E6FA",
    M: "#000080",
    N: "#E0115F",
    O: "#808000",
    P: "#800080",
    Q: "#E0D0C6",
    R: "#FF0000",
    S: "#C0C0C0",
    T: "#008080",
    U: "#808000",
    V: "#D2691E",
    W: "#EE82EE",
    X: "#738678",
    Y: "#FFFF00",
    Z: "#000000",
  };
 

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    let formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    formattedTime = formattedTime.replace(/^0/, '');
    return formattedTime;
  };

  useEffect(()=>{
    console.log("chatDetailsDatamsges",myMessages);
  },[myMessages])
  

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }  };

  useEffect(() => {
    scrollToBottom();
  }, [chatDetailsData,myMessages]);

  const onStateChange = (e) => {
    setMsg(e.target.value);
  };

  const removeImage = (index) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setPreviewUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
  };

  const handleKeyPress = (e) => {
    console.log("previewUrls",previewUrls);
    if (e.key === 'Enter' && input !== '' && previewUrls?.length == 0) {
      sendMessage();
    }
  };

  // const handleFileSelect = (event) => {
  //   setSelectedFiles(event.target.files);
  // };

  const userId = JSON.parse(localStorage.getItem("user"))?.userId;

  const handleSendMessage = () => {
    if (msg.trim() !== "") {
      const newMessage = {
        senderId: userId,
        content: msg,
        content_type: "text",
        status: "seen",
        timestamp: new Date().toISOString(),
      };
      setChat((prevChat) => [...prevChat, newMessage]);
      setMsg("");
    }
  };

  useEffect(()=>{
    if (chatScrollRef.current && myMessages.length > prevMessageCount.current) {
      console.log("called");
      chatScrollRef.current.scrollTop = chatScrollRef.current.scrollHeight;
    }
    prevMessageCount.current = myMessages.length;
  },[myMessages])

  // Reverse the order of messages
  const reversedMessages = Array.isArray(myMessages) ? [...myMessages].reverse() : [];

  const getBackgroundColor = (initial) => {
    const color = colorList[initial.toUpperCase()] || "#FFFFFF"; // Default to white if the initial is not found
    return { backgroundColor: color };
  };

  const uploadFiles = async () => {
    console.log("called upload")
    try {
      const formData = new FormData();
      selectedFiles.forEach((file) => {
        formData.append('photos', file);
      });
  
      const token = localStorage.getItem("token");
      const headers = {};
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
      // headers['Content-Type'] = 'multipart/form-data'; // Set Content-Type header
  
      const response = await fetch(`${appURL}/chat/upload-file`, {
        method: 'POST',
        body: formData,
        headers: headers,
      });
  
      if (!response.ok) {
        throw new Error('File upload failed');
      }
  
      const data = await response.json();
      const fileUrls = data.url; // Assuming the server returns an array of file URLs
  
      console.log(fileUrls, "fileUrls");
      return fileUrls;
    } catch (error) {
      console.error('Error uploading files:', error);
      return [];
    }
  };
  


  const titlebtn = (adsId, ads_type) => {
    switch (ads_type) {
      case "Buy & Sell":
        navigate(`/buySellDetails/${adsId}`);
        break;
      case "event":
        navigate(`/eventDetails/${adsId}`);
        break;
      case "rental":
        navigate(`/rentDetails/${adsId}`);
        break;
      case "babysitter & nannie":
        navigate(`/babySitterDetails/${adsId}`);
        break;
      case "job":
        navigate(`/jobDetails/${adsId}`);
        break;
      case "Local_biz & Service":
        navigate(`/localBizDetails/${adsId}`);
        break;
      default:
        return "/";
    }
  };

  useEffect(()=>{
    console.log("showfullImage",showFullImage);
  },[showFullImage])

  const handleImageClick = () => {
    setShowFullImage(!showFullImage);
  };
  const handleRemoveImage = (e, index) => {
    e.stopPropagation();
    setSelectedFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
    setPreviewUrls(prevUrls => prevUrls.filter((_, i) => i !== index));
  };
  

  console.log("chatDetails",chatDetailsData);
  // sourabhverma

  console.log("chatDetailsData?.messages === null",chatDetailsData?.messages,"--",listData)

  return (
    <>
    {<div className="main__chatcontent chat_box_wrap">
      {(loader || !chatDetailsData)&& <div className="loader-overlay">
        <CircularProgress/>  
      </div>}
      {((listData && (chatDetailsData?.messages !== null && chatDetailsData?.messages !== undefined)) || listData === undefined) &&<div className="content__header">
        <div className="blocks">
          <div className="current-chatting-user">

          <div className="profile_img_header"
          onClick={() => {
            titlebtn(adsId, ads_type);
          }}
          >
          <div className="profile_images">
               {((chatDetailsData?.seller_id === (JSON.parse(localStorage.getItem("user"))?.userId)&&(chatDetailsData?.buyer_image !==null && chatDetailsData?.buyer_image !== "null")) ||(chatDetailsData?.seller_id !== (JSON.parse(localStorage.getItem("user"))?.userId)&&(chatDetailsData?.seller_image !== null && chatDetailsData?.seller_image !== "null"))) ? (
                  <img src={chatDetailsData?.seller_id === (JSON.parse(localStorage.getItem("user"))?.userId)?chatDetailsData?.buyer_image:chatDetailsData?.seller_image?chatDetailsData?.seller_image:userData?.profile_img?userData?.profile_img:userVectorImage} alt="seller"/>
                ) : (
                  <div className="seller_placeholder" style={getBackgroundColor(chatDetailsData?.seller_name ? chatDetailsData?.seller_name[0].toUpperCase() : '')}>
                   <p> {chatDetailsData?.seller_id === (JSON.parse(localStorage.getItem("user"))?.userId)?chatDetailsData?.buyer_name[0]:chatDetailsData?.seller_name[0]?chatDetailsData?.seller_name[0]:userData?.name?userData?.name:''} </p>
                  </div>
                )}

         {/* <img src={chatDetailsData.seller_image} alt="image altitude"/> */}
          </div>
           <div className="profile_section">
            <p>{chatDetailsData?.seller_id === (JSON.parse(localStorage.getItem("user"))?.userId)?chatDetailsData?.buyer_name:chatDetailsData?.seller_name?chatDetailsData?.seller_name:userData?.name?userData?.name:''}</p>
            <span>{chatDetailsData?.ads_name}</span>

            <h6 className={chatDetailsData?.status==='active' ? "online" : "offline"}>
                {chatDetailsData?.status==='active' ? "online" : "offline"}
              </h6>
              
           </div>
          </div>

          </div>
        </div>
        <div className="blocks">
          <div className="settings">
            {/* <button className="btn-nobg"> */}
              {/* <i className="fa fa-cog"></i> */}
            {/* </button> */}
          </div>
        </div>
      </div>}
      <div ref={messagesEndRef} />
    <div className="chat_body">
      {((listData && (chatDetailsData?.messages !== null  && chatDetailsData?.messages !== undefined)) || !listData)? (
        <div className="content__body" ref={chatScrollRef}>
          <div className="chat__items">
            {myMessages?.map((message, index) => (
              <ChatItem
                key={index}
                user={message?.user}
                type={"text"}
                msg={message.message}
                timeStamp={formatTime(message.timeStamp)}
                onDelete={() => deleteMessage(message.key)}
                setShowFullImage={setShowFullImage}
                setimageUrl={setimageUrl}
              />
            ))}
            {/* Scroll to the bottom when new messages are added */}
            <div ref={messagesEndRef} />
          </div>
        </div>
      ) : (
        <>
        {
        (!loader || chatDetailsData?.messages === null) && <div style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column'}}>
        <img src="https://cdn.vectorstock.com/i/500p/30/21/data-search-not-found-concept-vector-36073021.avif"/>
        <h3>No Chat Found!</h3>
        </div>
}
        </>
      )}
    </div>
      <div className="content__footer">
      <div className="image-previews" style={{ display: 'flex' }}>
  {selectedFiles?.length > 0 && previewUrls.map((url, index) => (
    <div key={index} className="image-preview" style={{ position: 'relative', margin: '10px' }}>
      <img
        src={url}
        alt="Preview"
        onClick={() => handleImageClick(index)}
        style={{ width: '100px', height: '100px', cursor: 'pointer' }}
      />
      <button
        onClick={(e) => handleRemoveImage(e, index)}
        style={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          background: 'red',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          cursor: 'pointer',
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        &times;
      </button>
    </div>
  ))}
</div>

      {(showFullImage && imageUrl)&& (
        <div
          onClick={() => {setShowFullImage(false);}}
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            src={imageUrl}
            alt="Full Preview"
            style={{ maxWidth: '90%', maxHeight: '90%' }}
          />
        </div>
      )}
      {((listData && (chatDetailsData?.messages !== null &&  chatDetailsData?.messages !== undefined)) || !listData) &&<div className="sendNewMessage">
        <button className="addFiles" onClick={() => document.getElementById('fileInput').click()}>
          <i className="fa fa-plus"></i>
        </button>
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          onChange={handleFileSelect}
          multiple
        />
        <input
          type="text"
          placeholder="Type a message here"
          onChange={e => setInput(e.target.value)}
          value={input}
          onKeyPress={handleKeyPress}
        />
        <button
          className="btnSendMsg"
          id="sendMsgBtn"
          onClick={sendMessage}
          disabled={input === '' && previewUrls?.length == 0}
        >
          <i className="fa fa-paper-plane"></i>
        </button>
      </div>}
      
    </div>
    </div>}
    </>
  );
};

export default ChatContent;
