import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import Swal from 'sweetalert2';

async function confirmUserActionsweetalert(questionText = 'Sure to proceed?') {

    const result = await Swal.fire({
        title: questionText,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#e41e26'
    });

    return Boolean(result.value);
}

function confirmUserAction(title = 'Sure to proceed?', message = null) {
    return new Promise((resolve, reject) => {
        try {
            const options = {
                title: title,
                message: message,
                buttons: [
                    {
                        label: 'Proceed',
                        onClick: () => resolve(true)
                    },
                    {
                        label: 'Cancel',
                        onClick: () => reject(false)
                    }
                ],
                closeOnEscape: false,
                closeOnClickOutside: false,
                keyCodeForClose: [],
                willUnmount: () => { },
                afterClose: () => { },
                onClickOutside: () => { },
                onKeypress: () => { },
                onKeypressEscape: () => { },
                overlayClassName: 'overlay-custom-className-name'
            };

            confirmAlert(options);
        } catch (err) {
            return resolve(false);
        }
    });
}

export {confirmUserAction, confirmUserActionsweetalert};
