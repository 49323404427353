import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import getFetchConfig from "../../utils/getFetchConfig";
import appURLs from "../../appURL";
import "./Banner.css"

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Banner({ homepageBanner, sidebanner, bottombanner }) {
  const fetchConfig = getFetchConfig();
  const appURL = appURLs();

  const [bannerData, setBannerData] = useState([]);

  const adsOnValidityData = async () => {
    try {
      const response = await fetch(`${appURL}/posts/ads/adons-ads`, {
        ...fetchConfig,
        method: "GET",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      setBannerData(jsonData.banner.filter(item => item.status === true));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    adsOnValidityData();
  }, []);

  const filteredBanners = bannerData.filter(bannerItem => {
    if (homepageBanner && bannerItem.img_type === "topbanner") return true;
    if (sidebanner && bannerItem.img_type === "sidebanner") return true;
    if (bottombanner && bannerItem.img_type === "bottombanner") return true;
    return false;
  });

  const settings = {
    appendDots: dots => (
        <ul className="custom-dots">
          {dots}
        </ul>
      ),
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className='banner_tag'>
      {filteredBanners.length > 0 ? (
        <Slider {...settings}>
          {filteredBanners.map(bannerItem => (
            <div key={bannerItem._id}>
              <img src={bannerItem.image} alt="" />
            </div>
          ))}
        </Slider>
      ) : null}
    </div>
  );
}

export default Banner;
