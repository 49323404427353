import React, { useEffect, useState } from 'react';

import getFetchConfig from '../../utils/getFetchConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import appURLs from '../../appURL';

import Header from '../../component/header/Header';
import { Link } from 'react-router-dom';
import { redirect } from "react-router-dom";
import CommonModalWrapper from '../common-modal-Wrapper/CommonModalWrapper';
import AdsCategories from '../postAds/ads-categories/AdsCategories';
import CloseIcon from '../Icons/CloseIcon';


import style from "./paumentSuccess.module.css"


export default function PaymentSuccess() {

  const [modalIsModalForPostAdsCategries, updateModalIsModalForPostAdsCategries] = useState(false);


  const toggleModalForPostAdscategories = (toggleValue = false) => {

      if(toggleValue) document.body.classList.add("body-fixed");
      else  document.body.classList.remove("body-fixed");

      updateModalIsModalForPostAdsCategries(toggleValue);

  }

  const [showLoadder, toggleShowLoader] = useState(false),
    [adsData, updateAdsData] = useState({
      ads_name: "" ,
      ads_status: null
    });

    const onClickhjgghsj = () =>{
      window.location=`/`;
  }
  const fetchConfig = getFetchConfig(),
    appURL = appURLs();


  const fetchAdsData = (adsIdndType = {}) => {


    if (adsIdndType) {

      let endPoint = '';

      switch (adsIdndType?.adsType) {
        case "jobs":
          endPoint = `/posts/jobs/ad_details?adsId=${adsIdndType?.id}`
          break;
        case "events":
          endPoint = `/posts/events/ad_details?adsId=${adsIdndType?.id}`
          break;
        case "BuyAndSell":
          endPoint = `/posts/buysell/ad_details?adsId=${adsIdndType?.id}`
          break;
        case "localbizANDservices":
          endPoint = `/posts/biz/ad_details?adsId=${adsIdndType?.id}`
          break;
        case "BabbysitterAndNanny":
          endPoint = `/posts/babysitter/ad_details?adsId=${adsIdndType?.id}`
          break;
        case "rentals":
          endPoint = `/room-rents/ad_details?adsId=${adsIdndType?.id}`
          break;

        default:
          break;
      }

      console.log(endPoint)

      fetch(`${appURL}${endPoint}`, {
        ...fetchConfig,
        method: "GET"
      })
        .then(handleFetchErrors)
        .then((res) => {
            console.log(`res`,res);
            
          toggleShowLoader(false);
          if (res?.status == 200) {
            const data = res?.ads_details;
            updateAdsData({
              ads_name: data?.adsInfo?.title,
              ads_status: data?.status
            })
          }
        })
        .catch((err) => {
          toggleShowLoader(false);
          console.log(err)
        });
    }

  }

  const handleToHomePage = () => {
    localStorage.removeItem("adsInfo");
    redirect("/")
  }

  useEffect( () => {

    const adsDetail = JSON.parse(localStorage.getItem('adsInfo'));
    console.log(adsDetail, adsDetail)

    fetchAdsData(adsDetail)

  }, [])
  
  const adsInfoString = localStorage.getItem('adsInfo');
  const adsInfo = adsInfoString ? JSON.parse(adsInfoString) : null;

  function generateLink(adsType, id) {
    if (adsType === 'rentals') {
      return `rentDetails/${id}`;
    } else if (adsType === 'jobs') {
      return `jobDetails/${id}`;
     
    } else if (adsType === 'events') {
      return `eventDetails/${id}`;
    
    } else if (adsType === 'localbizANDservices') {
      return `localBizDetails/${id}`;
     
    } 
    else if (adsType === 'BabbysitterAndNanny') {
      return `babySitterDetails/${id}`;
    } 
    else if (adsType === 'BuyAndSell') {
      return `buySellDetails/${id}`;
    } 
    else {
      return ''; // Or any default link
    }
  }

  function handleClick(adsType, id) {
    const link = generateLink(adsType, id);
    if (link) {
      // Navigate to the generated link
      window.location.href = link;
    } else {
      // Reload the page if the link is empty
      window.location.reload();
    }
  }

  return (
    <>
 <CommonModalWrapper isOpen={modalIsModalForPostAdsCategries} className={style.ads_categories_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalForPostAdscategories(false)} />
                <AdsCategories toggleModalForPostAdscategories ={ toggleModalForPostAdscategories }  />
            </CommonModalWrapper>

      <CommonModalWrapper isOpen={true} className={style.success_payment_modal} >

        {/* <CloseIcon onClick={handleToHomePage} /> */}
        {/* <RoomRents toggleModalVisibilty={toggleModalVisibilty} adsCategoriesId={selectedAdsCategories?.adsCategoriesId} /> */}
        <main id='span-2' className={style.main_wrapper}>
          <section className={style.heading_section}>
            <svg width="150" height="150" viewBox="0 0 232 232" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="116" cy="116" r="115.753" fill="#FE4A49" fill-opacity="0.06" />
              <circle cx="116" cy="116" r="89" fill="#FE4A49" fill-opacity="0.12" />
              <path d="M178 118C178 134.178 171.573 149.694 160.134 161.134C148.694 172.573 133.178 179 117 179C100.822 179 85.3062 172.573 73.8665 161.134C62.4268 149.694 56 134.178 56 118C56 101.822 62.4268 86.3062 73.8665 74.8665C85.3062 63.4268 100.822 57 117 57C133.178 57 148.694 63.4268 160.134 74.8665C171.573 86.3062 178 101.822 178 118ZM147.729 94.8962C147.184 94.3535 146.536 93.9261 145.822 93.6398C145.108 93.3534 144.344 93.2139 143.576 93.2295C142.807 93.2452 142.049 93.4157 141.348 93.7309C140.646 94.0461 140.016 94.4995 139.494 95.064L113.012 128.805L97.053 112.838C95.9689 111.828 94.5351 111.278 93.0535 111.304C91.572 111.33 90.1584 111.93 89.1106 112.978C88.0629 114.026 87.4627 115.439 87.4365 116.921C87.4104 118.402 87.9603 119.836 88.9705 120.92L109.146 141.104C109.69 141.646 110.337 142.074 111.049 142.361C111.762 142.648 112.524 142.788 113.292 142.774C114.06 142.76 114.817 142.591 115.518 142.278C116.22 141.965 116.851 141.514 117.374 140.951L147.813 102.903C148.85 101.824 149.424 100.381 149.409 98.8838C149.395 97.3869 148.794 95.9552 147.736 94.8962H147.729Z" fill="#FD7E14" />
            </svg>

            <h3 className={style.heading}>Congratulations</h3>
            <p className={style.thanks_text}>Thank you for posting your Ad <span style={{color: "Black"}}>"{JSON.parse(localStorage.getItem('adsInfo')).title || JSON.parse(localStorage.getItem('adsCategories'))}"</span> (Ad Id: <span style={{color: "Black"}}>{JSON.parse(localStorage.getItem('advertisementId'))})</span></p>
            {/* <p>We moderate listings in order to follow safety practices, which can sometimes take a few minutes. Once your Ad is approved, it will be available to  <a href="#">view here</a></p> */}
            <p>We moderate listings in order to follow safety practices, which can sometimes take a few minutes. Once your Ad is approved, it will be available to  <Link onClick={() => handleClick(adsInfo.adsType, adsInfo.id)} className={style.congratsLink}>view here</Link>.</p>
            <p>

            {localStorage.getItem('user') ? (
              <>
                We sent a confirmation email to{" "}
                <span style={{ fontWeight: 700, color: "black" }}>
                  {JSON.parse(localStorage.getItem('user')).email_address}
                </span>
              </>
            ) : (
              ""
            )}
          </p>

                   
          </section>
          <section className={`button_wrapper`}>

                <div id="back_button" className="form-group"
                     onClick={()=>{toggleModalForPostAdscategories(true)}}>
                        <p  className="btn-confirm"> Post another ads </p>
                    </div>


                  <Link to="/" id={style.submit_btn_wrapper} className="form-group">
                      <div onClick={onClickhjgghsj} >
                          {
                              <button id={style.home_btn} type="submit" className="btn btn-confirm">
                                  Manage my ads
                              </button>
                          }
                      </div>
                  </Link>


</section>

        </main>

      </CommonModalWrapper>

      <div className="Main-wrapper-section">
        <div className="view_project">
          <div className='numberlists'>
            <div className='counting-number-lists'>
              <h3>15+M</h3>
              <p>Happy User</p>
            </div>
            <div className='counting-number-lists'>
              <h3>64+K</h3>
              <p>Verified Experts</p>
            </div>
            <div className='counting-number-lists'>
              <h3>98+</h3>
              <p>Categories</p>
            </div>
          </div>
        </div>
        <div className="section">




        </div>




      </div>
    </>
  )
}
