import React, { useState } from "react";
import { toast } from 'react-toastify';

import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import LoaderButton from "../buttons/loader-button/LoaderButton";
import style from "./deleteAccount.module.css"
import appURLs from "../../appURL";

export default function DeleteAccount({ loginSource = false, updateModalVisibilityForDeleteAccount = false }) {


    const fetchConfig = getFetchConfig();
    const appURL = appURLs();

    const [passwordInputTypeForPassword, updatePasswordInputTypeForPassword] = useState(`password`);


    const [showLoader, toggleLoader] = useState(false),
        [formData, updateFormData] = useState({ password: '', });

    const { password } = formData;

    const onChangeHandlerForFormData = (e) => {
        const inputName = e?.target?.name || e?.name,
            inputValue = e?.target?.value || e?.value;

        updateFormData((prev) => ({
            ...prev,
            [inputName]: inputValue
        }));
    };


    const passwordInputChangeHandler = (inputTpe) => {

        updatePasswordInputTypeForPassword(inputTpe);

    }

     const clearUrl = () => {
        window.history.replaceState({}, document.title, window.location.pathname);
    };


    const onSubmitHandler = async (e) => {
        e.preventDefault();

        
        let errorMessage = ``
        
        const missingData = [],
        invalidData = [];

        if (loginSource) errorMessage = `please enter valid password`;
        else errorMessage = `please enter valid string`;
        
        if (!password) missingData.push(errorMessage);
        
        
        // Show errors if needed
        if (missingData.length || invalidData.length) {
            if (missingData.length) toast.error(`Missing: ${missingData.join(`, `)}`);
            if (invalidData.length) toast.error(`Invalid: ${invalidData.join(`, `)}`);
            return;
        }
        
        toggleLoader(true)
        fetch(`${appURL}/account-delete`, {
            ...fetchConfig,
            body: JSON.stringify({ password })
        })
        .then(handleFetchErrors)
        .then((res) => {
            toggleLoader(false)
            console.log(`resresresres`, res)
            
            if (res?.status === 200) {
                updateModalVisibilityForDeleteAccount(false)
                window.history.replaceState({}, document.title, window.location.pathname);
                    
                    localStorage.removeItem(`token`);
                    window.location.reload(false);
                    return toast.success(res?.message);
                } else if (res?.status === 400) {
                    return toast.error(res?.message)
                } else {
                    return toast.error(`Something isn't working right`);
                }
                

            }).catch(standardFetchHandlers.error)
            .finally(() => {
                setTimeout(() => {
                    toggleLoader(false);
                }, 1000);
            });
    };


    return (

        <main>

            <form onSubmit={onSubmitHandler} >

                {
                    JSON.parse(localStorage.getItem(`user`))?.source === "email" ?
                        <div className="form-group">
                            <label className={style.label} for="password"> Please enter your password to delete account</label>
                            <div className={style.password_input_wrapper}>
                                <input type={passwordInputTypeForPassword} onChange={onChangeHandlerForFormData} className="form-control" name='password' placeholder="Please enter your password" autoComplete="off" />
                                {
                                    passwordInputTypeForPassword === "text" ?
                                        <svg onClick={() => { passwordInputChangeHandler(`password`, 1) }} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                        : <svg onClick={() => { passwordInputChangeHandler(`text`, 1) }} fill="#000000" width="20" height="20" viewBox="0 -16 544 544" xmlns="http://www.w3.org/2000/svg" ><title>hide</title><path d="M108 60L468 420 436 452 362 378Q321 400 272 400 208 400 154 361 99 322 64 256 79 229 102 202 124 174 144 160L76 92 108 60ZM368 256Q368 216 340 188 312 160 272 160L229 117Q254 112 272 112 337 112 392 152 446 192 480 256 474 269 461 288 448 307 434 322L368 256ZM272 352Q299 352 322 338L293 309Q283 312 272 312 249 312 233 296 216 279 216 256 216 247 220 236L190 206Q176 229 176 256 176 296 204 324 232 352 272 352Z" /></svg>
                                }

                            </div>

                        </div> :

                        <div className="form-group">
                            <label htmlFor="password" > Please type text `menehariya`</label>
                            <input type="text" onChange={onChangeHandlerForFormData} name='password' className="form-control" placeholder="Please type text `menehariya`" autoComplete="off" />
                        </div>
                }

                {
                    showLoader ?

                        <section className="button_wrapper">

                            <div id="back_button" className="form-group">
                                <p onClick={() => { updateModalVisibilityForDeleteAccount(false) }} id={style.back_button} className=" btn-confirm">
                                    Back
                                </p>

                            </div>

                            <LoaderButton />


                        </section>
                        :
                        <section className="button_wrapper">

                        <div id="back_button" className="form-group">
                                <p onClick={() => { updateModalVisibilityForDeleteAccount(false);clearUrl(); }} id={style.back_button} className=" btn-confirm">
                                    Back
                                </p>

                            </div>

                            <div id={style.submit_btn} className="form-group">
                                <button type="submit" className="btn btn-confirm">
                                    Delete
                                </button>

                            </div>


                        </section>

                }


            </form>
        </main>
    )
}