import React, { useState, useEffect } from 'react';
import EmailChangeCard from '../../component/email-chnage-card/EmailChangeCard';
import styles from "./changeEmailAddress.module.css"

import { useSearchParams } from 'react-router-dom';

import getFetchConfig from '../../utils/getFetchConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';
import standardFetchHandlers from '../../utils/standardFetchHandlers';

import appURLs from '../../appURL';

export default function     ChangeEmailAddress() {

    const [searchParams] = useSearchParams();

    const fetchConfig = getFetchConfig(),
    appURL = appURLs();

    const [showLoadder , toggleShowLoader] = useState(false),
        [isEmailChanged, updateIsEmailChanged] = useState({
            value: false,
            messgae: ``
        })


        // fetching ads categories
        const fetchPlanData = (secret) => {
            toggleShowLoader(true)
            fetch(`${appURL}/verify-email/?secret=${secret}`, {
                ...fetchConfig,
            })
                .then(handleFetchErrors)
                .then((res) => {
                    console.log(res)
                    toggleShowLoader(false)
    
                    if (res.status === 200) {
                        updateIsEmailChanged({
                            value: true,
                            messgae: "Your email has been changed successfully"
                        })
                    } else {
                       
                    }
    
                })
                .catch((err) => {
                    toggleShowLoader(false)
                    console.log(err)
                });
        }
    
        useEffect(() => {

            const  secret =  searchParams.get('secret'); 
            if(!secret){
                updateIsEmailChanged({
                    value: false,
                    messgae: `Invalid secret`
                })
            } else {
                fetchPlanData(secret);
            }
        }, []);

        console.log(isEmailChanged)
    
    return (
        <div className={styles.main_wrapper}>
            <EmailChangeCard messgae ={ isEmailChanged.messgae}  />
        </div>
    )
}
