exports.getCurrencySymbol = (currencyCode) => {
  try {
    const numberFormat = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode });
    const parts = numberFormat.formatToParts(0);
    const currencySymbol = parts.find(part => part.type === 'currency').value;
    return currencySymbol;
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
  };


