import styles from './styles.module.css';

const CloseIcon = (props) => {
    const clickHandler = props.onClick,
        className = props.className;

    return (
        <span className={`${styles.close_icon_wrapper} ${className}`} onClick={clickHandler}>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="none" stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
        </span>
    );
};


export default CloseIcon;