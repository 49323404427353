import React from 'react'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export default function footer_menu_other(props) {
  return (
            <div className="footer-wrapper">
               <h6><b>{props.title}</b></h6>
               <Link to="/view_for_Babysitter_and_nannies"><p>{props.text1}</p></Link>
               <Link to="/view_for_rent"><p>{props.text2}</p></Link>
               <Link to="/Termandcondition"><p>{props.text3}</p></Link>
              <Link to="/Privacy"><p>{props.text4}</p></Link> 
              <Link to="/view_for_localBiz_and_Services"><p>{props.text5}</p></Link> 
              <Link to="/view_for_Buy_and_Sell"><p>{props.text6}</p></Link> 
            </div> 
  )
}