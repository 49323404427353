import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import appURLs from "../../appURL";
import getFetchConfig from "../../utils/getFetchConfig";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "../card";
import Jobcard from "../jobcard";
import Eventlistcard from "../Eventlistcard";
import Buysellcard from "../Buysellcard";
import Babysittercard from "../babysitter";
import LocalBizcard from "../LocalBizServices";
import getSymbolFromCurrency from "currency-symbol-map";
import "./Search_query.css"

import moment from 'moment-timezone';
import 'moment-duration-format';

function Search_query({ latitudes, longitudes }) {
  const mySearch = useParams();

  const appURL = appURLs();
  const fetchConfig = getFetchConfig();

  const [searchData, setSearchData] = useState([]);
  const [roomRentData, setRoomRentData] = useState([]);
  const [myJobsData, setMyJobData] = useState([]);
  const [eventsData, setEventData] = useState([]);
  const [babySitterData, setBabySitterData] = useState([]);
  const [buySellData, setBuySellData] = useState([]);
  const [localBizData, setLocalBizData] = useState([]);

  const [isAllDataEmpty , setIsAllDataEmpty] = useState('')

  const [rentfeatureStatus , setRentFeaturedStatus] = useState(false);
  const [jobFeaturedStatus , setJobFeaturedStatus] = useState(false);
const [eventFeaturedStatus, setEventFeaturedStatus] = useState(false);
const [buySellFeaturedStatus, setBuySellFeaturedStatus] = useState(false);
const [bizFeaturedStatus, setBizFeaturedStatus] = useState(false);
const [babyFeaturedStatus, setBabyFeaturedStatus] = useState(false);

  console.log(mySearch.mySearch, "mySearch");

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const currentDate = new Date().toISOString().split('T')[0];

  const currentDates = new Date();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  };

  const localTimeString = currentDates.toLocaleString('en-US', options);
  const formattedDateTime = `${currentDates.getFullYear()}-${(currentDates.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${currentDates.getDate().toString().padStart(2, '0')} ${currentDates
    .getHours()
    .toString()
    .padStart(2, '0')}:${currentDates.getMinutes().toString().padStart(2, '0')}:${currentDates
    .getSeconds()
    .toString()
    .padStart(2, '0')}`;
  // Get the timezone
    // Format the local time
    

    // Get the timezone
  

    const getTimeSinceCreation = (dateString, mytimers) => {

  // let dateString = rentDetailsData.ads_details.active_on_virtual;

  // Split the string at "T" and concatenate the date and time parts
  let parts = dateString.split("T");
  let extractedDate = parts[0] + "T" + parts[1].split(".")[0];
  
  console.log(extractedDate, "extractedDate");
  const convertToTimeZone = (datetime, fromTimeZone, toTimeZone) => {
    return moment.tz(datetime, fromTimeZone).tz(toTimeZone).format('YYYY-MM-DD HH:mm:ss');
  };

const originalDatetime = extractedDate;
// const originalTimeZone = rentDetailsData.ads_details.location_timezone;
// console.log(originalTimeZone,"sbjsxjxx");

const timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
const targetTimeZone = timezoneString;

const convertedDatetime = convertToTimeZone(originalDatetime, mytimers, targetTimeZone);

    
console.log(convertedDatetime,"hhhhgxy",formattedDateTime);





  if (formattedDateTime && convertedDatetime) {
    const secondsDiff = moment.duration(moment(formattedDateTime).diff(convertedDatetime)).asSeconds();


    if (secondsDiff < 60) {
      return "just now";
    } else if (secondsDiff < 3600) {
      const minutesDiff = Math.floor(secondsDiff / 60);
      return minutesDiff === 1 ? "1 minute ago" : `${minutesDiff} minutes ago`;
    } else if (secondsDiff < 86400) {
      const hoursDiff = Math.floor(secondsDiff / 3600);
      return hoursDiff === 1 ? "1 hour ago" : `${hoursDiff} hours ago`;
    } else if (secondsDiff < 172800) {
      return "Yesterday";
    } else {
      const daysDiff = Math.floor(secondsDiff / 86400);
      return daysDiff === 1 ? "1 day ago" : `${daysDiff} days ago`;
    }
  }
  }


  const handleSearch = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };
  
      const apiUrl = `${appURL}/posts/ads/search?search_term=${mySearch.mySearch}&latitude=${latitudes}&longitude=${longitudes}`;
  
      const response = await fetch(apiUrl, {
        ...fetchConfig,
        method: "GET",
        headers: headers,
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const jsonData = await response.json();

      setSearchData(jsonData.searchdata)
      window.scrollTo(0, 700);

      const isAllDataEmpty = jsonData.searchdata.every(category => category.data.length === 0);
  
      // Find the element in searchdata that matches the category 'Rentals'
      const foundRentalsCategory = jsonData.searchdata.find(
        (item) => item.category === "Rentals"
      );
      const featuredStatusForRentals = foundRentalsCategory
  ? foundRentalsCategory.data.map((record) => {
      const isFeatured =
        record.addons_validity &&
        record.addons_validity.some((addon) => addon.name === "Featured");

      return isFeatured;
    })
  : [];

console.log(featuredStatusForRentals, "featuredStatusForRentals");
setRentFeaturedStatus(featuredStatusForRentals)



      const foundJobsCategory = jsonData.searchdata.find(
        (item) => item.category === "Jobs"
      );

      const featuredStatusForJobs = foundJobsCategory
      ? foundJobsCategory.data.map((record) => {
          const isFeatured =
            record.addons_validity &&
            record.addons_validity.some((addon) => addon.name === "Featured");
    
          return isFeatured;
        })
      : [];
    
    console.log(featuredStatusForJobs, "featuredStatusForJobs");
    setJobFeaturedStatus(featuredStatusForJobs)

    
      const foundEventCategory = jsonData.searchdata.find(
        (item) => item.category === "Events"
      );
      
      const featuredStatusForEvents = foundEventCategory
        ? foundEventCategory.data.map((record) => {
            const isFeatured =
              record.addons_validity &&
              record.addons_validity.some((addon) => addon.name === "Featured");
      
            return isFeatured;
          })
        : [];
      
      console.log(featuredStatusForEvents, "featuredStatusForEvents");
      setEventFeaturedStatus(featuredStatusForEvents)



      const foundbabySitterCategory = jsonData.searchdata.find(
        (item) => item.category === "Babysitters & Nannies"
      );
      const featuredStatusForBabySitters = foundbabySitterCategory
      ? foundbabySitterCategory.data.map((record) => {
          const isFeatured =
            record.addons_validity &&
            record.addons_validity.some((addon) => addon.name === "Featured");
    
          return isFeatured;
        })
      : [];
    
    console.log(featuredStatusForBabySitters, "featuredStatusForBabySitters");

    setBabyFeaturedStatus(featuredStatusForBabySitters);


      const foundlocalBizCategory = jsonData.searchdata.find(
        (item) => item.category === "Local Biz & Services"
      );
      const featuredStatusForLocalBiz = foundlocalBizCategory
      ? foundlocalBizCategory.data.map((record) => {
          const isFeatured =
            record.addons_validity &&
            record.addons_validity.some((addon) => addon.name === "Featured");
    
          return isFeatured;
        })
      : [];
    
    console.log(featuredStatusForLocalBiz, "featuredStatusForLocalBiz");
    setBizFeaturedStatus(featuredStatusForLocalBiz)


      const foundBuySellCategory = jsonData.searchdata.find(
        (item) => item.category === "Buy & Sell"
      );

      const featuredStatusForBuySell = foundBuySellCategory
  ? foundBuySellCategory.data.map((record) => {
      const isFeatured =
        record.addons_validity &&
        record.addons_validity.some((addon) => addon.name === "Featured");

      return isFeatured;
    })
  : [];

    console.log(featuredStatusForBuySell, "featuredStatusForBuySell");
    setBuySellFeaturedStatus(featuredStatusForBuySell)
  
      // Set RoomRentData and MyJobData based on the found element's data property
      setRoomRentData(foundRentalsCategory ? foundRentalsCategory.data : []);
      setMyJobData(foundJobsCategory ? foundJobsCategory.data : []);
      setEventData(foundEventCategory ? foundEventCategory.data : []);
      setBabySitterData(foundbabySitterCategory ? foundbabySitterCategory.data : []);
      setLocalBizData(foundlocalBizCategory ? foundlocalBizCategory.data : []);
      setBuySellData(foundBuySellCategory ? foundBuySellCategory.data : []);

      setIsAllDataEmpty(isAllDataEmpty);


        console.log(isAllDataEmpty,"ihfvdhsijdfhuejfibhwdijfgvwbhijdfgyhiwjbhwbhwfgydvwbhfgdvwbhfgyvwbhfgy")


  
      console.log(jsonData.searchdata, "Search results data +++++++++");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  useEffect(() => {
    handleSearch();

  }, [mySearch, isAllDataEmpty]);
  return (
    <div>

            {isAllDataEmpty ? (
           <div className="no_data_slash">
                <h1>No Data Found</h1>
            </div>  
                ) : (
                  <>
                  
                  {roomRentData && roomRentData.length > 0 ? (
  <>
    <div className="body-card">
      <div className="body-card-text">Rentals</div>
      <div className="view-btn"></div>
    </div>

    <Carousel
      responsive={responsive}
      ssr
      infinite={true}
      containerClass="carousel-container"
      itemClass="carousel-item-padding-40-px"
    >
      {roomRentData.map((record, index) => (
        <Card
          key={index}
          _id={record._id}
          title={record.adsInfo.title}
          adsButton="Price Drop"
          location={record.adsInfo.location.location_name}
          shareTitle={"rentDetails"}
          titleColor=""
          myFavourite={record.is_favorite}
          imageSrc={
            record.adsInfo.image &&
            record.adsInfo.image.map((img) => img.url)
          }
          featured={rentfeatureStatus[index] ? true : false} 
          dateType={getTimeSinceCreation(record.active_on_virtual, record.location_timezone)}
          rentAmount={
            record.adsInfo.rent.is_contact &&
            record.adsInfo.rent.amount === null ? (
              <p>Please Contact</p>
            ) : record.adsInfo.rent.negotiable &&
              record.adsInfo.rent.amount !== null ? (
              <p>
                {getSymbolFromCurrency(record.adsInfo.rent.currency)}
                {record.adsInfo.rent.amount}{" "}
                {record.adsInfo.rent_info || ""} Negotiable
              </p>
            ) : record.adsInfo.rent.negotiable ? (
              <p>Negotiable</p>
            ) : record.adsInfo.rent.currency &&
              record.adsInfo.rent.amount !== null ? (
              <p>
                {getSymbolFromCurrency(record.adsInfo.rent.currency)}
                {record.adsInfo.rent.amount}
                {record.adsInfo.rent_info || ""}
              </p>
            ) : null
          }
          UrgentLabel={
            record.addons_validity.some((item) => item.name === "Urgent")
              ? "Urgent"
              : ""
          }
        />
      ))}
    </Carousel>
  </>
) : (
""
)}





      

{/* Job data */}
{myJobsData && myJobsData.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">Jobs</div>
    <div className="view-btn">
      {/* <i onClick={handleRedirectJobs}>
        <button type="button" className="btn btn-view">
          View All
        </button>
      </i> */}
    </div>
  </div>
)}


{myJobsData && myJobsData.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {myJobsData.map((job, index) => (
        //  <Link to={`/jobDetails/${job._id}`} style={{ color: "#F17D3A" }}>
      <Jobcard
        key={index}
        _id={job._id}
        title={job.adsInfo.title}
        shareTitle ={"jobDetails"}
        myFavourite={job.is_favorite}
        location={job.adsInfo.location.location_name}
        imageSrc={job.adsInfo.image && job.adsInfo.image.map(img => img.url)} 
        featured={jobFeaturedStatus[index] ? true : false} 
        dateType={getTimeSinceCreation(job.active_on_virtual,job.location_timezone)}
        jobAmount={
          (job.adsInfo.salary.amount) ? (
            <p>{getSymbolFromCurrency(job.adsInfo.salary.currency)} {job.adsInfo.salary.amount} {job.adsInfo.salary_info}</p>
          ) : (
          null
          )
        }
        eventLabel={
          job.addons_validity.some(item => item.name === "Upcoming Event")
            ? "Upcoming Event"
            : job.addons_validity.some(item => item.name === "Urgent")
              ? "Urgent"
              : ""
        }   
      />
      //  </Link>
    ))}
  </Carousel>
)}



{/* Job data */}
{eventsData && eventsData.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">Events</div>
    <div className="view-btn">
      {/* <i onClick={handleRedirectJobs}>
        <button type="button" className="btn btn-view">
          View All
        </button>
      </i> */}
    </div>
  </div>
)}

{eventsData && eventsData.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {eventsData.map((e, index) => (
      //  <Link to={`/eventDetails/${e._id}`} style={{ color: "#F17D3A" }}>
      <Eventlistcard
        key={index}
        _id={e._id}
        title={e.adsInfo.title}
        shareTitle ={"eventDetails"}
        myFavourite={e.is_favorite}
        location={e.adsInfo.location.location_name}
        titleColor=""
        imageSrc={e.adsInfo.image && e.adsInfo.image.map(img => img.url)}
        featured={eventFeaturedStatus[index] ? true : false} 
        dateType={getTimeSinceCreation(e.active_on_virtual, e.location_timezone)}
        eventAmount={
          <p>
            {(e.adsInfo.ticket_price.regular_ticket_price ?? 0) === 0
              ? "Free"
              : `${getSymbolFromCurrency(e.adsInfo.ticket_price.currency)}${e.adsInfo.ticket_price.regular_ticket_price}`}
          </p>
        }
         
        eventLabel={
          e.addons_validity.some(item => item.name === "Upcoming Event")
            ? "Upcoming Event"
            : e.addons_validity.some(item => item.name === "Urgent")
              ? "Urgent"
              : ""
        }        

      />
      //  </Link>
    ))}
  </Carousel>
)}


{/* Babysitters & Nannies Data */}
{babySitterData && babySitterData.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">Babysitters & Nannies</div>
    <div className="view-btn">
      {/* <i onClick={handleRedirectBabySitter}>
        <button type="button" className="btn btn-view">
          View All
        </button>
      </i> */}
    </div>
  </div>
)}

{babySitterData && babySitterData.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {babySitterData.map((baby, index) => (
        //  <Link to={`/babySitterDetails/${baby._id}`} style={{ color: "#F17D3A" }}>
      <Babysittercard
        key={index}
        _id={baby._id}
        title={baby.adsInfo.title}
        shareTitle ={"babySitterDetails"}
        // titlebtn={
        //   <Link to={`/babySitterDetails/${baby._id}`} style={{ color: "#F17D3A" }}>
        //     View Details
        //   </Link>
        // }
        location={baby.adsInfo.location.location_name}
        imageSrc={baby.adsInfo.image && baby.adsInfo.image.map(img => img.url)} 
        duration=""
        titleColor=""
        myFavourite={baby.is_favorite}
        featured={babyFeaturedStatus[index] ? true : false} 
        dateType={getTimeSinceCreation(baby.active_on_virtual, baby.location_timezone)}
        babyAmount = {
          baby.adsInfo.expected_salary_amount && baby.adsInfo.expected_salary_amount.is_contact ? (
            <p>Please Contact</p>
          ) : (
            baby.adsInfo.expected_salary_amount ? (
              baby.adsInfo.expected_salary_amount.amount === 0 ? (
                <p>Please Contact</p>
              ) : (
                <p>
                  {getSymbolFromCurrency(baby.adsInfo.expected_salary_amount.currency)}
                  {baby.adsInfo.expected_salary_amount.amount}
                  {baby.adsInfo.expected_salary_rate}
                </p>
              )
            ) : null
          )
        }
        
        
        eventLabel={
          baby.addons_validity.some(item => item.name === "Upcoming Event")
            ? "Upcoming Event"
            : baby.addons_validity.some(item => item.name === "Urgent")
              ? "Urgent"
              : ""
        }   
        
      />
      // </Link>
    ))}
  </Carousel>
)}


        


   {/* Buy & Sell Data */}
{buySellData && buySellData.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">Buy & Sell</div>
    <div className="view-btn">
      {/* <i onClick={handleRedirectBuySell}>
        <button type="button" className="btn btn-view">
          View All
        </button>
      </i> */}
    </div>
  </div>
)}


{buySellData && buySellData.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {buySellData.map((buy, index) => (
      //  <Link to={`/buySellDetails/${buy._id}`} style={{ color: "#F17D3A" }}>
      <Buysellcard
        key={index}
        _id={buy._id}
        title={buy.adsInfo.title}
        location={buy.adsInfo.location.location_name}
        imageSrc={buy.adsInfo.image && buy.adsInfo.image.map(img => img.url)}
        featured={buySellFeaturedStatus[index] ? true : false} 
        myFavourite={buy.is_favorite}
        shareTitle ={"buySellDetails"}
        dateType={getTimeSinceCreation(buy.active_on_virtual , buy.location_timezone)}
        localbtn="Local"
        buyAmount={
          buy.adsInfo.price.is_contact ? (
            <p>Please Contact</p>
          ) : (
            <div style={{display:"flex"}}>
              {buy.price_drop && buy.adsInfo.price.amount > 0 ? (
                <>
                  <p style={{ textDecoration: 'line-through', color:"grey" }}>
                    {getSymbolFromCurrency(buy.adsInfo.price.currency)}
                    {buy.adsInfo.price.amount}
                  </p>
                  <p>
                    {getSymbolFromCurrency(buy.adsInfo.price.currency)}
                    {buy.price_drop}
                  </p>
                  {buy.adsInfo.price.negotiable ? (
                    <p>Negotiable</p>
                  ) : null}
                </>
              ) : (
                <>
                  {buy.adsInfo.price.amount === 0 ? (
                    <p>Free</p>
                  ) : (
                    <>
                      <p>
                        {getSymbolFromCurrency(buy.adsInfo.price.currency)}
                        {buy.adsInfo.price.amount}
                      </p>
                      {buy.adsInfo.price.negotiable ? (
                        <p>Negotiable</p>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </div>
          )
        }
      />
      // </Link>
    ))}
  </Carousel>
)} 

            
         

       {/* Local Biz & Services Data */}
{localBizData && localBizData.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">Local Biz & Services</div>
    <div className="view-btn">
      {/* <i onClick={handleRedirectLocalBiz}>
        <button type="button" className="btn btn-view">
          View All
        </button>
      </i> */}
    </div>
  </div>
)}

{localBizData && localBizData.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {localBizData.map((biz, index) => (
    
      <LocalBizcard
        key={index}
        _id={biz._id}
        shareTitle ={"localBizDetails"}
        title={biz.adsInfo.title}
        location={biz.adsInfo.location.location_name}
        myFavourite={biz.is_favorite}
        featured={bizFeaturedStatus[index] ? true : false} 
        imageSrc={biz.adsInfo.image && biz.adsInfo.image.map(img => img.url)} 
        localbtn="Local"
        dateType={getTimeSinceCreation(biz.active_on_virtual, biz.location_timezone)}
        eventLabel={
          biz.addons_validity.some(item => item.name === "Urgent")
            ? "Urgent"
              : ""
        }   
      />

    ))}
  </Carousel>
)}




                  </>
                    )}


    




    </div>
  );
}

export default Search_query;
