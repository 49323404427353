import React, { useEffect, useState } from "react";
// import Footer_menu from "../../component/footer_menu";
import Footer_menu from "../../component/footer_menu";
import Header from "../../component/header/Header";
import BillStyle from "./Billing.module.css";
import { useNavigate } from "react-router-dom"
import getFetchConfig from '../../utils/getFetchConfig';
import handleFetchErrors from '../../utils/handleFetchErrors';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import visa from '../../assest/images/visa 1.png'
import { error } from "jquery";
import appURLs from "../../appURL";


const fetchConfig = getFetchConfig();


export default  function Billing_info(){

    const appURL = appURLs();
    const navigate = useNavigate();
   const [loginSource, updateLoginSource] = useState(false);

   const [billingInfo, setBillingInfo] = useState([]);

    useEffect(() => {

        var x = localStorage.getItem("token");
    
        if (!x) navigate("/")
    
        const source = localStorage.getItem(`source`);
        if (source) updateLoginSource(true)
        else updateLoginSource(false)
      }, [])


    // const fetchBillingInfo = async () => {
    //     try {
    //       const response = await fetch("https://api.menehariya.com/v1/api/posts/payment/billing-information");
    //       const data = await response.json();
    //       setBillingInfo(data);
    //       console.log(data,"THis is my data");
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   };
    

      const fetchBillingInfo = () => {

        fetch(`${appURL}/posts/payment/billing-information`, {
          ...fetchConfig,
          method: `GET`,
          
        })
          .then(handleFetchErrors)
          .then((sellerAccountJOSNReponse) => {

            setBillingInfo(sellerAccountJOSNReponse.paymentMethods.data)
            console.log(sellerAccountJOSNReponse.paymentMethods.data, "jhdfbndmcnv")
            console.log(sellerAccountJOSNReponse.paymentMethods.data[0].card.brand, "66666666")
          })
          .catch((error) => {
            console.log(error); 
          });
      };



      const deleteCard = (cardId) => {
        fetch(`${appURL}/posts/payment/detach-card`, {
          ...fetchConfig,
          method: "POST",
          body: JSON.stringify({ cardId }), 
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then((response) => {
            if (response.ok) {
              setBillingInfo((prevBillingInfo) =>
                prevBillingInfo.filter((method) => method.id !== cardId)
              );
            } else {
              console.log("Error deleting card");
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };
      
    const backToHome =()=>{
        window.location.href = "/";
    }

      useEffect(() => {
        fetchBillingInfo();
        deleteCard();
      }, []);


    return(
        <>

        
    <div className="billing-wrapper-sec" onClick={backToHome}>
    <i class="fa-solid fa-arrow-left"></i>
    </div>
        <div className={BillStyle.Billing}>
       
            <div className={BillStyle.mainContent}> 
            <h3>Billing Information</h3>

            <p>CARDS</p>
            </div>



            {/* <div className={BillStyle.BillBox}>

                <div className={BillStyle.BilLeft}>

                    <div className={BillStyle.BillImage}>
                    <img src="" alt="Visa Image" />
                    </div>

                    <div className={BillStyle.BillContent}>
                    <h3>VISA</h3>
                    <p>...4162</p>
                    </div>


                </div>
                
                <div className={BillStyle.BillRemove}>
                <h4>Remove</h4>
                </div>
            


                
            </div> */}


{billingInfo && billingInfo.length > 0 ? (
  billingInfo.filter((method) => method && method.card).map((method) => (
    <div key={method.id} className={BillStyle.BillBox}>
      <div className={BillStyle.BilLeft}>
        <div className={BillStyle.BillImage}>
          <img src={visa} alt="Visa Image" />
        </div>
        <div className={BillStyle.BillContent}>
          <h3 className={BillStyle.cardName}>
            {method.card.brand}
          </h3>
          <p className={BillStyle.cardName1}>
            ...{method.card.last4}
          </p>
        </div>
      </div>
      <div className={BillStyle.BillRemove}>
        <h4 onClick={() => deleteCard(method.id)}>Remove</h4>
      </div>
    </div>
  ))
) : (
  <h4 className={BillStyle.ErrorMessage}>No data available</h4>
)}








            <div className=" footer">
        <div className="footer-wraper">
          <img src="/images/logo_final.png" alt="logo" />
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc viverra efficitur convallis. Mauris non hendrerit nulla.</p>
        </div>
        <Footer_menu title="QUICK LINKS" text1="Babysitters and Nannies" text2="Room/Houses for Rent" text3="Jobs" text4="Events" />
        <Footer_menu title="MENEHARIYA" text1="Contact Us" text2="Blogs" text3="Term & Condition" text4="Privacy Policy" />
        <Footer_menu title="TRENDING SEARCH" text1="Lorem Ipsum" text2="Lorem Ipsum" text3="Lorem Ipsum" text4="Lorem Ipsum" />
        <div className="media-heading">
          <h6>SOCIAL MEDIA</h6>
          <p className='footer_icon'><svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.75" cy="18.75" r="18.75" fill="#424242" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6667 14.3333V11.8529C20.6667 10.7331 20.924 10.1667 22.7318 10.1667H25V6H21.2151C16.5771 6 15.0469 8.04427 15.0469 11.5534V14.3333H12V18.5H15.0469V31H20.6667V18.5H24.4854L25 14.3333H20.6667V14.3333Z" fill="white" />
          </svg>

            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18.75" cy="18.75" r="18.75" fill="#424242" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M29 12.8958C28.2626 13.2167 27.4743 13.4333 26.6436 13.5333C27.4912 13.0333 28.1439 12.2417 28.449 11.3C27.6565 11.7625 26.7792 12.1 25.8426 12.2792C25.0924 11.4917 24.0244 11 22.8462 11C20.5787 11 18.7436 12.8083 18.7436 15.0375C18.7436 15.3542 18.7775 15.6625 18.8495 15.9583C15.4378 15.7917 12.4117 14.1833 10.3901 11.7375C10.0384 12.3333 9.83492 13.0292 9.83492 13.7667C9.83492 15.1667 10.5639 16.4042 11.6658 17.1292C10.9877 17.1125 10.352 16.9292 9.80102 16.625C9.80102 16.6417 9.80102 16.6583 9.80102 16.675C9.80102 18.6333 11.2166 20.2625 13.0941 20.6333C12.7508 20.725 12.3863 20.775 12.0134 20.775C11.7506 20.775 11.4921 20.75 11.242 20.7C11.7633 22.3042 13.2806 23.4708 15.0776 23.5042C13.6747 24.5875 11.9032 25.2333 9.97902 25.2333C9.64844 25.2333 9.3221 25.2125 9 25.175C10.8097 26.3333 12.9669 27 15.281 27C22.8377 27 26.9657 20.8458 26.9657 15.5083C26.9657 15.3333 26.9614 15.1583 26.953 14.9875C27.754 14.4167 28.449 13.7083 29 12.8958V12.8958Z" fill="white" />
            </svg>

            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="19.25" cy="18.75" r="18.75" fill="#424242" />
              <circle cx="19.25" cy="19" r="3.5" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1274 15.338C17.034 14.4314 18.2393 13.9268 19.5214 13.9268C20.8035 13.9268 22.0089 14.4341 22.9155 15.3407C23.4907 15.9159 23.9017 16.6071 24.1254 17.3786H27.7286V12.5357C27.7286 11.3996 26.8504 10.5214 25.7143 10.5214H13.3714C12.2353 10.5214 11.2714 11.3996 11.2714 12.5357V17.3786H14.9174C15.1412 16.6071 15.5521 15.9132 16.1274 15.338ZM26.3572 14.0868C26.3572 14.3898 26.1116 14.6354 25.8086 14.6354H24.1629C23.8599 14.6354 23.6143 14.3898 23.6143 14.0868V12.4411C23.6143 12.1381 23.8599 11.8925 24.1629 11.8925H25.8086C26.1116 11.8925 26.3572 12.1381 26.3572 12.4411V14.0868Z" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9155 22.1262C22.0089 23.0328 20.8035 23.5268 19.5214 23.5268C18.2393 23.5268 17.034 23.0355 16.1274 22.1289C15.2224 21.224 14.7234 19.9929 14.7215 18.75H11.2714V24.8786C11.2714 26.0147 12.2353 26.9786 13.3714 26.9786H25.7143C26.8504 26.9786 27.7286 26.0147 27.7286 24.8786V18.75H24.3213C24.3195 19.9929 23.8205 21.2213 22.9155 22.1262V22.1262Z" fill="white" />
            </svg>

          </p>
          <div className='footer_language'>
            <select className="selectpicker" data-width="fit">
              <option data-content='<span className="flag-icon flag-icon-us"></span> English'>English
              </option>
              <option data-content='<span className="flag-icon flag-icon-mx"></span> Español'>Español
              </option>
            </select>
          </div>
        </div>
      </div>

        </div>
        </>
    )
}