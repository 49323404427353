import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import CommonModalWrapper from '../common-modal-Wrapper/CommonModalWrapper';
import CloseIcon from '../Icons/CloseIcon';
import VerifyOTP from '../verify-otp-card/VerifyOTPCard';
import LoaderButton from '../buttons/loader-button/LoaderButton';
import { isTruthyString, isValidEmailAddress, isValidIndianMobileNumber } from '../../utils/verification';
import appURLs from '../../appURL';

import style from './verifyOTPCard.module.css';

export default function VerifyOTPCard({ isEmailOTP = true, isMobileOTP = false, updateModalVisibilityForRegister, onSubmitHandler = null, reGenerateOTPForEmail = null, reGenerateOTPForPhoneNumber = null, skipRequired=false }) {

    const [isMobileOtpSkiped, updatedIsMobileOtpSkiped] = useState(true);

    const navigate = useNavigate();
    const fetchConfig = getFetchConfig();

    const appURL = appURLs();

    const [showLoader, toggleLoader] = useState(false)

    const [emailOTP, setEmailOTP] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" });
    const [isSkip, updateIsSkip] = useState(true);
    const [mobileOTP, setMobileOTP] = useState({ otp1: "", otp2: "", otp3: "", otp4: "" });

    const [isValidEmailOTP, updateIsValidEmailOTP] = useState({
        value: false,
        message: `Please enter valid OTP`
    })

    const [isValidPhoneOTP, updateIsValidPhoneOTP] = useState({
        value: false,
        message: `Please enter valid OTP`
    })

    const handleChange = (otpType, otpValue, event) => {
        
        const disbaledKeys= ["Shift"," ","Escape","Tab","CapsLock", "Enter", "Control", "Alt", "Meta","ArrowLeft","ArrowRight","ArrowUp","ArrowLeft","`\`","]","}","q","w","e","r","t","y","u","i","o","p","a","s","d","f","g","h","j","k","l","z","x","c","v","b","n","m","<",">","/",".",",","?","!","@","#","$","%","^","&","*","(",")","_","-","+","="]

        if(disbaledKeys.includes(event.target.value)) return
        if (otpType === 'email') {
            setEmailOTP({ ...emailOTP, [otpValue]: event.target.value });
        } else if (otpType === 'mobile') {
            setMobileOTP({ ...mobileOTP, [otpValue]: event.target.value });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        updateIsValidEmailOTP({ value: false })
        updateIsValidPhoneOTP({ value: false })


        let emailOTPStr = ``,
            mobileOTPStr = ``;

        if (isEmailOTP) {
            if (!(emailOTP.otp1 && emailOTP.otp2 && emailOTP.otp3 && emailOTP.otp4)) {
                updateIsValidEmailOTP({
                    value: true,
                    message: `Please enter all otp`
                })
            }
            console.log(emailOTP.otp1)
            emailOTPStr = `${emailOTP.otp1}${emailOTP.otp2}${emailOTP.otp3}${emailOTP.otp4}`

        }


        if (isMobileOTP) {
            if (!(mobileOTP.otp1 && mobileOTP.otp2 && mobileOTP.otp3 && mobileOTP.otp4)) {
                updateIsValidPhoneOTP({
                    value: true,
                    message: `Please enter all otp`
                })
            }

            mobileOTPStr = `${mobileOTP.otp1}${mobileOTP.otp2}${mobileOTP.otp3}${mobileOTP.otp4}`

        }

        const otp = {
            otp_for_email: emailOTPStr,
        };
   
        if (isMobileOTP && isSkip) {
            console.log(`hsfdhgfahds`)
            otp.otp_for_mobile_number = mobileOTPStr;
            if (mobileOTPStr.length < 4) return
        }

        if(!isMobileOTP){
            if (emailOTPStr.length < 4) return
        }

        if (onSubmitHandler) {
            onSubmitHandler(event, otp)
        } else {

            toggleLoader(true)
            fetch(`${appURL}/verify-otp`, {
                ...fetchConfig,
                body: JSON.stringify(otp)
            })
                .then(handleFetchErrors)
                .then((res) => {
              
                    toggleLoader(false)

                    if (Number(res.status) === Number(200) && Number(res.invalidOTP) === 0) {
                        updateModalVisibilityForRegister(false)

                        const tempToken = localStorage.getItem(`temtoken`);

                        if (tempToken) {
                            localStorage.setItem(`token`, tempToken);
                            localStorage.removeItem("temtoken");
                        }

                        window.location.reload(false);

                    } else if (Number(res.status) === Number(200) && Number(res.invalidOTP) === 1) {
                        console.log(`hwbdjwd`)
                        return updateIsValidEmailOTP({
                            value: true,
                            message: "invalid OTP"
                        })
                    } else if (Number(res.status) === Number(200) && Number(res.invalidOTP) === 2) {
                      return   updateIsValidPhoneOTP({
                            value: true,
                            message: "invalid OTP"
                        })
                    }else if (Number(res.status) === Number(200) && Number(res.invalidOTP) === 3) {
                         updateIsValidPhoneOTP({
                            value: true,
                            message: "invalid OTP"
                        })
                        updateIsValidEmailOTP({
                            value: true,
                            message: "invalid OTP"
                        })
                        return
                    } else if (Number(res.status) === Number(400) || Number(res.status) === Number(403)) {
                        toast.error(res?.message);
                    }

                })
                .catch(standardFetchHandlers.error)
                .finally(() => {
                    setTimeout(() => {
                        toggleLoader(false);
                    }, 1000);
                });
        }



    };

    const inputfocus = (otpType, elmnt) => {
       
          const disbaledKeys= ["Shift"," ","Escape","Tab","tab","CapsLock", "Enter", "Control", "Alt", "Meta","ArrowLeft","ArrowRight","ArrowUp","ArrowLeft","`\`","]","}","q","w","e","r","t","y","u","i","o","p","a","s","d","f","g","h","j","k","l","z","x","c","v","b","n","m","<",">","/",".",",","?","!","@","#","$","%","^","&","*","(",")","_","-","+","="]

          if(disbaledKeys.includes(elmnt.key)) return;

          console.log(elmnt.key)
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt?.target?.tabIndex - 2;
            if (next > -1) {
                elmnt?.target?.form?.elements[next].focus()
            }
        } else {
            const next = elmnt?.target?.tabIndex;
            if (next < 8) {
                console.log(elmnt?.target?.form.elements[next])
                elmnt?.target?.form?.elements[next].focus()
            }
        }
    };

    // const handleSkipMobileNumberOTP = () => {
    //     updatedIsMobileOtpSkiped(true)

    // }

    const handleSkipCheck = (value) => {
        console.log(value)
        updateIsSkip(value)

    }

    // console.log(isMobileOtpSkiped)

    return (

        <form id={style.otpForm} onSubmit={handleSubmit}>

            {
                isEmailOTP ?
                    <section className={style.emailOTPInputSection}>
                        <h4> Enter email OTP</h4>
                        <div className={style.otpContainer}>
                            <input
                                name="email-otp-1"
                                type="text"
                                autoComplete="off"
                                className={style.otpInput}
                                value={emailOTP.otp1}
                                onChange={(e) => handleChange('email', 'otp1', e)}
                                tabIndex="1"
                                maxLength="1"
                                onKeyUp={(e) => inputfocus('email', e)}
                            />
                            <input
                                name="email-otp-2"
                                type="text"
                                autoComplete="off"
                                className={style.otpInput}
                                value={emailOTP.otp2}
                                onChange={(e) => handleChange('email', 'otp2', e)}
                                tabIndex="2"
                                maxLength="1"
                                onKeyUp={(e) => inputfocus('email', e)}
                            />
                            <input
                                name="email-otp-3"
                                type="text"
                                autoComplete="off"
                                className={style.otpInput}
                                value={emailOTP.otp3}
                                onChange={(e) => handleChange('email', 'otp3', e)}
                                tabIndex="3"
                                maxLength="1"
                                onKeyUp={(e) => inputfocus('email', e)}
                            />
                            <input
                                name="email-otp-4"
                                type="text"
                                autoComplete="off"
                                className={style.otpInput}
                                value={emailOTP.otp4}
                                onChange={(e) => handleChange('email', 'otp4', e)}
                                tabIndex="4"
                                maxLength="1"
                                onKeyUp={(e) => inputfocus('email', e)}
                            />
                        </div>



                        <div className={style.erroResendButtonSection}>
                            <span className={style.errorText}> {isValidEmailOTP.value ? isValidEmailOTP.message : ``} </span>
                            <span onClick={(e) => { reGenerateOTPForEmail(e, true) }} className={style.resendText}>Resend OTP</span>
                        </div>
                    </section>
                    :
                    ``
            }


            {
                isMobileOTP ? <section className={style.mobileOTPInputSection}>

                    <div className={style.heading_skip_btn} >
                        <h4>Enter phone OTP</h4>

                        {
                            skipRequired ?
                            <div className={style.skip_text_section} style={{display:"flex", alignItems: "center"}}>

                            {
                                isSkip ?
                                <svg  onClick={()=>handleSkipCheck(false)} xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="#f5a623" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>
                              
                                :
                                <svg onClick={()=>handleSkipCheck(true)}  xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="#f5a623" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                               
                            }
                       
                            <p>skip</p>
                        </div>
:``
                        }

                      

                    </div>

                    {
                        isSkip ?
                            <>
                                <div className={style.otpContainer}>
                                    <input
                                        name="mobile-otp-1"
                                        type="text"
                                        autoComplete="off"
                                        className={style.otpInput}
                                        value={mobileOTP.otp1}
                                        onChange={(e) => handleChange('mobile', 'otp1', e)}
                                        tabIndex="5"
                                        maxLength="1"
                                        onKeyUp={(e) => inputfocus('mobile', e)}
                                    />

                                    <input
                                        name="mobile-otp-2"
                                        type="text"
                                        autoComplete="off"
                                        className={style.otpInput}
                                        value={mobileOTP.otp2}
                                        onChange={(e) => handleChange('mobile', 'otp2', e)}
                                        tabIndex="6"
                                        maxLength="1"
                                        onKeyUp={(e) => inputfocus('mobile', e)}
                                    />

                                    <input
                                        name="mobile-otp-3"
                                        type="text"
                                        autoComplete="off"
                                        className={style.otpInput}
                                        value={mobileOTP.otp3}
                                        onChange={(e) => handleChange('mobile', 'otp3', e)}
                                        tabIndex="7"
                                        maxLength="1"
                                        onKeyUp={(e) => inputfocus('mobile', e)}
                                    />

                                    <input
                                        name="mobile-otp-4"
                                        type="text"
                                        autoComplete="off"
                                        className={style.otpInput}
                                        value={mobileOTP.otp4}
                                        onChange={(e) => handleChange('mobile', 'otp4', e)}
                                        tabIndex="8"
                                        maxLength="1"
                                        onKeyUp={(e) => inputfocus('mobile', e)}
                                    />
                                </div >
                                <div className={style.erroResendButtonSection}>
                                    <span className={style.errorText}> {isValidPhoneOTP.value ? isValidPhoneOTP.message : ``}</span>
                                    <span onClick={(e) => { reGenerateOTPForPhoneNumber(e, true) }} className={style.resendText}>Resend OTP</span>
                                </div>
                            </> : ``
                    }

                </section> : ``
            }
            {
                showLoader ?
                    <LoaderButton />
                    :
                    <button className={`${style.submitBtn} btn1`} type='submit'>submit</button>
            }




        </form>
    )

}