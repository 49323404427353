import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// import 'dayjs/plugin/customParseFormat';

import CommonModalWrapper from '../../common-modal-Wrapper/CommonModalWrapper';
import CloseIcon from '../../Icons/CloseIcon';
import AddonExampleCard from '../addon-example-card/AddonExampleCard';
import { isValidURL } from '../../../utils/verification';
import getFetchConfig from '../../../utils/getFetchConfig';
import handleFetchErrors from '../../../utils/handleFetchErrors';
import standardFetchHandlers from '../../../utils/standardFetchHandlers';
import LoaderScreen from '../loader-screen/LoaderScreen';
import FormButton from '../../buttons/form-button/FormButton';
import CheckoutButton from '../../buttons/checkout-button/CheckoutButton';
import { getCurrencySymbol } from '../../../utils/getCurrencySymbol';
import getSymbolFromCurrency from 'currency-symbol-map';
import CongratulationsScreen from '../congratulations-screen/CongratulationsScreen';

import Inputs from '../../Inputs/Inputs';
import appURLs from '../../../appURL';

import style from './adsPlans.module.css';


const  formatDateFun= (dateString) =>{
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();
  
    const formattedDate = `${month} ${day} ${year}`;
  
    return formattedDate;
  }

function addDaysToCurrentDate(days) {
    const currentDate = new Date();
    const newDate = new Date(currentDate.setDate(currentDate.getDate() + days));

    return formatDateFun(newDate);
}



export default function AdsPlans({ adsCategoriesId = "", adsId = "", updateSteps, steps,myCurrency, adsIds, is_featured_true, is_homePageGallery, is_bump_up, is_uregnt, is_link_webiste, website_urls, is_upcoming_event, setCongratsClose, congratsClose, showAdsPlan }) {



console.log(is_upcoming_event, "is_upcoming_event123");

    const fetchConfig = getFetchConfig(),
        appURL = appURLs();

    const [planData, updatePlanData] = useState([]),
        [addOnLists, updateAddOnLists] = useState([]),
        [isLinkYourWebsite, updateIsLinkYourWebsite] = useState(false),
        [homepagesgallery, sethOMEPAGESgallery] = useState(false),
        [urgento, setUrgento] = useState(false),
        [bumping_up, setBumping_up] = useState(false),
        [featureing, setFeaturing] = useState(false),
        [is_webiste_linking, setIs_websiteLinking] = useState(false),
        [is_new_events, setIs_new_events] = useState(false),
        [website, updateWebsite] = useState(""),
        [priceValid, setPriceValid] = useState(false),
        [isButtonDisabled, setIsButtonDisabled] = useState(false),
        [priceError, setPriceError] = useState(''),
        [isLinkYourPrice, updateIsLinkYourPrice] = useState(false),
        [prices, updatePrices] = useState(""),
        [isAllAddonSelected, toggleIsAllAddonSelected] = useState(false),
        [selectedExampleData, updateSelectedExampleData] = useState({}),
        [isModalOpenForExample, toggleExampleModal] = useState(false),
        [selectedAddOn, updateSelectedAddOn] = useState([]),
        [showLoader, toggleShowLoader] = useState(false),     
        [selecetdPlan, updateSelecetdPlan] = useState({});

        const [showCongratulations, setShowCongratulations] = useState(false);


       


        const dateFormat = 'DD/MM/YYYY'; // Adjust the date format according to the format stored in local storage

        const startDate = localStorage.getItem('start_date');
        const endDate = localStorage.getItem('end_date');
     

        
        
        
       
        const start = dayjs(startDate, dateFormat).toDate();
        const end = dayjs(endDate, dateFormat).toDate();
        const dateDiff = Math.floor((end - start) / (1000 * 60 * 60 * 24)); 
        
        let expirationDate;
        
            // if (dateDiff > 30) {
            // const expirationMonth = (start.getMonth() + 2) % 12;
            // const expirationYear = start.getFullYear() + Math.floor((start.getMonth() + 2) / 12); 
            // expirationDate = new Date(expirationYear, expirationMonth, start.getDate()).toLocaleDateString(undefined, {
            //     day: 'numeric',
            //     month: 'long',
            //     year: 'numeric'
            // });
            // }

            if (dateDiff > 30) {
                expirationDate = end.toLocaleDateString(undefined, {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                });
              }
            else {
            const expirationDay = end.getDate() + 1;
            const expirationMonth = end.getMonth() + 1;
            const expirationYear = end.getFullYear();
            expirationDate = new Date(expirationYear, expirationMonth - 1, expirationDay).toLocaleDateString(undefined, {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
            });            
        }





    const totalAmount = useMemo(() => {


        const totalAddonPrice = selectedAddOn.reduce((sum, addon) => sum + addon.addonPrice, 0);
        const total = totalAddonPrice + Number(selecetdPlan?.price?.amount) || 0;
        return parseFloat(total.toFixed(2));

    }, [selectedAddOn]);


    const handlenew = () => {

        
        toast.dismiss();
        if (isLinkYourWebsite && !website) {
          return toast.error("Please provide website link");
        } else if (isLinkYourWebsite && website && !isValidURL(website)) {
          return toast.error("Please provide a valid website link");
        } else if (isLinkYourPrice && !prices) {
          return toast.error("Please provide a price");
        }
        
      
        let addonIdArr = [];
      
        for (let i = 0; i < selectedAddOn.length; i++) {
          addonIdArr.push(selectedAddOn[i].priceId);
        }
      
        fetch(`${appURL}/posts/payment/create-payment-intent`, {
          ...updatedFetchConfig,
          body: JSON.stringify({
            postId: adsId || adsIds,
            planId: selecetdPlan._id,
            add_ons: addonIdArr,
            website_url: website || "",
            price_drop: 0 || "",
            redirect_uri_success: "http://localhost:3005/eventsAds",
            // redirect_uri_success: "https://menehariya.netscapelabs.com/success",
          }),
        })
          .then(handleFetchErrors)
          .then((res) => {
            console.log(res);
            if(addonIdArr.length === 0){
                console.log("Checker 101010");
                setShowCongratulations(true);
                setCongratsClose(true);
              
            }
            if (res.paymentIntent) {
              window.location.href = res.paymentIntent;
            } else {
              updateSteps(steps + 1);
            }
          })
          .catch((err) => {
           console.log(err);
          });
      };

    function calculateDuration(days) {
        const daysInMonth = 30;
        const daysInWeek = 7;
        const months = Math.floor(days / daysInMonth);
        const remainingDays = days % daysInMonth;
        const weeks = Math.floor(remainingDays / daysInWeek);
        const remainingDaysInWeek = remainingDays % daysInWeek;
      
        if (months !== 0 && weeks !== 0 && remainingDaysInWeek !== 0) {
          return `${months * daysInMonth + weeks * daysInWeek + remainingDaysInWeek} days`;
        } else if (months !== 0 && weeks !== 0) {
          return `${months * daysInMonth + weeks * daysInWeek} days`;

        } else if (months !== 0) {
          const monthValue = months === 1 ? "month" : "months";
          return `${months} ${monthValue}`;
        } else if (weeks !== 0 && remainingDaysInWeek !== 0) {
          return `${weeks * daysInWeek + remainingDaysInWeek} days`;
        } else if (weeks !== 0) {
          const weeksValue = weeks === 1 ? "week" : "weeks";
          return `${weeks} ${weeksValue}`;
        } else {
          return `${remainingDaysInWeek} days`;
        }
      }

    const planRadioBtnOption = useMemo(() => {
        let planRadioBtnOptionArr = [];

        for (let i = 0; i < planData.length; i++) {

            const planDataObj = planData[i];

            planRadioBtnOptionArr.push({
                label: calculateDuration(planDataObj?.duration),
                value: String(planDataObj?._id),
            })

        }
        return planRadioBtnOptionArr

    }, [planData]);

    // const adsPrice = localStorage.getItem('myItem');
    const myPrice = localStorage.getItem('isCheckedNegotiablePrice')
    const myPrice2 = localStorage.getItem('priceInputValue')

    // fetching ads categories
    const fetchPlanData = () => {
        toggleShowLoader(true)
        fetch(`${appURL}/posts/ads/fetch_plan?ads_type=${adsCategoriesId}`, {
            ...fetchConfig,
            method: "GET",
        })
            .then(handleFetchErrors)
            .then((res) => {
                console.log(res)
                toggleShowLoader(false)

                // if (res.status === 200) {
                //     const adsPlansData = res?.data;
                //     if (adsPlansData.length) {
                //         updatePlanData(adsPlansData);
                //         updateSelecetdPlan(adsPlansData[0])
                //         updateAddOnLists(adsPlansData[0]?.add_ons)
                //         //const adsPrice = localStorage.getItem('myItem');
                //     }

                // } 
                if (res.status === 200) {
                    const adsPlansData = res?.data;
                    // debugger
                    if (adsPlansData.length) {
                        updatePlanData(adsPlansData);
                        updateSelecetdPlan(adsPlansData[0]);
                        
                        if ( !adsPrice) {
                            
                            const filteredAddOns = adsPlansData[0]?.add_ons.filter(addon => addon.name !== "Price Drop");
                            updateAddOnLists(filteredAddOns);
                        } 
                        else if(adsPrice) {
                            updateAddOnLists(adsPlansData[0]?.add_ons);
                        }
                    }
                }
                else {
                    return toast.error(`something went wrong`);
                }

            })
            .catch((err) => {
                toggleShowLoader(false)
                console.log(err)
            });
    }
    useEffect(() => {
        
        if(selectedAddOn?.length === selecetdPlan?.add_ons?.length) toggleIsAllAddonSelected(true)
        else toggleIsAllAddonSelected(false)

    }, [selectedAddOn])
    


    useEffect(() => {
        fetchPlanData();
    }, [adsCategoriesId]);



    const planChangeHanlder = (e) => {

        const planId = e?.target?.value;

        const foundPlan = planData.find(plan => plan._id === planId);
        if (foundPlan && Object.keys(foundPlan).length) {
            updateSelecetdPlan(foundPlan);
            updateAddOnLists(foundPlan.add_ons);
            updateSelectedAddOn([]);
            toggleIsAllAddonSelected(false);
        }
    }


    if (showLoader) {
        return <LoaderScreen />
    }

    //selecting addons

    // const handleSelectAddons = (addonName, addonId, addonPrice, addonCurrency) => {

    //     const isAlreadyExistSelectedAddon = selectedAddOn.some((addon) => addon.addonName === addonName && addon.addonId === addonId);

    //     if (isAlreadyExistSelectedAddon) {
    //         const filteredSlectedAddon = selectedAddOn.filter((addon) => addon.addonName !== addonName && addon.addonId !== addonId);
    //         updateSelectedAddOn([...filteredSlectedAddon])
    //     } else {

    //         const updatedSelectedAddOn1 = [...selectedAddOn];

    //         const existingAddonIndex = updatedSelectedAddOn1.findIndex(
    //             (addon) => addon.addonName === addonName
    //         );

    //         if (existingAddonIndex !== -1) {

    //             updatedSelectedAddOn1[existingAddonIndex].addonId = addonId;
    //             updatedSelectedAddOn1[existingAddonIndex].addonName = addonName;
    //             updatedSelectedAddOn1[existingAddonIndex].addonPrice = addonPrice;
    //             updatedSelectedAddOn1[existingAddonIndex].addonCurrency = addonCurrency;
    //             updateSelectedAddOn(updatedSelectedAddOn1);

    //         } else {

    //             updateSelectedAddOn([
    //                 ...selectedAddOn,
    //                 {
    //                     addonName: addonName,
    //                     addonId: addonId,
    //                     addonPrice: addonPrice,
    //                     addonCurrency: addonCurrency
    //                 }]);
    //         }
    //     }


    // }





    // jdhfuwqbsdabhjsbDHADVHSZDVGASVGAWDVSG

    // const handleSelectAddon = (addonId, addonTtile) => {

    //     const isAlreadyExistSelectedAddon = selectedAddOn.some((addon) => addon.addonId === addonId);

    //     const foundAddon = selecetdPlan?.add_ons.find((addon) => addon._id == addonId);

    //     if (isAlreadyExistSelectedAddon) {
    //         if(addonTtile == "Link to your website") {
    //             updateIsLinkYourWebsite(false);
    //             updateWebsite("");
    //         }
    //         const filteredSlectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
    //         updateSelectedAddOn([...filteredSlectedAddon])
    //     } else {

    //         if (foundAddon && Object.keys(foundAddon).length) {
    //             if(addonTtile == "Link to your website") updateIsLinkYourWebsite(true)
    //             updateSelectedAddOn([
    //                 ...selectedAddOn,
    //                 {
    //                     addonName: foundAddon?.name,
    //                     addonId: addonId,
    //                     priceId: foundAddon?.price[0]?._id,
    //                     addonPrice: foundAddon?.price[0]?.amount,
    //                     addonCurrency: foundAddon?.price[0]?.currency
    //                 }]);
    //         }
    //     }
    // }

    
    const handleSelectAddon = (addonId, addonTitle) => {
        const isAlreadyExistSelectedAddon = selectedAddOn.some((addon) => addon.addonId === addonId);
      
        const foundAddon = selecetdPlan?.add_ons.find((addon) => addon._id == addonId);
      
        if (isAlreadyExistSelectedAddon) {
          if (addonTitle === "Link to your website") {
            updateIsLinkYourWebsite(false);
            updateWebsite("");
            setIs_websiteLinking(false)
            
          } if (addonTitle === "Price Drop") {
            updateIsLinkYourPrice(false);
            updatePrices("");
            setIsButtonDisabled(false)
          }

          if (addonTitle === "Homepage Gallery") {
            sethOMEPAGESgallery(false);
        }
          if (addonTitle === "Urgent") {
            setUrgento(false);
        }
          if (addonTitle === "Bump up") {
            setBumping_up(false);
        }
          if (addonTitle === "Featured") {
            setFeaturing(false);
        }
          if (addonTitle === "Upcoming Event") {
            setIs_new_events(false);
        }

          
          
          const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
          updateSelectedAddOn([...filteredSelectedAddon]);
        } 
        
        else if (is_homePageGallery && addonTitle === "Homepage Gallery") {
            sethOMEPAGESgallery(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }
        else if (is_uregnt && addonTitle === "Urgent") {
            setUrgento(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }
        else if (is_bump_up && addonTitle === "Bump up") {
            setBumping_up(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }
        else if (is_featured_true && addonTitle === "Featured") {
            setFeaturing(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }
        else if (is_link_webiste && addonTitle === "Link to your website") {
            setIs_websiteLinking(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }
        else if (is_upcoming_event && addonTitle === "Upcoming Event") {
            setIs_new_events(true);
            const filteredSelectedAddon = selectedAddOn.filter((addon) => addon.addonId !== addonId);
            updateSelectedAddOn([...filteredSelectedAddon]);
        }
        
        else {
          if (foundAddon && Object.keys(foundAddon).length) {
            if (addonTitle === "Link to your website") {
              updateIsLinkYourWebsite(true);
            }  if (addonTitle === "Price Drop") {
              updateIsLinkYourPrice(true);
            }
            // if(addonTitle === "Homepage Gallery"){
            //     sethOMEPAGESgallery(true)
            // } 
            updateSelectedAddOn([
              ...selectedAddOn,
              {
                addonName: foundAddon?.name,
                addonId: addonId,
                priceId: foundAddon?.price[0]?._id,
                addonPrice: foundAddon?.price[0]?.amount,
                addonCurrency: foundAddon?.price[0]?.currency,
              },
            ]);
          }
        }
      };
      


     const exp_date = localStorage.getItem('event_end_date');





    const handleChangePrice = (e, addonId) => {
        const selectedOptionData = e.target?.options[e.target.selectedIndex]?.dataset;

        console.log(`event`, e );
        const  dataset = e.dataset;
        console.log(`dataset`,selectedOptionData );
        const selectedPrice = e?.target?.value;

        const updatedSelectedAddOn1 = [...selectedAddOn];

        const existingAddonIndex = updatedSelectedAddOn1.findIndex(
            (addon) => addon.addonId === addonId
        );

        if (existingAddonIndex !== -1) {
        
            updatedSelectedAddOn1[existingAddonIndex].addonPrice = Number(selectedPrice);
            updatedSelectedAddOn1[existingAddonIndex].priceId = selectedOptionData?.priceId;

            updateSelectedAddOn(updatedSelectedAddOn1);

        }
    }



    const checkingSelectedPrice = (addonId) => {
        return selectedAddOn.some((addon) =>  addon.addonId === addonId)
    }

    const updatedFetchConfig = fetchConfig;
    updatedFetchConfig.headers['Content-Type'] = 'application/json';

    // const handle = () => {
    //     toast.dismiss();
    //     if(isLinkYourWebsite && !website) return toast.error("Please provide website link");
    //     else if( isLinkYourWebsite && website && !isValidURL(website))return toast.error("Please provide valid website link");
    //     let addonIdArr = [];
    //     for (let i = 0; i < selectedAddOn.length; i++) {
    //         addonIdArr.push(selectedAddOn[i].priceId);
    //     }   
    //     fetch(`${appURL}/posts/payment/create-payment-intent`, {
    //         ...updatedFetchConfig,
    //         body: JSON.stringify(
    //             {
    //                 "postId": adsId,
    //                 "planId": selecetdPlan._id,
    //                 "add_ons": addonIdArr,
    //                 website_url: website || "",
    //                 redirect_uri_success: "https://menehariya.netscapelabs.com/success"
    //             }
    //         )
    //     })
    //         .then(handleFetchErrors)
    //         .then((res) => {
    //             console.log(res)
    //             if (res.paymentIntent) {
    //                 window.location.href = res.paymentIntent
    //             } else {
    //                 updateSteps(steps + 1)
    //             }
    //         })
    //         .catch((err) => {
    //         });
    // }


    

    const handle = () => {

        
        toast.dismiss();
        if (isLinkYourWebsite && !website) {
          return toast.error("Please provide website link");
        } else if (isLinkYourWebsite && website && !isValidURL(website)) {
          return toast.error("Please provide a valid website link");
        } else if (isLinkYourPrice && !prices) {
          return toast.error("Please provide a price");
        }
        
      
        let addonIdArr = [];
      
        for (let i = 0; i < selectedAddOn.length; i++) {
          addonIdArr.push(selectedAddOn[i].priceId);
        }
      
        fetch(`${appURL}/posts/payment/create-payment-intent`, {
          ...updatedFetchConfig,
          body: JSON.stringify({
            postId: adsId || adsIds,
            planId: selecetdPlan._id,
            add_ons: addonIdArr,
            website_url: website || "",
            price_drop: prices || "",
            redirect_uri_success: "https://menehariya.netscapelabs.com/success",
          }),
        })
          .then(handleFetchErrors)
          .then((res) => {
            console.log(res);
            if(addonIdArr.length === 0){
                console.log("Checker 101010");
                setShowCongratulations(true);
                setCongratsClose(true);
              
            }
            if (res.paymentIntent) {
              window.location.href = res.paymentIntent;
            } else {
              updateSteps(steps + 1);
            }
          })
          .catch((err) => {
           console.log(err);
          });
      };



    const backBtnClickHandler = () => {
        if(!adsId){
            updateSteps(steps - 1)
        }
    }

    const handleExampleModal = (value, addonId) => {

        const foundAddonExample = selecetdPlan.add_ons.find((addon) => addon._id == addonId);
        if (foundAddonExample) {
            updateSelectedExampleData({
                title: foundAddonExample?.example_title,
                description: foundAddonExample?.example_description,
                image: foundAddonExample?.example_image
            })
            toggleExampleModal(value)
        } else {
            toggleExampleModal(value)
        }


    }

    // const handleSelectAll = (e) => {

    //     const isChecked = e.target.checked;

    //     if(isChecked){
    //         const selectedPlanAddon = selecetdPlan?.add_ons;

    //         let allSelectedAddonArr = []
    
    //         for (var i = selectedPlanAddon.length - 1; i >= 0; i--) {
    
    //             const addon =  selectedPlanAddon[i];
    //             const addonObj = {
    //                 addonName: addon?.name,
    //                 addonId: addon?._id,
    //                 priceId: addon?.price[0]?._id,
    //                 addonPrice: addon?.price[0]?.amount,
    //                 addonCurrency: addon?.price[0]?.currency
    //             }
    
    //             allSelectedAddonArr.push(addonObj)
    //         }
    //         updateIsLinkYourWebsite(true);
    //         updateSelectedAddOn(allSelectedAddonArr);

    //     } else {
    //         updateIsLinkYourWebsite(false);
    //         updateSelectedAddOn([]);

    //     }

    //     toggleIsAllAddonSelected(isChecked)
     
    // }


    // const handlePriceChange = (e) => {
    //     const enteredPrice = e.target.value;
    //     const adsPrice = localStorage.getItem('myItem');
        
    //     if (enteredPrice < adsPrice) {
    //       updatePrices(enteredPrice);
    //     } else {
    //       toast.error("Entered price should be less than the ads price");
    //     }
    //   };
    
    let adsPrice = localStorage.getItem('priceInputValue');
    // let priceCurrency = localStorage.getItem('currencyPrice');

    // const handlePriceChange = (e) => {
    //   const enteredPrice = Number(e.target.value);
    
    //   if (enteredPrice > adsPrice) {
    //     setIsButtonDisabled(true);
    //     toast.error('Enter a valid amount');
    //   } else if (enteredPrice > 1) {
    //     setPriceError('Your sales price will be displayed as');
    //   } else {
    //     setIsButtonDisabled(false);
    //     setPriceError('');
    //   }
    
    //   updatePrices(enteredPrice || null);
    // };

    const handlePriceChange = (e) => {
        const enteredPrice = Number(e.target.value);
      
        if (enteredPrice >= adsPrice) {
          setIsButtonDisabled(true);
          setPriceError("");
          toast.error('Enter a valid amount');
        } else if (enteredPrice >= 1) {
          setIsButtonDisabled(false); // Enable the button when the input is valid
          setPriceError('Your sales price will be displayed as');
        } else {
            setIsButtonDisabled(true); // Disable the button when the input is not valid
            setPriceError('');
        }
        updatePrices(enteredPrice || null);
      };
      

        const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
      
        if (isChecked) {
          const selectedPlanAddon = selecetdPlan?.add_ons;
      
          let allSelectedAddonArr = [];
      
          for (var i = selectedPlanAddon.length - 1; i >= 0; i--) {
            const addon = selectedPlanAddon[i];
            const addonObj = {
              addonName: addon?.name,
              addonId: addon?._id,
              priceId: addon?.price[0]?._id,
              addonPrice: addon?.price[0]?.amount,
              addonCurrency: addon?.price[0]?.currency,
            };
    
            allSelectedAddonArr.push(addonObj);
          }
      
          updateIsLinkYourWebsite(true);
        //   sethOMEPAGESgallery(true);
          updateIsLinkYourPrice(true);
          updateSelectedAddOn(allSelectedAddonArr);
        } else {
          updateIsLinkYourWebsite(false);
        //   sethOMEPAGESgallery(false);
          updateIsLinkYourPrice(false);
          updateSelectedAddOn([]);
        }
      
        toggleIsAllAddonSelected(isChecked);
      };

    function calculateDuration(days) {
        const daysInMonth = 30;
        const daysInWeek = 7;
        const months = Math.floor(days / daysInMonth);
        const remainingDays = days % daysInMonth;
        const weeks = Math.floor(remainingDays / daysInWeek);
        const remainingDaysInWeek = remainingDays % daysInWeek;
      
        if (months !== 0 && weeks !== 0 && remainingDaysInWeek !== 0) {
          return `${months * daysInMonth + weeks * daysInWeek + remainingDaysInWeek} days`;
        } else if (months !== 0 && weeks !== 0) {
          return `${months * daysInMonth + weeks * daysInWeek} days`;
        } else if (months !== 0) {
          const monthValue = months === 1 ? "month" : "months";
          return `${months} ${monthValue}`;
        } else if (weeks !== 0 && remainingDaysInWeek !== 0) {
          return `${weeks * daysInWeek + remainingDaysInWeek} days`;
        } else if (weeks !== 0) {
          const weeksValue = weeks === 1 ? "week" : "weeks";
          return `${weeks} ${weeksValue}`;
        } else {
          return `${remainingDaysInWeek} days`;
        }
      }

// debugger

    return (

        <>

            {/* <CommonModalWrapper isOpen={isModalOpenForExample } className={style.example_modal} >
                <CloseIcon onClick={() => handleExampleModal(false, null)} />
                <AddonExampleCard selectedExampleData={selectedExampleData} handleExampleModal={handleExampleModal} />
            </CommonModalWrapper> */}

            {showCongratulations &&
                <CongratulationsScreen adsIds={adsIds} />
                }

            <section id='span-2' className={style.ads_plan_wrapper}>

            {/* 
            <aside className={style.plan_detail_section_wrapper}>

                {
                    planData && planData.length ?
                        planData.map((plan, i) => {
                            return (

                                <div onClick={() => { planChangeHanlder(plan._id) }} key={i} className={style.plan_detail_section} >

                                    <section className={style.plan_amount_title_text_section}>

                                        <div className={style.plan_amount_title_text} >
                                            <h3>{plan?.price?.isfree ? `Free` : plan?.price?.amount}  {!plan?.price?.isfree && plan?.price?.currency}</h3>
                                            <p>{plan?.name}</p>
                                        </div>

                                        {
                                            plan._id === selecetdPlan._id ?
                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#FD7E14" />
                                                    <path d="M14.6673 6.5L8.25065 12.9167L5.33398 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#FD7E14" />
                                                </svg> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="#D0D5DD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>
                                        }

                                    </section>

                                    <p>
                                        {
                                            plan._id === selecetdPlan._id ?
                                                <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#FD7E14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg> :
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="#D0D5DD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>
                                        }

                                        <span className={style.duration_text}> Post For {plan.duration} Days</span>
                                    </p>
                                </div>
                            )

                        }) : ``
                }



            </aside> */}

                {/*
            <section className={style.addon_section_wrapper}>
                {
                    addOnLists.length ?
                        addOnLists.map((addOn) => {
                            return (
                                <section className={style.addon_section}>
                                    <span className={style.addon_name_text}>{addOn?.name}</span>
                                    <div className={style.addon_price_section_wrapper}>


                                        {
                                            addOn?.price?.length ?
                                                addOn?.price.map((price) => {
                                                    return (


                                                        <div onClick={() => { handleSelectAddons(addOn?.name, price?._id, price?.amount, price?.currency) }} id={checkingSelectedPrice(addOn?.name, price?._id) ? style.active_card : ``} className={style.addon_price_card_section}  >

                                                            <section className={style.addon_amount_title_text_section}>

                                                                <div className={style.plan_amount_title_text} >
                                                                    <h3> {!price?.isfree && getCurrencySymbol(price?.currency)}{price?.isfree ? `Free` : price?.amount}</h3>

                                                                </div>

                                                                {
                                                                    checkingSelectedPrice(addOn?.name, price?._id) ?
                                                                        <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="#FD7E14" />
                                                                            <path d="M14.6673 6.5L8.25065 12.9167L5.33398 10" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#FD7E14" />
                                                                        </svg>
                                                                        :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#D0D5DD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>
                                                                }

                                                            </section>

                                                            <p className={style.addon_price_duration_wrapper}>
                                                                {
                                                                    checkingSelectedPrice(addOn?.name, price?._id) ?
                                                                        <svg width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M6.5 11L9.5 14L15.5 8M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11Z" stroke="#FD7E14" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#D0D5DD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle></svg>
                                                                }

                                                                <span className={style.addon_price_duration_text}>{price.duration} days</span>
                                                            </p>
                                                        </div>
                                                    )
                                                })
                                                : ``

                                        }
                                    </div>
                                </section>
                            )

                        })
                        : ``
                }

            </section>


            <hr />

            <div className={style.price_calculation_section}>

                <section className={style.price_calculation_heading}>

                    <span>Price per Ad</span>

                    {
                        selecetdPlanFeaturedDetail?.selected ?
                            <span>Featured per Ad</span> : ``
                    }

                    {
                        selectedAddOn.map((addon) => {
                            return <span>{addon?.addonName}</span>
                        })
                    }

                    <span>Total</span>

                </section>

                <section className={style.price_calculation_amount}>

                    <span>${selecetdPlan?.price?.amount}</span>
                    {
                        selecetdPlanFeaturedDetail?.selected ?
                            <span>${selecetdPlan?.featured_price?.amount}</span> : ``
                    }

                    {
                        selectedAddOn.map((addon) => {
                            return <span>{!addon?.isfree && getCurrencySymbol(addon?.addonCurrency)}{addon?.addonPrice}</span>
                        })
                    }

                    <span>${totalAmount}</span>

                </section>

            </div> */}
            

            {startDate && endDate && (
                <div className={style.selected_plan}>
                <h5 style={{textAlign:'center',fontWeight:'bold'}}>Buy Promotions</h5>
                <p className={style.ads_expire_info_text}> This Ad will expire on {expirationDate}</p>
                </div>
            )
            }

{!startDate && !endDate && (
            <>
         
               {
                // planRadioBtnOption.length === 1  && selecetdPlan?.price?.isfree ? `` : <span className={style.select_duration_heading}>Select Duration</span>
            
               }
                        <h5 style={{textAlign:'center',fontWeight:'bold'}}>Buy Promotions</h5>
                <section id={planRadioBtnOption.length === 1  && selecetdPlan?.price?.isfree && !expirationDate && style.expire_text_section}  className={style.expire_text_section}>
                    {
                     planRadioBtnOption.length === 1  && selecetdPlan?.price?.isfree  ?
                     <span className={style.ads_expire_info_text}>This Ad will expire on {selecetdPlan?.duration ? addDaysToCurrentDate(selecetdPlan?.duration) : ``}</span> 
                    // <span className={style.ads_expire_info_text}> This Ad will expire on {expirationDate}</span>
                    : 
               <>
               {
                    planRadioBtnOption?.length &&
                    <Inputs
                        name='careType'
                        value={String(selecetdPlan?._id)}
                        onChangeHandler={planChangeHanlder}
                        type='radio'
                        id='span-2'
                        required={true}
                        radioBtnOtions={planRadioBtnOption}
                    />
                }

                <div className={style.price_section}>
                    <span className={style.plan_price_text} >{!selecetdPlan?.price?.isfree && getCurrencySymbol(selecetdPlan?.price?.currency)}{selecetdPlan?.price?.isfree ? `Free` : selecetdPlan?.price?.amount}</span>
                    {/* <h4 style={{textAlign:'center',fontWeight:'bold'}}>Buy Promotions</h4> */}
                    <p className={style.expire_text}>This Ad will expire on {selecetdPlan?.duration ? addDaysToCurrentDate(selecetdPlan?.duration) : ``}</p>
                    {/* <p className={style.expire_text}>{`This Ad will expire on ${exp_date}`}</p> */}
                </div>
               </>}
            </section>
            </>
            
            )
        }   

                <section className={style.addon_section_wrapper}>
                    <section className={style.sub_heading_and_selectall_wrapper}>
                        <h4>Make your ad stand out!</h4>
                        <div className={style.sub_heading_and_selectall_section}>
                            <span>Select an option to promote your ad</span>

                           <div className={style.select_all_label} >
                           <span>Select All</span>
                            <input checked={isAllAddonSelected} onChange={handleSelectAll} type="checkbox" id="selectaAll" name="selectaAll" />
                            <label htmlFor="selectaAll"></label>
                           </div>
                        </div>

                    </section>


                    {
                        addOnLists.length ?
                            addOnLists.map((addOn, j) => {

                                const isFeatured = addOn?.name === "Featured";
                                const isAddonSelected = selectedAddOn.some(selectOptionArr => selectOptionArr.addonId === addOn._id);
                                const isPriceValid = is_featured_true
                                return (

                                    <section key={j} className={style.plan_card_wrapper} id={checkingSelectedPrice(addOn?._id) ? style.active_card : ``}>
                                        <div className={style.check_plan_heading_wrapper}>
                                            <div className={style.check_box_wrapper}>



                                            {/* {addOn?.name === "Featured" ? (
                                            <input
                                            checked={isAddonSelected}
                                            onChange={() => { handleSelectAddon(addOn?._id, addOn?.name) }}
                                            type="checkbox"
                                            id={addOn?._id}
                                            name="selected_addon"
                                            value={addOn?._id}
                                            disabled={isPriceValid}
                                        />
                                         ) : (
                                            ""
                                            )}

                                            {addOn?.name === "Homepage Gallery" ? (
                                            <input
                                            checked={isAddonSelected}
                                            onChange={() => { handleSelectAddon(addOn?._id, addOn?.name) }}
                                            type="checkbox"
                                            id={addOn?._id}
                                            name="selected_addon"
                                            value={addOn?._id}
                                            disabled={is_homePageGallery}
                                        />
                                         ) : (
                                            ""
                                            )}

                                            {addOn?.name === "Bump up" ? (
                                            <input
                                            checked={isAddonSelected}
                                            onChange={() => { handleSelectAddon(addOn?._id, addOn?.name) }}
                                            type="checkbox"
                                            id={addOn?._id}
                                            name="selected_addon"
                                            value={addOn?._id}
                                            disabled={is_bump_up}
                                        />
                                         ) : (
                                            ""
                                            )}

                                            {addOn?.name === "Urgent" ? (
                                            <input
                                            checked={isAddonSelected}
                                            onChange={() => { handleSelectAddon(addOn?._id, addOn?.name) }}
                                            type="checkbox"
                                            id={addOn?._id}
                                            name="selected_addon"
                                            value={addOn?._id}
                                            disabled={is_uregnt}
                                        />
                                         ) : (
                                            ""
                                            )}

                                            {addOn?.name === "Link to your website" ? (
                                            <input
                                            checked={isAddonSelected}
                                            onChange={() => { handleSelectAddon(addOn?._id, addOn?.name) }}
                                            type="checkbox"
                                            id={addOn?._id}
                                            name="selected_addon"
                                            value={website_urls}
                                            disabled={is_link_webiste}
                                        />
                                         ) : (
                                            ""
                                        )}

                                            {addOn?.name === "Upcoming Event" ? (
                                            <input
                                            checked={isAddonSelected}
                                            onChange={() => { handleSelectAddon(addOn?._id, addOn?.name) }}
                                            type="checkbox"
                                            id={addOn?._id}
                                            name="selected_addon"
                                            value={is_upcoming_event}
                                            disabled={is_link_webiste}
                                        />
                                         ) : (
                                            ""
                                        )} */}

                            


                                                <input 
                                                // checked={selectedAddOn.some(selectOptionArr => selectOptionArr.addonId === addOn._id) } 
                                                checked={(is_homePageGallery && addOn.name === "Homepage Gallery") || (is_uregnt && addOn.name === "Urgent") || (is_bump_up && addOn.name === "Bump up") || (is_featured_true && addOn.name === "Featured") || (is_link_webiste && addOn.name === "Link to your website") || (is_upcoming_event && addOn.name === "Upcoming Event") || selectedAddOn.some(selectOptionArr => selectOptionArr.addonId === addOn._id)}
                                                onChange={() => { handleSelectAddon(addOn?._id, addOn?.name) }} 
                                                type="checkbox" 
                                                id={addOn?._id}
                                                 name="selected_addon" 
                                                 value={addOn?._id} />
                                                <label htmlFor={addOn?._id}></label>
                                                <span style={{background: `${addOn?.color_code}`}} className={style.plan_heading_text}>{addOn?.name}</span>
                                            </div>
                                            
                                            <p className={style.plan_description_text}>{addOn?.description} <span onClick={() => handleExampleModal(true, addOn?._id)} className={style.view_example_text}>View example</span></p>



                                            {addOn?.name === "Featured" ? (
                                                is_featured_true && <span style={{ color: 'red' }}>
                                                       This ad has expired on {is_featured_true.toLocaleDateString('en-IN')}
                                                    </span>
                                            ) : (
                                                ""
                                            )}
                                            {addOn?.name === "Homepage Gallery" ? (
                                                is_homePageGallery && <span style={{ color: 'red' }}>This ad has expired on {is_homePageGallery.toLocaleDateString('en-IN')}</span>
                                            ) : (
                                                ""
                                            )}

                                            {addOn?.name === "Bump up" ? (
                                                is_bump_up && <span style={{ color: 'red' }}>This ad has expired on {is_bump_up.toLocaleDateString('en-IN')}</span>
                                            ) : (
                                                ""
                                            )}
                                            
                                            {addOn?.name === "Urgent" ? (
                                                is_uregnt && <span style={{ color: 'red' }}>This ad has expired on {is_uregnt.toLocaleDateString('en-IN')}</span>
                                            ) : (
                                                ""
                                            )}


                                            {addOn?.name === "Link to your website" ? (
                                                is_link_webiste && <span style={{ color: 'red' }}>This ad has expired on {is_link_webiste.toLocaleDateString('en-IN')}</span>
                                            ) : (
                                                ""
                                            )}

                                            {addOn?.name === "Upcoming Event" ? (
                                                is_upcoming_event && <span style={{ color: 'red' }}>This ad has expired on {is_upcoming_event.toLocaleDateString('en-IN')}</span>
                                            ) : (
                                                ""
                                            )}





                                            {addOn?.name === "Link to your website" ? (
                                            <input
                                                disabled={!selectedAddOn.some((selectOptionArr) => selectOptionArr.addonId === addOn._id)}
                                                value={website || website_urls}
                                                onChange={(e) => updateWebsite(e.target.value)}
                                                type="text"
                                                name="website"
                                                className={style.website_text_input_field}
                                                placeholder="Enter website link"
                                            />
                                            ) : (
                                            ""
                                            )}

                                            
                                                {addOn?.name === "Price Drop" && (
                                                <div>
                                               {priceError && <span className={style.error_message}>
                                            {priceError} <span className={style.error_drop}>{myCurrency ? getSymbolFromCurrency(myCurrency) : "$"}{""}{adsPrice}</span>  
                                           {" "}  <span className={style.actual_drop_price}>{getSymbolFromCurrency(myCurrency) || "$"}{""}{prices}</span> 
                                             </span>}
                                                <input
                                                disabled={!selectedAddOn.some((selectOptionArr) => selectOptionArr.addonId === addOn._id)}
                                                value={prices}
                                                onChange={handlePriceChange}
                                                onWheel={(e) => e.currentTarget.blur()}
                                                type="number"
                                                name="price"
                                                className={priceValid ? style.website_text_input_field : `${style.website_text_input_field} ${style.invalid}`}
                                                placeholder="Enter Price"
                                                />                                          
                                                </div>
                                                )}

                                            {/*                                             
                                            {   addOn?.name === "Link to your website"? <input disabled={!selectedAddOn.some(selectOptionArr => selectOptionArr.addonId === addOn._id)} value={website} onChange={(e)=>updateWebsite(e.target.value)} type="text" name="website" className={style.website_text_input_field} placeholder='Enter website link' /> : ``
                                            } */}


                                        {/* My work */}
                                    
                                            {/* {   addOn?.name === "Price Drop"? <input disabled={!selectedAddOn.some(selectOptionArr => selectOptionArr.addonId === addOn._id)} value={prices} onChange={(e)=>updatePrices(e.target.value)} type="text" name="price" className={style.website_text_input_field} placeholder='Enter Price' /> : ``                                             
                                            } */}
                                            {/* selectedAddOn.some(selectOptionArr => selectOptionArr.addonId === addOn._id)  */}
                                        </div>

                                        <select id={style.select_plan_input} name="price" onChange={(e) => { handleChangePrice(e, addOn?._id) }} className={checkingSelectedPrice(addOn?._id) ? `${style.select_plan_input} ${style.active_card}` : style.select_plan_input} >

                                            {
                                                addOn?.price?.length ?
                                                    addOn?.price.map((price, i) => {
                                                        return (
                                                            <option 
                                                            selected ={selectedAddOn.find((addonlist)=>{return ( addonlist?.addonPrice === price?.amount && addOn._id === addonlist.addonId)} )}
                                                             key={i} 
                                                               data-price-id={price?._id} 
                                                               value={price?.amount}>


                            
                                                            {/* {addOn?.name === "Bump up" ? (
                                                            price.duration === 30 ? (
                                                                `Everyday - ${!price?.isfree && getCurrencySymbol(price?.currency)}${price?.isfree ? "Free" : price?.amount}`
                                                            ) : (
                                                                `Every ${price.duration} days - ${!price?.isfree && getCurrencySymbol(price?.currency)}${price?.isfree ? "Free" : price?.amount}`
                                                            )
                                                            ) : (
                                                            `${price.duration} days - ${!price?.isfree && getCurrencySymbol(price?.currency)}${price?.isfree ? "Free" : price?.amount}`
                                                            )} */}



                                                {addOn?.name === "Bump up" ? (
                                                    price.duration === 30 ? (
                                                        `Everyday - ${!price?.isfree && getCurrencySymbol(price?.currency)}${price?.isfree ? "Free" : price?.amount}`
                                                    ) : (
                                                        `Every ${price.duration} days - ${!price?.isfree && getCurrencySymbol(price?.currency)}${price?.isfree ? "Free" : price?.amount}`
                                                    )
                                                ) : (
                                                    addOn?.name === "Link to your website" ? (
                                                        `Free`
                                                    ) : (
                                                        `${price.duration} days - ${!price?.isfree && getCurrencySymbol(price?.currency)}${price?.isfree ? "Free" : price?.amount}`
                                                    )
                                                )}



                                                                </option>

                                                        )
                                                    }) : ``
                                        }

                                        </select>
                                    </section>

                                )

                            })
                            : ``
                    }

                </section>

                <div className={style.price_calculation_section}>

                    <section className={style.price_calculation_heading}>
{/* 
                        <span>Price per Ad</span>

                    

                        {
                            selectedAddOn.map((addon) => {
                                return <span>{addon?.addonName}</span>
                            })
                        } */}

                        {/* <span>{selectedAddOn.length !==0 ? "Post My Ad":'Total'}-</span> */}
                        <span>Total</span>

                    </section>

                    <section className={style.price_calculation_amount}>

                        {/* <span  className={style.amount_text}>${selecetdPlan?.price?.amount}</span>
                      
                        {
                            selectedAddOn.map((addon) => {
                                return <span className={style.amount_text}>{!addon?.isfree && getCurrencySymbol(addon?.addonCurrency)}{addon?.addonPrice}</span>
                            })
                        } */}

                        <span  className={style.amount_text}>${totalAmount}</span>

                    </section>

                </div>
{/* 
                <CheckoutButton cancelButtonText='Back' submitBtnText={selectedAddOn.length !==0 ? "Checkout and Post My Ad": 'Post My Ad'} id="span-2" className={style.form_button_section} backBtnClickHandler={backBtnClickHandler} onClickHandler={handle} showLoadder={false} />  */}


                <CheckoutButton
                cancelButtonText="Back"
                // submitBtnText={selectedAddOn.length !== 0 ? "Post My Ad" : "Post My Ad ${totalAmount}"}
                submitBtnText={totalAmount !== 0 ? `Post My Ad - $${totalAmount}` : "Post My Ad"}
                id="span-2"
                className={style.form_button_section}
                backBtnClickHandler={backBtnClickHandler}
                onClickHandler={handlenew}
                showLoadder={showAdsPlan}
                disabled={isButtonDisabled}
                // showAdsPlan={showAdsPlan}
                />    
            </section>
        </>
    )

}
