import React, { useState , useEffect} from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import Tooltip from '../../tools-tip/Tooltip';

import style from "./FilterLocation.module.css";
import { Select } from 'antd';
import Modal from 'react-modal';

export default function FilterLocation({ callBackFunction = () => { },label = "Location", placeholder = "Enter your location", location = ``,latitude=``, longitude=``, updateFormData = () => {} , formData={}, required=false, setLocationResponseData = () => {}, onLocationChange = () => {},filterlocations, setCheckTypeLoc=()=>{} }) {

    const [locationReponseData, toogleLoactionResponseData] = useState(false);
    const [address, updateAddress] = useState(location);
    // const [coordinate, updateCoordinate] = useState({
    //     lat: latitude,
    //     lng: longitude
    // });

    console.log(address,"hgdjfklgh")
    
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    });


    const ref = useOnclickOutside(() => {
        clearSuggestions();
        // if(data.length == 0){
        //     console.log(`innner loop`)
        //     updateFormData({
        //         ...formData,
        //         location: ``
        //     })
        // }
        // console.log(data)
    });

    // const getCurrentLocation = () => {
    //     if ("geolocation" in navigator) {
    //       // Request the user's current location
    //       navigator.geolocation.getCurrentPosition(
    //         (position) => {

    //           const { latitude, longitude } = position.coords;
              
              
    //           console.log(`Current Location: Latitude ${latitude}, Longitude ${longitude}`);

    //           onLocationChange(latitude, longitude);
              
    //           // Optionally, update the input field or do something with the location
    //         //   setValue(`Current Location: ${latitude}, ${longitude}`);
    //           setValue(`Sector 71, Phase 1, Mohali Village, Sahibzada Ajit Singh Nagar, Punjab, India`);
    //         },
    //         (error) => {
    //           console.error("Error getting current location:", error);
    //           // Handle errors if needed
    //         }
    //       );
    //     } else {
    //       console.log("Geolocation not supported in this browser.");
    //       // Handle case where geolocation is not supported
    //     }
    //   };
      

    const getCurrentLocation = () => {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
      
              const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
              
              fetch(apiUrl)
                .then(response => response.json())
                .then(data => {
                  const locationName = data.display_name;
      
                  console.log(`Current Location: Latitude ${latitude}, Longitude ${longitude}`);
                  console.log(`Location Name: ${locationName}`);
      
                  setValue(locationName);

                  localStorage.setItem('SelectedCity', locationName);
                  localStorage.setItem('latts', latitude);
                  localStorage.setItem('langs', longitude);
      
                  onLocationChange(latitude, longitude, locationName);
                })
                .catch(error => {
                  console.error("Error getting location name:", error);
                });
            },
            (error) => {
              console.error("Error getting current location:", error);
            }
          );
        } else {
          console.log("Geolocation not supported in this browser.");
        }
      };
      
      

    const handleInput = (e) => {
        console.log(e.target.value)
        const inputValue = e.target.value;
        callBackFunction(e.target.value)
        toogleLoactionResponseData(true)
        setValue(e.target.value);
        setCheckTypeLoc(!!inputValue);
    };


    const handleSelect =
        ({ description }) =>
            () => {

                setValue(description, true);
                toogleLoactionResponseData(false)
                clearSuggestions();

                // Get latitude and longitude via utility functions
                getGeocode({ address: description }).then((results) => {
                    console.log(results)
                    const { lat, lng } = getLatLng(results[0]);
                    onLocationChange(lat, lng,results[0].formatted_address);
                    callBackFunction(results[0].formatted_address,lat,lng)
                    updateAddress(results[0].formatted_address,lat,lng)
                    localStorage.setItem('SelectedCity', results[0].formatted_address);
                    localStorage.setItem('latts', lat)
                    localStorage.setItem('langs', lng)
                    // loctiondata(results[0].formatted_address,lat,lng)
                
                    // updateCoordinate({
                    //      lat: lat,
                    //      lng: lng
                    // })
                //   abc({lat, lng})
               
                  setLocationResponseData(true);
                  console.log("Formatted Address:", results[0].formatted_address);
                    console.log("📍 Coordinates: ", { lat, lng });
                });
            };


            useEffect(() => {
                toogleLoactionResponseData(false)
                // getCurrentLocation();
              }, [])
              useEffect(() => {
                setValue(location);
                updateAddress(location);
                // updateCoordinate({
                //     lat: latitude,
                //     lng: longitude
                // });
            }, [location, latitude, longitude]);

     useEffect(()=>{
                setValue(filterlocations)
            },[filterlocations])     
            
            const handleUpdateLocation = () => {
                getCurrentLocation();
              };

              useEffect(() => {
                const storedLocation = localStorage.getItem('SelectedCity');
                if (storedLocation) {
                  setValue(storedLocation);
                  // You might want to update other state variables or perform other actions here
                }
              }, []);
            
    return (
        <section id="span-2" className={style.input_Wrapper}>
               

            <div className={style.location_input_section} ref={ref}>
                <input
                    value={value}
                    onChange={handleInput}
                    placeholder={placeholder}
                />

                <div className={style.current_location}>
                <i class="fa-solid fa-location-arrow"></i>
                  <span onClick={handleUpdateLocation}> Use my current location</span>
                </div>

                {/* We can use the "status" to decide whether we should display the dropdown or not */}
                {status === "OK" && locationReponseData && <ul className={style.location_ul_lists}>{

                    data.map((suggestion) => {
                        const {
                            place_id,
                            structured_formatting: { main_text, secondary_text },
                        } = suggestion;

                        return (
                            
                            <li key={place_id} onClick={handleSelect(suggestion)}>
                                <strong>{main_text}</strong> <small>{secondary_text}</small>
                            </li>
                            
                        );
                    })
                }</ul>}
            </div>

        </section>

    )
}
