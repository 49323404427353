import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styles from './formElems.module.css';
import { WordCount } from '../../utils/verification';

function RichTextEditor({ value, updateFormData, formData = {}, label = "", required = false  }) {
  const [editorValue, setEditorValue] = useState(value);

  const handleEditorChange = (value) => {
    setEditorValue(value);
  
    // updateFormData({
    //   ...formData,
    //   descriptions: value
    // })

//     updateFormData((prevstate)=>({
//     ...prevstate,
//     descriptions:value
//  }))

updateFormData((prevstate)=>({
  ...prevstate,
  descriptions:value
}))


  };

  return (
    <section id='span-2'>
      <label id='label'>{label}<span className='optional_text'>(minimum 5 words)</span> {required ? <span className={styles.required_field}>*</span> : <span className='optional_text'>(Optional)</span>}</label>
      <ReactQuill
        name
        value={value}
        onChange={handleEditorChange}
        modules={{
          toolbar: [
            ['bold', 'italic'],
            ['underline'],
            [{ list: 'ordered' }, { list: 'bullet' }]
          ]
        }}
      />
      <p className={styles.word_count_text}>{ WordCount(formData.descriptions) < 5 && WordCount(formData.descriptions) !== 0 ?`Please enter at least ${5-WordCount(formData.descriptions)} word more`:``}</p>
    </section>
  );
}

export default RichTextEditor;
