import React, { useState } from 'react';
import { Watch } from 'react-loader-spinner'
import style from './loaderButton.module.css';

export default function LoaderButton() {

    return (
        <div id={style.submit_btn_wrapper} className="form-group">
       
                <Watch
                    height="30"
                    width="30"
                    radius="48"
                    color="#ffffff"
                    ariaLabel="watch-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
     

        </div>
        
    )

}