import React from 'react';
import style from "./formButton.module.css"
import LoaderButton from '../loader-button/LoaderButton';

export default function FormButton({cancelButtonText = "", submitBtnText = "",  backBtnClickHandler = () =>{}, onClickHandler=()=>{}, className=``, id=``,showLoadder = false,status}) {
    return (
        <section id={id} className={`${style.button_wrapper} ${className} button_wrapper`}>

            <div onClick={!showLoadder ? backBtnClickHandler : ``} id="back_button" className="form-group">
                <p id={style.back_button} className=" btn-confirm"> {cancelButtonText} </p>
            </div>

            <div id={style.submit_btn_wrapper} className="form-group">
                {
                    showLoadder ? <LoaderButton /> : <button onClick={onClickHandler} type="submit" className="btn btn-confirm">
                    {submitBtnText}
                </button>
                }
            </div>

        </section>
    )
}
