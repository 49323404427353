import React from 'react'

export default function button(props) {
  return (
    <div className="button-box">
        <button type="button" className="btn btn-outline-dark">{props.title}</button>
    </div> 
  )
}









