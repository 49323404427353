export default function validate(values) {
    let errors = {};
    if (!values.title) {
      errors.title = 'Email address is required';
    } else if (!/\S+@\S+\.\S+/.test(values.title)) {
      errors.title = 'Email address is invalid';
    }
    if (!values.password) {
      errors.password = 'Password is required';
    } else if (values.password.length < 8) {
      errors.password = 'Password must be 8 or more characters';
    }
    return errors;
  };
  