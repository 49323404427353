import React, { useState , useEffect} from 'react';
import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import Tooltip from '../../tools-tip/Tooltip';

import style from "./selectLocation.module.css";

export default function SelectLocation({ callBackFunction = () => { }, label = "Location", placeholder = "Enter your location", location = ``,latitude=``, longitude=``, updateFormData = () => {} , formData={}, required=false, setLocationResponseData = () => {}, onLocationChange = () => {}, mylocation ,showMark}) {

    const [locationReponseData, toogleLoactionResponseData] = useState(false);
    const [address, updateAddress] = useState(location);
    // const [coordinate, updateCoordinate] = useState({
    //     lat: latitude,
    //     lng: longitude
    // });
    
    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    });


    const ref = useOnclickOutside(() => {
        clearSuggestions();
        // if(data.length == 0){
        //     console.log(`innner loop`)
        //     updateFormData({
        //         ...formData,
        //         location: ``
        //     })
        // }
        // console.log(data)
    });

    const getCurrentLocation = () => {
        if ("geolocation" in navigator) {
          // Request the user's current location
          navigator.geolocation.getCurrentPosition(
            (position) => {

              const { latitude, longitude } = position.coords;
              
              console.log(`Current Location: Latitude ${latitude}, Longitude ${longitude}`);

              onLocationChange(latitude, longitude);
              
              // Optionally, update the input field or do something with the location
            //   setValue(`Current Location: ${latitude}, ${longitude}`);
            //   setValue(`Sector 71, Phase 1, Mohali Village, Sahibzada Ajit Singh Nagar, Punjab, India`);
            },
            (error) => {
              console.error("Error getting current location:", error);
              // Handle errors if needed
            }
          );
        } else {
          console.log("Geolocation not supported in this browser.");
          // Handle case where geolocation is not supported
        }
      };
      

      const handleInput = (e) => {
        const value = e.target.value;
        console.log(e.target.value);
        callBackFunction(e.target.value);
        toogleLoactionResponseData(true);
        setValue(e.target.value);
    
        // Use getGeocode to get additional information including latitude and longitude
        getGeocode({ address: e.target.value })
            .then((results) => {
                if (results.length > 0) {
                    const { lat, lng } = getLatLng(results[0]);
                    onLocationChange(lat, lng);
                    callBackFunction(results[0].formatted_address,lat,lng)
                    updateAddress(results[0].formatted_address,lat,lng)
                    // Do whatever you need with lat and lng here
                }
            })
            .catch((error) => {
                console.error("Error getting geocode:", error);
            });
    };
    

    const handleSelect =
        ({ description }) =>
            () => {

                setValue(description, true);
                toogleLoactionResponseData(false)
                clearSuggestions();

                // Get latitude and longitude via utility functions
                getGeocode({ address: description }).then((results) => {
                    console.log(results)
                    const { lat, lng } = getLatLng(results[0]);
                    onLocationChange(lat, lng);
                    callBackFunction(results[0].formatted_address,lat,lng)
                    updateAddress(results[0].formatted_address,lat,lng)


                    
                    // updateCoordinate({
                        //      lat: lat,
                        //      lng: lng
                        // })
                        //   abc({lat, lng})
                        setLocationResponseData(true);
                        
                   console.log("Formatted Address:", results[0].formatted_address);
                    console.log("📍 Coordinates: ", { lat, lng });
                });
            };


            useEffect(() => {
                toogleLoactionResponseData(false)
                getCurrentLocation();
              }, [])

              useEffect(() => {
                setValue(location);
                // setValue(mylocation);
                

                updateAddress(location);
                // updateCoordinate({
                //     lat: latitude,
                //     lng: longitude
                // });
            }, [location, latitude, longitude]);
            
    return (
        <section id="span-2" className={style.input_Wrapper}>
            <span id='label'>{label}{required ? <span className={style.required_field}>*</span>:''}
            {showMark !== false &&
             <Tooltip id={style.tooltip_text_wrapper} text="Your location will be displayed on your ad and help determine which city it will be posted in.">
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="#FD7E14" strokeWidth="2.5" strokeLinecap="square" strokeLinejoin="bevel"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
        </Tooltip>
}
        </span>        

            <div className={style.location_input_section} ref={ref}>
                <input
                    value={value}
                    onChange={handleInput}
                    placeholder={placeholder}
                    style={{height:'50px'}}
                />
                {/* We can use the "status" to decide whether we should display the dropdown or not */}
                {status === "OK" && locationReponseData && <ul className={style.location_ul_lists}>{
                    data.map((suggestion) => {
                        const {
                            place_id,
                            structured_formatting: { main_text, secondary_text },
                        } = suggestion;

                        return (
                            <li key={place_id} onClick={handleSelect(suggestion)}>
                                <strong>{main_text}</strong> <small>{secondary_text}</small>
                            </li>
                        );
                    })
                }</ul>}
            </div>

        </section>

    )
}
