import React, { useEffect, useState } from 'react';
import CloseIcon from "../../../assest/images/closeIcon.svg"
import style from './imageUpload.module.css';
import { toast } from 'react-toastify';
import Tooltip from '../../tools-tip/Tooltip';
import './test.css'

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import cuid from 'cuid';
import axios from 'axios';
import appURLs from '../../../appURL';

export default function ImageUpload({ images = [], updateImage ,handleFileChange ,selectedFiles,setSelectedFiles,myadsType,myadsId}) {
   const appURL = appURLs()
    const onClickHandlerForImageUpload = () => {

        let input = document.createElement('input');
        input.type = 'file';
        input.multiple = true;
        input.accept = 'image/*';
        input.onchange = (e) => {

             const files = e.target.files;

             if (files.length > 0) {
                // Call the handleFileChange function passed as a prop
                handleFileChange(e);
              }

            // let imageURLArr = [];

            // const imagesArr = e?.target?.files;

            // if (imageURLArr.length > 9) return toast.error(`Only 10 image allowed to upload`);


            // if (imagesArr?.length) {

            //     for (let index = imagesArr.length - 1; index >= 0; index--) {

                   
            //         const element = imagesArr[index];

            //         if (element.type === "image/png" || element.type === "image/jpeg" || element.type === "image/jpeg"){
            //             const encodedUrl = URL.createObjectURL(element);

            //              if(images.length  < 9) {
            //                 imageURLArr.push(encodedUrl)
            //              }

            //         }

            //     }
                
            // }

            // updateImage([...images, ...imageURLArr]);


        };
        input.click();
    }

    useEffect(()=>{
        // alert(`imagedeletereq${myadsType},${myadsId}`);
    },[myadsType,myadsId])


    // const onClickHandlerForImageUpload = (id) => {
    //     let input = document.createElement('input');
    //     input.type = 'file';
    //     input.multiple = true;
    //     input.accept = 'image/*';

    //     input.onchange = (e) => {
    //         const imagesArr = e?.target?.files;

    //         if (imagesArr?.length) {
    //             const promises = Array.from(imagesArr).map((element) => {
    //                 return new Promise((resolve, reject) => {
    //                     const img = new Image();
    //                     img.src = URL.createObjectURL(element);

    //                     img.onload = () => {
    //                         const width = img.width;
    //                         const height = img.height;

    //                         if (width === 305 && height === 237 && element.size <= 10 * 1024 * 1024) {
    //                             const encodedUrl = URL.createObjectURL(element);
    //                             resolve(encodedUrl);
    //                         } else {
    //                             let message = ``;
    //                             if (element.size >= 10 * 1024 * 1024) message = `please provide image size less tha 10mb`
    //                             else message = `Image dimension should be 305x237 `
    //                             reject(new Error(message));
    //                         }
    //                     };

    //                     img.onerror = () => {
    //                         reject(new Error('Error loading image'));
    //                     };
    //                 });
    //             });

    //             Promise.all(promises)
    //                 .then((imageURLArr) => {
    //                     updateImage([...images, ...imageURLArr]);
    //                 })
    //                 .catch((error) => {

    //                     toast.error(error.message);
    //                     console.error(error);
    //                 });
    //         }
    //     };

    //     input.click();
    // };

    // const clickHandleForDeleteImage = (imageUrl = ``) => {
    //     console.log(imageUrl)
    //     const updateImageUrlArr = selectedFiles.filter((image) => String(image) !== String(imageUrl));
    //     setSelectedFiles(updateImageUrlArr)
      
    // }
    // sourabh
    // sourabhverma

    const clickHandleForDeleteImage = async(file,imageUrl = '') => {
        // alert(`deletefile${file}----${imageUrl}`);
        // selectedFiles?.map((item,index)=>{
        //     alert(`Item ${item}`);
        // })
        const updatedSelectedFiles = selectedFiles.filter((filee) => filee !== file);
        // const updatedSelectedFiles = selectedFiles.filter((filee) => filee?.url !== file?.url);
        setSelectedFiles(updatedSelectedFiles);
        const token=localStorage.getItem('token');
        await axios.patch(`${appURL}/posts/ads/remove-media`,{
            ads_id:myadsId,
            ads_type:myadsType,
            image_id:file?._id
        },
        {
            headers:{
                "Authorization":`Bearer ${token}`
            }
        }
        )
        .then((res)=>{console.log("image deleted succes",res)})
        .catch((err)=>{console.log("err image deleted",err)})
      };

      const onDragStart = (e, index) => {
        e.dataTransfer.setData('draggedItemIndex', index);
      };
    
      const onDragOver = (e) => {
        e.preventDefault();
      };
    
      const onDrop = (e, dropIndex) => {
        const draggedItemIndex = e.dataTransfer.getData('draggedItemIndex');
        if (draggedItemIndex === dropIndex) return;
        const items = [...selectedFiles];
        const [draggedItem] = items.splice(draggedItemIndex, 1);
        items.splice(dropIndex, 0, draggedItem);
        setSelectedFiles(items);
      };
      



    const getRandomValue = () => {
        return Math.floor(Math.random() * 400) + 1;
    };


    const items = images.slice(0,10).map((val, index) => ({
        title: 'Item ' + index,
        index: index,
        id: cuid(),
        imageSrc: val,
    }));

    // const items = images.map((val, index) => ({
    //     title: 'Item ' + index,
    //     index: index,
    //     id: cuid(),
    //     imageSrc: val,
    // }));


    const SortableComponent = () => {
        const [state, setState] = useState({
            items,
        });

        const onSortEnd = ({ oldIndex, newIndex }) => {
            setState({
                items: arrayMove(state.items, oldIndex, newIndex),
            });
        };

        const SortableItem = SortableElement(({ item }) => (
            <div className="item" style={{
                opacity: 1, visibility: "inherit", zIndex: 100000000000000000000
            }} >
                <div className="inner-item">
                    <div key={item.imageSrc} className={style.hero_image_div} style={{
                        backgroundImage: `url(${item.imageSrc})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        height: '80px',
                        width: '80px',
                        borderRadius: "10px",
                        position: "relative"

                    }}>

                        {/* <img onClick={() => { clickHandleForDeleteImage(item.imageSrc) }} className={style.close_icon} style={{ height: "30px", zIndex: 1000000000000000000000000000 }} src={CloseIcon} alt="Image" /> */}
                    </div>
                    {/* <img height="50px" src={item.imageSrc} /> */}

                </div>
            </div>
        ));

        const SortableList = SortableContainer(({ items }) => (
            <div className="container">
                {items.map((item, index) => (
                    <SortableItem key={`${item.id}`} index={index} item={item} />
                ))}
            </div>
        ));

        return (
            <SortableList
                items={state.items}
                onSortEnd={onSortEnd}
                axis="xy"
                pressDelay={300}
                helperClass="SortableHelper"
            />
        );

    }
    return (
        <section id='span-2' className={style.image_upload_wrapper}>

            {
                selectedFiles && selectedFiles.length > 0 ? `` : <div className={style.image_icon}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.6667 4.00008H10.4C8.15979 4.00008 7.03969 4.00008 6.18404 4.43606C5.43139 4.81955 4.81947 5.43147 4.43597 6.18412C4 7.03977 4 8.15987 4 10.4001V21.6001C4 23.8403 4 24.9604 4.43597 25.816C4.81947 26.5687 5.43139 27.1806 6.18404 27.5641C7.03969 28.0001 8.15979 28.0001 10.4 28.0001H22.6667C23.9066 28.0001 24.5266 28.0001 25.0353 27.8638C26.4156 27.4939 27.4938 26.4157 27.8637 25.0354C28 24.5267 28 23.9067 28 22.6668M25.3333 10.6667V2.66675M21.3333 6.66675H29.3333M14 11.3334C14 12.8062 12.8061 14.0001 11.3333 14.0001C9.86057 14.0001 8.66667 12.8062 8.66667 11.3334C8.66667 9.86066 9.86057 8.66675 11.3333 8.66675C12.8061 8.66675 14 9.86066 14 11.3334ZM19.9867 15.8909L8.7082 26.1441C8.07382 26.7209 7.75663 27.0092 7.72857 27.259C7.70425 27.4755 7.78727 27.6903 7.95091 27.8341C8.13971 28.0001 8.56837 28.0001 9.42571 28.0001H21.9413C23.8602 28.0001 24.8196 28.0001 25.5732 27.6777C26.5193 27.273 27.2729 26.5193 27.6776 25.5733C28 24.8197 28 23.8603 28 21.9414C28 21.2957 28 20.9729 27.9294 20.6723C27.8407 20.2945 27.6706 19.9406 27.431 19.6353C27.2403 19.3924 26.9883 19.1907 26.4841 18.7874L22.7544 15.8036C22.2499 15.4 21.9976 15.1981 21.7197 15.1269C21.4748 15.0641 21.2172 15.0722 20.9767 15.1503C20.7039 15.2389 20.4648 15.4563 19.9867 15.8909Z" stroke="#475467" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>

                </div>
            }

            <div className={style.image_wrapper_section}>

          <div className={style.files_container}>
          {selectedFiles.length > 0 && selectedFiles.map((file, i) => (
            <div
              key={i}
              className={style.hero_image_div}
              draggable
              onDragStart={(e) => onDragStart(e, i)}
              onDragOver={onDragOver}
              onDrop={(e) => onDrop(e, i)}
              style={{
                backgroundImage: `url(${file?.url ? file?.url : URL.createObjectURL(file)})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '80px',
                width: '85px',
                borderRadius: "10px",
                marginTop: "20px",
                position: "relative",
              }}
            >
              <img
                onClick={() => clickHandleForDeleteImage(file)}
                className={style.close_icon}
                style={{ height: "30px", zIndex: 100 }}
                src={CloseIcon}
                alt="Close Icon"
              />
            </div>
          ))}
        </div>

                {/* {
                    images && images.length ?
                        images.map((data, i) => {
                            return (

                                <div key={i} className={style.hero_image_div} style={{
                                    backgroundImage: `url(${data})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: '80px',
                                    width: '85px',
                                    borderRadius: "10px",
                                    position: "relative"

                                }}>

                                    <img  onClick={() => { clickHandleForDeleteImage(data) }} className={style.close_icon} style={{ height: "30px", userSelect: 'none', }} src={CloseIcon} alt="Image" />
                                </div>

                            )
                        }) : ``
                } */}


                <SortableComponent />

            </div>

            {
                selectedFiles.length <= 9 &&
                <div className={style.upload_imgae_btn_wrapper}>
                    <span onClick={onClickHandlerForImageUpload} className={style.image_upload_btn}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.66602 13.3333L9.99935 10M9.99935 10L13.3327 13.3333M9.99935 10V17.5M16.666 13.9524C17.6839 13.1117 18.3327 11.8399 18.3327 10.4167C18.3327 7.88536 16.2807 5.83333 13.7493 5.83333C13.5673 5.83333 13.3969 5.73833 13.3044 5.58145C12.2177 3.73736 10.2114 2.5 7.91602 2.5C4.46424 2.5 1.66602 5.29822 1.66602 8.75C1.66602 10.4718 2.36222 12.0309 3.48847 13.1613" stroke="#344054" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>

                        <p>Upload photo<Tooltip text="You can upload a maximum of 10 photos, that are at least 300px wide or tall (we recommend at least 1000px). Drag and drop to change the order of your pictures.">

                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="#FD7E14" strokeWidth="2.5" strokeLinecap="square" strokeLinejoin="bevel"><circle cx="12" cy="12" r="10"></circle><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>
                        </Tooltip></p>


                    </span>

                </div>
            }


        </section>
    )
}
