import React from 'react'
import $ from 'jquery'

export default function Job_categorycard(props) {
    return (
        <div className="card-image">
            <div className="card">
                <div className="cardimage job-category-card">
                    <div className="job-post-text">
                        <h3>Welding</h3>
                        <p>25 listings</p>
                    </div>
                </div>
                {/* <img src="/images/room.jpg" alt="logo" className="image"/> */}
            </div>
        </div> 
    )
}









