import React, { useEffect, useState ,useMemo} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import { isTruthyString, isValidEmailAddress, isValidPassword } from '../../utils/verification';
import style from './login.module.css'
import AppleLogin from "react-apple-login";
import LoaderButton from '../buttons/loader-button/LoaderButton';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import CommonModalWrapper from '../common-modal-Wrapper/CommonModalWrapper';
import { useGoogleLogin } from '@react-oauth/google';
import CloseIcon from '../Icons/CloseIcon';
import VerifyOTP from '../verify-otp-card/VerifyOTPCard';
import appURLs from '../../appURL';

import ForgetPassword from '../forget-password/ForgetPassword';
import axios from "axios"

import Inputs from '../Inputs/Inputs';
import { requestForToken } from '../Firebase/firebased';


export default function LoginCard({ toggleModalForRegister = () => { }, updateModalVisibilityForLogin = () => { } }) {
    const [passwordInputTypeForPassword, updatePasswordInputTypeForPassword] = useState(`password`);
    const [modalIsOpenForForgetPassword, updateModalVisibilityForForgetPassword] = useState(false);

    
    const appURL = appURLs();
requestForToken()

    const passwordInputChangeHandler = (inputTpe) => {

        updatePasswordInputTypeForPassword(inputTpe);

    }

    const toggleModalForForgetPassword = (toggleValue = false) => {

        updateModalVisibilityForLogin(false)
        updateModalVisibilityForForgetPassword(toggleValue)
    }

    const navigate = useNavigate();
    const fetchConfig = getFetchConfig();

    // useEffect(()=>{
    //     const getToken=async()=>{
    //     const deviceTokenPromise = requestForToken();
    //     console.log("deviceTokenPromise",deviceTokenPromise);
    //         const deviceToken = await deviceTokenPromise;
    //         console.log(deviceToken, "device")
    //         localStorage.setItem("deviceToken",deviceToken)
    //     }
    //     getToken();
    // },[])

    const [showLoader, toggleLoader] = useState(false),
        [formData, setFormData] = useState({
            email_address: '',
            password: ''
        });

        const [userName, updateUserName] = useState(``);
       const [userDetail, setUserDetail] = useState({});

  const [nameInitial, updateNameInitial] = useState({
    firstName: ``,
    lastName: ``
  });

    const handleChangeForLoginFormInput = (e) => {

        const inputName = e?.target?.name || e?.name,
            inputValue = e?.target?.value || e?.value;

        setFormData((prevState) => ({
            ...prevState,
            [inputName]: inputValue
        }));

        // if(!(formData.email_address && formData.password)){
        //     updateDisabledRememberMe(true)
        // }
    };



    const [rememberMe, updateRememberMe] = useState({
        email_address: ``,
        password: ``,
        value: false
    })

    const [selectedRememberMe, updateSelectedRememberMe] = useState([]);

    const handleChange = (event) => {
        console.log(`working`)
        console.log(`working`)
        const input = event.target;
        const checked = event.target.checked;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        console.log(value)

        if (checked) {
            updateSelectedRememberMe([event.target.value]);

        } else {
            updateSelectedRememberMe(selectedRememberMe.filter((e) => e !== event.target.value));
        }

        updateRememberMe({
            value: value,
            email: formData.email_address,
            password: formData.password
        })
    };


    
    const colorList = {
        A: "#00FFFF",
        B: "#0000FF",
        C: "#FF8C00",
        D: "#FFD700",
        E: "#008000",
        F: "#FF00FF",
        G: "#DDA0DD",
        H: "#FF69B4",
        I: "#4B0082",
        J: "#00A86B",
        K: "#F0E68C",
        L: "#E6E6FA",
        M: "#000080",
        N: "#E0115F",
        O: "#808000",
        P: "#800080",
        Q: "#E0D0C6",
        R: "#FF0000",
        S: "#C0C0C0",
        T: "#008080",
        U: "#808000",
        V: "#D2691E",
        W: "#EE82EE",
        X: "#738678",
        Y: "#FFFF00",
        Z: "#000000",
      };
    
    const onSubmitHandler = async (e) => {
        e.preventDefault();
        toast.dismiss();

const deviceTokenPromise = requestForToken();   
console.log("deviceTokenPromise",deviceTokenPromise);
const deviceToken = await deviceTokenPromise;
console.log(deviceToken, "device")
localStorage.setItem("deviceToken",deviceToken)
        const { email_address, password } = formData;


        const missingData = [],
            invalidData = [];

        if (!email_address) missingData.push(`email address`);
        else if (email_address && !isValidEmailAddress(email_address)) 
        invalidData.push(`email_address`);

        if (!password) missingData.push(`password`);
        // else if (password && !isValidPassword(password))
        // invalidData.push(`password should include at least one upper case, one lower case,one digit & special character`);
        // else{
        //     invalidData.push(`Incorrect password`);  
        // }

        
        //Edited logics
        // if(missingData.length || invalidData.length){
        //     if (missingData.length) toast.error("Invalid Credenstails");;
        //     if (invalidData.length) toast.error("Invalid Credenstails");;                        
        //     return;
        // }
        //Edited logics


        // Show errors if needed

        if (missingData.length || invalidData.length) {
            if (missingData.length) toast.error(`Missing:- ${missingData.join(`, `)}`);
            if (invalidData.length) toast.error(`Invalid:- ${invalidData.join(`, `)}`);                        
            return;
        }

        




        const loginDataObj = {
            email: email_address,
            device_token: `${deviceToken}`,
            device_type: 3,
            password,
        };

        if (rememberMe.value) {

            updateRememberMe({
                email: email_address,
                password,
            })
        }

        // Clearing token from LocalStorage if any available
        localStorage.removeItem(`token`);
        toggleLoader(true)

        fetch(`${appURL}/login-with-email`, {
            ...fetchConfig,
            body: JSON.stringify(loginDataObj)
        })
            .then(handleFetchErrors)
            .then((res) => {
                console.log(res)
                toast.dismiss();
                toggleLoader(false);
                // standardFetchHandlers.success(res);\

                if (res?.status === 404 || res?.status === 401) {
                    return toast.error(res.message);
                } else if (res?.status === 200) {

                    const userData = res?.data;
                    console.log("userData whle login",userData)
                    const Obj = {
                        name: userData.name,
                        phone_number: userData?.phone_number || ``,
                        email_address: userData?.email_address || ``,
                        source: userData.source,
                        picture: userData.picture || ``,
                        userId:userData.userId || ``
                    }
                    localStorage.setItem(`user`, JSON.stringify(Obj));

                    updateModalVisibilityForLogin(false)
                    localStorage.setItem(`rememberMe`, JSON.stringify(rememberMe))
                    localStorage.setItem(`token`, res?.token);
                    changeColorTheme(userData);

                    navigate(`/`);
                    window.location.reload();
                    return;
                } else if( res?.status === 204 ){
                    toggleModalForVerifyOTP(true)
                }else if(res?.status === 205){
                    updateIsMobileNumberExist(true)
                    toggleModalForVerifyOTP(true)
                }
            })
            .catch(standardFetchHandlers.error)
            .finally(() => {
                setTimeout(() => {
                    toggleLoader(false);
                }, 1000);
            // window.location.reload();
            });
    };

    useMemo(() => {
        if (userName && userName.length) {
    
          const nameArr = userName.split(" ");
    
          updateNameInitial({
            firstName: nameArr[0][0],
            lastName: nameArr?.[1]?.[0] ? nameArr[1][0] : ``
          })
    
        }
    
      }, [userDetail]);

      const changeColorTheme = (nameInitial) => {
        const initial = nameInitial && nameInitial.name ? nameInitial.name[0].toUpperCase() : 'M';
        localStorage.setItem('initial', initial);
      
        const backgroundColor = colorList[initial];
        localStorage.setItem('backgroundColor', backgroundColor);
      }
      




    const [demo, updateDemo] = useState(false);
    const [updateUser1, setupdateUser1] = useState({})

    const loginWithGoogle = useGoogleLogin({
        onSuccess: async respose => {
            try {
                axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
                    headers: {
                        "Authorization": `Bearer ${respose.access_token}`
                    }
                }).then(async (res) => {
                    const datas = res?.data;
                    console.log(datas)

                    const userDataObj = {
                        name: datas.given_name,
                        email_address: datas.email,
                        google_id: String(datas.sub.toLowerCase()),
                        google_token: respose.access_token,
                        device_token: "token",
                        device_type: 1
                    };


                    fetch(`${appURL}/login-with-google`, {
                        ...fetchConfig,
                        body: JSON.stringify(userDataObj)
                    })
                        .then(handleFetchErrors)
                        .then((res) => {
                            console.log(res)
                            toast.dismiss();
                            updateModalVisibilityForLogin(false)

                            if (Number(res?.status) === 201 || Number(res?.status) === 200) {
                                const userData = res?.data;
                                const Obj = {
                                    name: userData.name,
                                    email_address: userData?.email_address || ``,
                                    phone_number: userData?.phone_number || ``,
                                    source: userData.source,
                                    picture: userData.picture || ``,
                                    userId:userData.userId || ``
                                }
                                localStorage.setItem(`user`, JSON.stringify(Obj));
                                localStorage.setItem(`token`, res?.token);
                                window.location.reload();

                            } else if (Number(res?.status) === 400 || Number(res?.status) === 403) {

                                toast.error(res?.message);
                            }



                        })
                        .catch((err) => {
                            console.log(err)
                        });



                    // Extract the user's email and name from the response data


                })





                // console.log(res.data)
            } catch (err) {
                console.log(err)

            }

        }
    });

    const renderButton = ({ onClick }) => null;


    const [modalIsOpenForVerifyOTP, updateModalVisibilityForVerifyOTP] = useState(false);

    const toggleModalForVerifyOTP = (toggleValue = false) => {
        // updateModalVisibilityForLogin(false)
        // toggleModalForRegister(false)
        updateModalVisibilityForVerifyOTP(toggleValue)
    }



    const [isMobileNumberExist, updateIsMobileNumberExist] = useState(false);

    const { name, email, password, mobile_number } = formData;

    const onChangeHandlerForFormData = (e) => {
        const inputName = e?.target?.name || e?.name,
            inputValue = e?.target?.value || e?.value;
            setUserDetail((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value
              }));

        setFormData((prev) => ({
            ...prev,
            [inputName]: inputValue
        }));
    };




    const [authResponse, updateauthResponse] = useState({})


    const appleResponse = async (response) => {
        console.log(response)
        console.log(response)

        if (response.authorization.id_token) {

            const idToken = jwt_decode(response.authorization.id_token);

            const userDataObj = {
                email_address: String(idToken.email).toLocaleLowerCase(),
                apple_id: String(response.authorization.id_token),
                apple_token: response.authorization.id_token,
                device_token: "token",
                device_type: 1
            };


            console.log(userDataObj)

            // return 

            fetch(`${appURL}/login-with-apple`, {
                ...fetchConfig,
                body: JSON.stringify(userDataObj)
            })
                .then(handleFetchErrors)
                .then((res) => {
                    console.log(`working`)
                    console.log(`res`, res)
                    localStorage.setItem(`temtoken`, res?.token);
                    updateModalVisibilityForLogin(false)

                    if (Number(res?.status) === 201 || Number(res?.status) === 200) {
                        const userData = res?.data;
                        const Obj = {
                            name: userData.name,
                            source: userData.source,
                            picture: userData.picture || ``
                        }
                        localStorage.setItem(`user`, JSON.stringify(Obj));
                        localStorage.setItem(`token`, res?.token);
                        localStorage.setItem(`source`, res?.data?.source);

                    } else if (Number(res?.status) === 400 || Number(res?.status) === 403) {
                        
                        return toast.error(res?.message);
                    } else {
                        console.log(`jwhdghasgj`)
                        return toast.error(res?.message);
                    }
                })
                .catch(standardFetchHandlers.error)
                .finally(() => {
                    setTimeout(() => {
                        toggleLoader(false);
                    }, 1000);
                });


        }



    };

    useEffect(() => {

        const rememberMeDetail = JSON.parse(localStorage.getItem(`rememberMe`));

        setFormData({
            email_address: rememberMeDetail?.email || ``,
            password: rememberMeDetail?.password || ``
        })

    }, [])




    const onSubmitHandlerForEmailChange = (e, isResend = false) => {
        e.preventDefault();
        toast.dismiss();
        const emailAddress = email

        if (!emailAddress) return toast.error(`Please provide email address`);
        else if (emailAddress && !isValidEmailAddress(emailAddress)) return toast.error(`Please provide valid email address`);


        fetch(`${appURL}/generate_otp`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                email_address: emailAddress,
                source: 2
            })
        })
            .then(handleFetchErrors)
            .then((generatedOTP) => {
                console.log(`generatedOTP`, generatedOTP)
                if (generatedOTP && generatedOTP.status === 200) {

                    return toast.success(`Resend otp successfully  sent for email address`)

                } else {
                    toast.error(generatedOTP?.message);
                }

            })
            .catch(standardFetchHandlers.error);

    }


    const onSubmitHandlerForPhoneNumberChange = (e, isResend = false) => {
        toast.dismiss();
        e.preventDefault();
        const phoneNumber = mobile_number;
        if (!phoneNumber) toast.error(`Please provide valid mobile number`);


        fetch(`${appURL}/generate_otp`, {
            ...fetchConfig,
            method: `POST`,
            body: JSON.stringify({
                phone_number: phoneNumber,
                source: 1
            })
        })
            .then(handleFetchErrors)
            .then((generatedOTP) => {

                if (generatedOTP && generatedOTP.status === 200) {
                    toast.success(`Resend otp successfully  sent for mobile number`)
                } else {
                    toast.error(generatedOTP?.message);
                }

            })
            .catch(standardFetchHandlers.error);

    }




    return (
        <>

            <CommonModalWrapper isOpen={modalIsOpenForVerifyOTP} className={style.verify_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalForVerifyOTP(false)} />
                <VerifyOTP isMobileOTP={isMobileNumberExist} toggleModalForRegister={toggleModalForRegister} updateModalVisibilityForRegister={updateModalVisibilityForLogin} reGenerateOTPForEmail={onSubmitHandlerForEmailChange} reGenerateOTPForPhoneNumber={onSubmitHandlerForPhoneNumberChange} skipRequired={true} />
            </CommonModalWrapper>

            <CommonModalWrapper isOpen={modalIsOpenForForgetPassword} className={style.verify_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalForForgetPassword(false)} />
                <ForgetPassword updateModalVisibilityForForgetPassword={updateModalVisibilityForForgetPassword} />
            </CommonModalWrapper>



            <div className="modal-content">
                <div className="modal-header">
                    <h6 className={style.heading} >LOG INTO YOUR ACCOUNT</h6>
                </div>
                <div className="modal-body">
                    <form onSubmit={onSubmitHandler} autoComplete="off">

                        <div className="form-group">
                            <label for="exampleInputEmail1">Email Address* </label>
                            <input autoComplete="none" type="email" value={formData.email_address} onChange={handleChangeForLoginFormInput} name='email_address' className="form-control" placeholder="Enter your email address" />
                        </div>


                        <div className="form-group">
                            <label for="exampleInputPassword1">Password* </label>
                            <div className={`${style.password_input_wrapper} form-group`}>
                                <input value={formData.password} type={passwordInputTypeForPassword} onChange={handleChangeForLoginFormInput} className="form-control" name='password' placeholder="Please enter your password" autoComplete="none" />
                                {
                                    passwordInputTypeForPassword === "text" ?
                                        <svg onClick={() => { passwordInputChangeHandler(`password`, 1) }} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
                                        :

                                        <svg onClick={() => { passwordInputChangeHandler(`text`, 1) }} fill="#000000" width="20" height="20" viewBox="0 -16 544 544" xmlns="http://www.w3.org/2000/svg" ><title>hide</title><path d="M108 60L468 420 436 452 362 378Q321 400 272 400 208 400 154 361 99 322 64 256 79 229 102 202 124 174 144 160L76 92 108 60ZM368 256Q368 216 340 188 312 160 272 160L229 117Q254 112 272 112 337 112 392 152 446 192 480 256 474 269 461 288 448 307 434 322L368 256ZM272 352Q299 352 322 338L293 309Q283 312 272 312 249 312 233 296 216 279 216 256 216 247 220 236L190 206Q176 229 176 256 176 296 204 324 232 352 272 352Z" /></svg>
                                        }

                            </div>

                            <div className={style.forget_password_and_rember_me_text}>
                                <aside className={style.remember_text_wrapper}>
                                    {/* <Inputs
                                       type='checkbox'
                                       disabled={!formData.email_address || !formData.password ? true : false}
                                       checkboxInputOtions={[`Remember`]}
                                    /> */}

                                    <Inputs
                                        name='remember'
                                        onChangeHandler={handleChange}
                                        selectOptionArrForCheckBox={selectedRememberMe}
                                        type='checkbox'
                                        checkboxInputOtions={[`Remember`]}
                                    />

                                </aside>
                                <span className={style.forgrt_password_text} onClick={() => { updateModalVisibilityForForgetPassword(true) }}>Forgot Password </span>
                            </div>

                        </div>



                        {/* <div className="form-group">
                        <label for="exampleInputPassword1">Password* </label>
                        <input type="password" onChange={handleChangeForLoginFormInput} name='password' className="form-control" placeholder="Enter your password " />
                        </div> */}

                        {
                            showLoader ? <LoaderButton /> :
                                <div id={style.submit_btn} className="form-group">
                                    <button type="submit" className="btn btn-confirm">
                                        Confirm
                                    </button>
                                </div>

                        }




                    </form>

                    <p className={style.login_text}>Don`t have account? <span onClick={() => toggleModalForRegister(true)}>Sign Up</span></p>


                    <section className={style.horizontal_line_wrapper}>
                        <div className={style.horizontal_line}></div>
                        <span>OR</span>
                        <div className={style.horizontal_line}></div>
                    </section>

                    <button onClick={loginWithGoogle} className={style.google_login_btn}>
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_1148_88683)">
                                <path d="M24.2682 12.2765C24.2682 11.4608 24.2021 10.6406 24.061 9.83813H12.7422V14.4591H19.2239C18.955 15.9495 18.0907 17.2679 16.8252 18.1056V21.104H20.6922C22.963 19.014 24.2682 15.9274 24.2682 12.2765Z" fill="#4285F4" />
                                <path d="M12.7391 24.0008C15.9756 24.0008 18.705 22.9382 20.6936 21.1039L16.8266 18.1055C15.7507 18.8375 14.3618 19.252 12.7435 19.252C9.61291 19.252 6.95849 17.1399 6.00607 14.3003H2.01562V17.3912C4.05274 21.4434 8.20192 24.0008 12.7391 24.0008Z" fill="#34A853" />
                                <path d="M6.00473 14.3002C5.50206 12.8099 5.50206 11.196 6.00473 9.70569V6.61475H2.01869C0.316687 10.0055 0.316687 14.0004 2.01869 17.3912L6.00473 14.3002Z" fill="#FBBC04" />
                                <path d="M12.7391 4.74966C14.4499 4.7232 16.1034 5.36697 17.3425 6.54867L20.7685 3.12262C18.5991 1.0855 15.7198 -0.034466 12.7391 0.000808666C8.20192 0.000808666 4.05274 2.55822 2.01562 6.61481L6.00166 9.70575C6.94967 6.86173 9.6085 4.74966 12.7391 4.74966Z" fill="#EA4335" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1148_88683">
                                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                                </clipPath>
                            </defs>
                        </svg>

                        Sign up with Google
                    </button>
                    <GoogleLogin
                        onSuccess={credentialResponse => {

                            var decoded = jwt_decode(credentialResponse.credential);

                        }}
                        render={renderButton}

                        onError={() => {
                            console.log('Login Failed');
                        }} />



                    {Object.keys(authResponse).length === 0 ? (
                        <AppleLogin
                            // clientId="J9FLJKQNXM"
                            clientId="com.netscapelabs.menehariya.web"
                            redirectURI="https://menehariya.netscapelabs.com"
                            usePopup={true}
                            callback={appleResponse}
                            scope="email name"
                            responseMode="query"
                            render={renderProps => (
                                <button className={style.apple_login_btn}
                                    onClick={renderProps.onClick}
                                    style={{
                                        backgroundColor: "white",
                                        padding: 10,
                                        border: "1px solid black",
                                        fontFamily: "none",
                                        lineHeight: "25px",
                                        fontSize: "25px"
                                    }}
                                >
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M21.3418 17.1449C21.0091 17.9135 20.6153 18.6211 20.159 19.2715C19.5371 20.1583 19.0278 20.7721 18.6354 21.113C18.027 21.6724 17.3752 21.959 16.6772 21.9753C16.1761 21.9753 15.5718 21.8327 14.8683 21.5434C14.1626 21.2555 13.514 21.113 12.921 21.113C12.2991 21.113 11.632 21.2555 10.9185 21.5434C10.204 21.8327 9.62831 21.9834 9.18819 21.9984C8.51884 22.0269 7.85166 21.7322 7.18571 21.113C6.76066 20.7422 6.22901 20.1067 5.59212 19.2063C4.90878 18.2449 4.34698 17.13 3.90686 15.8589C3.43551 14.486 3.19922 13.1565 3.19922 11.8694C3.19922 10.3951 3.5178 9.12345 4.15592 8.05784C4.65742 7.20191 5.32459 6.52672 6.15962 6.03105C6.99464 5.53539 7.89688 5.2828 8.86852 5.26664C9.40017 5.26664 10.0974 5.43109 10.9637 5.75429C11.8277 6.07858 12.3824 6.24303 12.6256 6.24303C12.8075 6.24303 13.4237 6.05074 14.4684 5.66738C15.4564 5.31186 16.2902 5.16466 16.9732 5.22264C18.8241 5.37202 20.2147 6.10167 21.1395 7.41619C19.4841 8.4192 18.6653 9.82403 18.6816 11.6262C18.6965 13.03 19.2057 14.1981 20.2066 15.1256C20.6601 15.5561 21.1667 15.8888 21.7302 16.1251C21.608 16.4795 21.479 16.819 21.3418 17.1449ZM17.0968 0.440369C17.0968 1.54062 16.6948 2.56792 15.8936 3.51878C14.9267 4.64917 13.7572 5.30236 12.489 5.19929C12.4729 5.06729 12.4635 4.92837 12.4635 4.78239C12.4635 3.72615 12.9233 2.59576 13.7399 1.67152C14.1475 1.20356 14.666 0.814453 15.2948 0.504058C15.9221 0.198295 16.5156 0.0292007 17.0737 0.000244141C17.09 0.147331 17.0968 0.294426 17.0968 0.440355V0.440369Z" fill="black" />
                                    </svg>
                                    Sign up with Apple
                                </button>
                            )}
                        />
                    ) : (
                        <p style={{ fontFamily: "none" }}>
                            {JSON.stringify(this.state.authResponse)}
                        </p>
                    )}
                </div>
            </div>
        </>
    )
}
